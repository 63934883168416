import "./structGPT.css";
import TitleBar from "../../components/titleBar/v2/titleBar";

function StructGPT() {

    return (
        <>
            <TitleBar/>
            <div className="_structGPTDiv">
                <label className="_structGPTMainLabel">Coming Soon!</label>
            </div>
        </>
    );
}

export default StructGPT;
