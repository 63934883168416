import Rectangle from "./elements/rectangle";
import DottedLine from "./elements/dottedLine";
import Arrow from "./elements/arrow";
import Legend from "./elements/legend";

//frameheight: height of the frame in which you want to place the diagram
//framewidth: width of the frame in which you want to place the diagram
//shearForceAlongX: shear force that is acting along the x direction
//shearForceAlongY: shear force that is acting along the y direction
//momentAboutX: moment that is acting about the x axis
//momentAboutY: moment that is acting about the y axis

function RectangularHollowSectionLoadingDiagram({ frameHeight, frameWidth, shearForceAlongX, shearForceAlongY, momentAboutX, momentAboutY, height, width, thickness }) {
    const frameCenterX = frameWidth / 2;
    const frameCenterY = frameHeight / 2 - 10;
    const aspectRatio = width / height;
    const displayHeight = (frameHeight) * 0.4;
    const displayWidth = displayHeight * aspectRatio;
    const displayThickness = thickness;
    const dottedLineOffset = 50;
    // const legend = {
    //     mx: ["blue", `Mx: ${momentAboutX} kN-m`],
    //     my: ["blue", `My: ${momentAboutY} kN-m`],
    //     sx: ["green", `Sx: ${shearForceAlongX} kN`],
    //     sy: ["green", `Sy: ${shearForceAlongY} kN`],
    // };

    return (
        <>
            {/*outer rectangle*/}
            <Rectangle originX={frameCenterX} originY={frameCenterY} width={displayWidth} height={displayHeight} stroke="black" fill="#F5F5F5" />
            {/*inner rectangle*/}
            <Rectangle originX={frameCenterX} originY={frameCenterY} width={displayWidth - 2 * displayThickness} height={displayHeight - 2 * displayThickness} stroke="black" fill="white" />
            {/*vertical dotted line*/}
            <DottedLine originX={frameCenterX} originY={frameCenterY} length={displayHeight + dottedLineOffset} spanDirection="vertical" stroke="darkgrey" />
            <text x={frameCenterX} y={frameCenterY - displayHeight / 2 - dottedLineOffset / 2 - 15} fill="darkgrey" dominantBaseline="hanging" textAnchor="middle">
                Y
            </text>
            {/*horizontal dotted line*/}
            <DottedLine originX={frameCenterX} originY={frameCenterY} length={displayWidth + dottedLineOffset} spanDirection="horizontal" stroke="darkgrey" />
            <text x={frameCenterX + displayWidth / 2 + dottedLineOffset / 2 + 15} y={frameCenterY} fill="darkgrey" dominantBaseline="middle" textAnchor="middle">
                X
            </text>
            {/*mx ellipse*/}
            <path
                d={`M ${frameCenterX + displayWidth / 2 + 20},${frameCenterY + 20}
                A 50,30 0 0 1 ${frameCenterX + displayWidth / 2 + 20},${frameCenterY - 20}`}
                fill="none"
                stroke="blue"
            />
            <line x1={frameCenterX + displayWidth / 2 + 20} y1={frameCenterY + 20} x2={frameCenterX + displayWidth / 2 + 20 - 2} y2={frameCenterY + 20 - 7} stroke="blue" />
            <line x1={frameCenterX + displayWidth / 2 + 20} y1={frameCenterY + 20} x2={frameCenterX + displayWidth / 2 + 20 - 7} y2={frameCenterY + 20 + 1} stroke="blue" />
            <text x={frameCenterX + displayWidth / 2 + 20} y={frameCenterY - 20 - 5} fill="blue" dominantBaseline="auto" textAnchor="start">{`Mx: ${momentAboutX} kN-m`}</text>
            {/*my ellipse*/}
            <path
                d={`M ${frameCenterX + 20},${frameCenterY - displayHeight / 2 - 20}
                A 30,50 0 0 1 ${frameCenterX - 20},${frameCenterY - displayHeight / 2 - 20}`}
                fill="none"
                stroke="blue"
            />
            <line x1={frameCenterX + 20} y1={frameCenterY - displayHeight / 2 - 20} x2={frameCenterX + 20 - 1} y2={frameCenterY - displayHeight / 2 - 20 + 9} stroke="blue" />
            <line x1={frameCenterX + 20} y1={frameCenterY - displayHeight / 2 - 20} x2={frameCenterX + 20 - 8} y2={frameCenterY - displayHeight / 2 - 20 + 2} stroke="blue" />
            <text x={frameCenterX - 20 - 5} y={frameCenterY - displayHeight / 2 - 20} fill="blue" dominantBaseline="middle" textAnchor="end">{`My: ${momentAboutY} kN-m`}</text>
            {/*sx arrow*/}
            <Arrow originX={frameCenterX - displayWidth / 2} originY={frameCenterY} tailLength={30} arrowHeadHeight={8} stroke="green" fill="green" direction="right" />
            <text x={frameCenterX - displayWidth / 2 - 38 - 5} y={frameCenterY} dominantBaseline="middle" textAnchor="end" fill="green">{`Sx: ${shearForceAlongX} kN`}</text>
            {/*sy arrow*/}
            <Arrow originX={frameCenterX} originY={frameCenterY + displayHeight / 2} tailLength={30} arrowHeadHeight={8} stroke="green" fill="green" direction="up" />
            <text x={frameCenterX} y={frameCenterY + displayHeight / 2 + 38 + 5} dominantBaseline="hanging" textAnchor="middle" fill="green">{`Sy: ${shearForceAlongY} kN`}</text>
            {/*legend*/}
            {/* <Legend originX={10} originY={frameCenterY - 30} sideLength={5} verticalSpacing={20} items={legend} /> */}
        </>
    );
}

export default RectangularHollowSectionLoadingDiagram;
