import "./footer.css";
import Buttons from "../buttons/buttons";

function Footer({ textColor }) {
    return (
        <>
            <div className="_sectionFooterDiv">
                <div className="_startSectionDiv">
                    <label style={{ color: "lightgrey" }}>Contact Us: support@structurapro.co</label>
                </div>
                <div className="_middleSectionDiv">
                    <Buttons type="linkButton" text="About Us" fontColor={textColor} linkTo="/aboutUs" onClickFunction={() => { }} />
                    <label style={{ color: "lightgrey" }}>|</label>
                    <Buttons type="linkButton" text="Legal" fontColor={textColor} linkTo="/legal" onClickFunction={() => { }} />
                    <label style={{ color: "lightgrey" }}>|</label>
                    <Buttons type="linkButton" text="Privacy Statement" fontColor={textColor} linkTo="/privacyStatement" onClickFunction={() => { }} />
                    <label style={{ color: "lightgrey" }}>|</label>
                    <Buttons type="linkButton" text="Terms of Use" fontColor={textColor} linkTo="/termsOfUse" onClickFunction={() => { }} />
                    <label style={{ color: "lightgrey" }}>|</label>
                    <Buttons type="linkButton" text="Pricing" fontColor={textColor} linkTo="/pricing" onClickFunction={() => { }} />
                </div>
                <div className="_endSectionDiv">
                    <label style={{ color: "lightgrey" }}>Copyright © Structura Pro Studios</label>
                </div>
            </div>
        </>
    );
}

export default Footer;
