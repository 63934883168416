import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./pages/home/home.js";
import DesignTools from "./pages/designTools/designTools.js";
import Legal from "./pages/legal/legal.js";
import PrivacyStatement from "./pages/privacyStatement/privacyStatement.js";
import TermsOfUse from "./pages/termsOfUse/termsOfUse.js";
import ComingSoon from "./pages/comingSoon/comingSoon.js";
import RectangularHollowSteelSection from "./pages/designPages/rectangularHollowSteelSection.js";
import RectangularHollowSteelBeam from "./pages/designPages/rectangularHollowSteelBeam.js";
import CircularHollowSteelSection from "./pages/designPages/circularHollowSteelSection.js";
import CircularHollowSteelBeam from "./pages/designPages/circularHollowSteelBeam.js";
import RectangularSolidConcreteBeam from "./pages/designPages/rectangularSolidConcreteBeam.js";
import RectangularHollowSteelBeamOptimizer from "./pages/designPages/rectangularHollowSteelBeamOptimizer.js";
import CircularHollowSteelBeamOptimizer from "./pages/designPages/circularHollowSteelBeamOptimizer.js";
import RectangularSolidConcreteColumn from "./pages/designPages/rectangularSolidConcreteColumn.js";
import RectangularSolidConcreteBeamOptimizer from "./pages/designPages/rectangularSolidConcreteBeamOptimizer.js";
import RectangularSolidConcreteColumnOptimizer from "./pages/designPages/rectangularSolidConcreteColumnOptimizer.js";
import BeamAnalysis from "./pages/designPages/beamAnalysis.js";
import SignUp from "./components/SignUp.js";
import SignIn from "./components/SignIn";
import { PaymentProtectedRoute, SignInProtectedRoute } from "./components/ProtectedRoute.js";
import ProfilePage from "./components/ProfilePage.js";
import StructGPT from "./pages/structGPT/structGPT.js";
import UnpaidUser from "./components/unpaidUser.js";
import AboutUs from "./pages/aboutUs/aboutUs.js";
import Pricing from "./pages/pricing/pricing.js";
import RectangularHollowSteelSectionOptimizer from "./pages/designPages/rectangularHollowSteelSectionOptimizer.js";
import CircularHollowSteelSectionOptimizer from "./pages/designPages/circularHollowSteelSectionOptimizer.js";
import TrussAnalysis from "./pages/designPages/trussAnlaysis.js";
import { AuthProvider } from "./authContext.js";
import UnauthenticatedUser from "./components/UnauthenticatedUser.js";
import { TrussProvider } from "./components/trussAnalysisCanvas/trussContext.js";
import Articles from "./pages/articles/articles.js";
import BeamAnalysisArticle from "./pages/articles/beamAnalysis.js";
import TrussAnalysisArticle from "./pages/articles/trussAnalysis.js";
import SteelBeamISCodeArticle from "./pages/articles/steelBeamISCode.js";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
    <React.StrictMode>
        <AuthProvider>
            <BrowserRouter>
                <Routes>
                    <Route index element={<Home />} />
                    <Route path="/designTools" element={<DesignTools />} />
                    <Route path="/legal" element={<Legal />} />
                    <Route path="/privacyStatement" element={<PrivacyStatement />} />
                    <Route path="/termsOfUse" element={<TermsOfUse />} />
                    <Route path="/designTools/rectangularHollowSteelSection" element={<SignInProtectedRoute><RectangularHollowSteelSection /></SignInProtectedRoute>} />
                    {/* <Route path="/designTools/rectangularHollowSteelBeam" element={<SignInProtectedRoute><RectangularHollowSteelBeam /></SignInProtectedRoute>} /> */}
                    <Route path="/designTools/circularHollowSteelSection" element={<SignInProtectedRoute><CircularHollowSteelSection /></SignInProtectedRoute>} />
                    {/* <Route path="/designTools/circularHollowSteelBeam" element={<SignInProtectedRoute><CircularHollowSteelBeam /></SignInProtectedRoute>}/> */}
                    {/* <Route path="/designTools/rectangularSolidConcreteBeam" element={<RectangularSolidConcreteBeam />} /> */}
                    {/* <Route path="/designTools/rectangularHollowSteelBeamOptimizer" element={<SignInProtectedRoute><RectangularHollowSteelBeamOptimizer /></SignInProtectedRoute>} /> */}
                    {/* <Route path="/designTools/circularHollowSteelBeamOptimizer" element={<SignInProtectedRoute><CircularHollowSteelBeamOptimizer /></SignInProtectedRoute>} /> */}
                    {/* <Route path="/designTools/rectangularSolidConcreteBeamOptimizer" element={<PaymentProtectedRoute><RectangularSolidConcreteBeamOptimizer /></PaymentProtectedRoute>} /> */}
                    {/* <Route path="/designTools/rectangularSolidConcreteColumnOptimizer" element={<PaymentProtectedRoute><RectangularSolidConcreteColumnOptimizer /></PaymentProtectedRoute>} /> */}
                    {/* <Route path="/designTools/rectangularSolidConcreteColumn" element={<RectangularSolidConcreteColumn />} /> */}
                    <Route path="/designTools/beamAnalysis" element={<BeamAnalysis />} />
                    <Route path="/designTools/rectangularHollowSteelSectionOptimizer" element={<PaymentProtectedRoute><RectangularHollowSteelSectionOptimizer /></PaymentProtectedRoute>} />
                    <Route path="/designTools/circularHollowSteelSectionOptimizer" element={<PaymentProtectedRoute><CircularHollowSteelSectionOptimizer /></PaymentProtectedRoute>} />
                    <Route path="/designTools/rectangularHollowSteelSectionOptimizer" element={<RectangularHollowSteelSectionOptimizer />} />
                    <Route path="/designTools/circularHollowSteelSectionOptimizer" element={<CircularHollowSteelSectionOptimizer />} />
                    <Route path="/designTools/trussAnalysis" element={<SignInProtectedRoute><TrussProvider><TrussAnalysis /></TrussProvider></SignInProtectedRoute>} />
                    <Route path="/signup" element={<SignUp />} />
                    <Route path="/signin" element={<SignIn />} />
                    <Route path="/comingSoon" element={<ComingSoon />} />
                    <Route path="/structGPT" element={<StructGPT />} />
                    <Route path="/signup" element={<SignUp />} />
                    <Route path="/profile" element={<ProfilePage />} />
                    <Route path="/unpaidUser" element={<UnpaidUser />} />
                    <Route path="/unauthenticatedUser" element={<UnauthenticatedUser />} />
                    <Route path="/aboutUs" element={<AboutUs />} />
                    <Route path="/pricing" element={<Pricing />} />
                    <Route path="/articles" element={<Articles />} />
                    <Route path="/articles/beamAnalysisArticle" element={<BeamAnalysisArticle />} />
                    <Route path="/articles/trussAnalysisArticle" element={<TrussAnalysisArticle />} />
                    <Route path="/articles/steelBeamISCodeArticle" element={<SteelBeamISCodeArticle />} />
                </Routes>
            </BrowserRouter>
        </AuthProvider>
    </React.StrictMode>
);
