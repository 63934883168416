//originX and originY: this is the center of the circle
//radius: this is the radius of the circle you want to make
//fill: this is the fill color inside the circle
//stroke: this is the color of the line that will draw the circle

function Circle({ originX, originY, radius, fill, stroke }) {
    return (
        <>
            <path
                d={`M ${originX},${originY - radius}
                A ${radius}, ${radius}
                0 1 0
                ${originX}, ${originY + radius}
                A ${radius}, ${radius}
                0 1 0
                ${originX}, ${originY - radius}`}
                fill={fill}
                stroke={stroke}
            />
        </>
    );
}

export default Circle;
