// src/firebase.js
import { initializeApp } from "firebase/app";
import { getAuth, onAuthStateChanged, GoogleAuthProvider, setPersistence, browserLocalPersistence } from "firebase/auth";
import { getFirestore, doc, setDoc, getDoc } from "firebase/firestore";

const firebaseConfig = {
    apiKey: "AIzaSyAyaEkRSYOpP_MLuo7x-MHxrOLumHICoQw",
    authDomain: "structura-pro-4533a.firebaseapp.com",
    projectId: "structura-pro-4533a",
    storageBucket: "structura-pro-4533a.appspot.com",
    messagingSenderId: "476696929627",
    appId: "1:476696929627:web:21dc6f1aad80d31f70e67e",
    measurementId: "G-MG93FD96H5",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
setPersistence(auth, browserLocalPersistence)
    .then(() => {
        // Existing and future Auth states are now persisted
    })
    .catch((error) => {
        console.error("Error setting persistence:", error);
    });

export const googleAuthProvider = new GoogleAuthProvider();
export const db = getFirestore(app); // Initialize Firestore

export const createUserProfileDocument = async (user, additionalData) => {
    if (!user) return;

    const userRef = doc(db, `users/${user.uid}`);
    const snapshot = await getDoc(userRef);

    if (!snapshot.exists()) {
        const { email } = user;
        const createdAt = new Date();

        try {
            await setDoc(userRef, {
                email,
                createdAt,
                ...additionalData,
            });
        } catch (error) {
            console.error("Error creating user document:", error);
        }
    }

    return userRef;
};

onAuthStateChanged(auth, async (user) => {
    if (user) {
        const userRef = doc(db, `users/${user.uid}`);
        const snapshot = await getDoc(userRef);

        if (snapshot.exists()) {
            const userData = snapshot.data();

        }
    } else {
        console.log("No user signed in");
    }
});

export { onAuthStateChanged };
