//this is the page that we are using for legal, terms of use and privacy statements

import TitleBar from "../../components/titleBar/v2/titleBar";
import "./informationPage.css";

function ContentSection({ heading, content }) {
    return (
        <div className="_informationPageContentBlock">
            <label className="_informationPageContentHeading">{heading}</label>
            <label className="_informationPageContent">{content}</label>
        </div>
    );
}

//informationPageHeading: this will be the heading for the information page
//content: this is going to be be an object where we are going to show all the information. the format for it should be as:
//const content = {
//     [key] = [heading, content]
// }

function InformationPage({ informationPageHeading, content }) {

    const renderedContent = [];
    Object.keys(content).forEach((key) => {
        renderedContent.push(<ContentSection heading={content[key][0]} content={content[key][1]} />);
    });

    return (
        <>
            <TitleBar/>
            <div style={{ margin: "20px", marginTop: "10px" }}>
                <label className="_informationPageContentMainHeading">{informationPageHeading}</label>
            </div>
            <hr className="_horizontalDividerLine" />
            {renderedContent}
        </>
    );
}

export default InformationPage;
