import "./designPagesStyles.css";
import Buttons from "../../components/buttons/buttons";
import React, { useRef, useState, useEffect } from "react";
import axios from "axios";
import SettingsMenu from "../../components/settingsMenu/settingsMenu";
//import CalculationSheet from "../../components/calculationSheet/calculationSheet";
import InputSheet from "../../components/inputSheet/inputSheet";
import BeamSectionDesignPDF from "../../components/pdf/beamSectionDesignPDF";
import { pdf } from "@react-pdf/renderer";
import CicrularHollowSectionDiagram from "../../components/diagrams/circularHollowSectionDiagram";
import CircularHollowSectionLoadingDiagram from "../../components/diagrams/circularHollowSectionLoadingDiagram";
import InformationOverlay from "../../components/informationOverlay/informationOverlay";
import ExpandableSection from "../../components/expandableSection/expandableSection";
import CalculationSheet from "../../components/calculationSheet/calculationSheet";
import AppliedForcesAndDesignCapacities from "../../components/tables/appliedForcesAndDesignCapacities/appliedForcesAndDesignCapacitites";
import DesignCapacityRatios from "../../components/tables/designCapacityRatios/designCapacityRatios";

function CircularHollowSteelSection() {
    //****************************************INPUT REFS AND INPUT VARIABLES****************************************
    //Ref Variables
    const inputDataRefs = {
        radiusRef: useRef(null),
        lengthRef: useRef(null),
        thicknessRef: useRef(null),
        densityOfSteelRef: useRef(null),
        yieldStrengthOfSteelRef: useRef(null),
        modulusOfElasticityOfSteelRef: useRef(null),
        axialForceRef: useRef(null),
        shearForceAlongXRef: useRef(null),
        shearForceAlongYRef: useRef(null),
        momentAboutXRef: useRef(null),
        momentAboutYRef: useRef(null),
        sectionTypeRef: useRef(null),
        effectiveLengthFactorRef: useRef(null),
    };

    //Input Variables
    const inputData = {
        //geometric properties
        radius: ["Geometric Properties", "Radius", ["number", inputDataRefs.radiusRef, 200], "mm"],
        length: ["Geometric Properties", "Length", ["number", inputDataRefs.lengthRef, 2000], "mm"],
        thickness: ["Geometric Properties", "Thickness", ["number", inputDataRefs.thicknessRef, 10], "mm"],
        //material properties
        densityOfSteel: ["Material Properties", "Density of Steel", ["number", inputDataRefs.densityOfSteelRef, 7850], "kg/m³"],
        yieldStrengthOfSteel: ["Material Properties", "Yield Strength of Steel", ["number", inputDataRefs.yieldStrengthOfSteelRef, 455], "MPa"],
        modulusOfElasticityOfSteel: ["Material Properties", "Modulus of Elasticity", ["number", inputDataRefs.modulusOfElasticityOfSteelRef, 20000], "MPa"],
        //applied forces
        axialForce: ["Applied Forces", "Axial Force", ["number", inputDataRefs.axialForceRef, 600], "kN"],
        shearForceAlongX: ["Applied Forces", "Shear Force Along X", ["number", inputDataRefs.shearForceAlongXRef, 5], "kN"],
        shearForceAlongY: ["Applied Forces", "Shear Force Along Y", ["number", inputDataRefs.shearForceAlongYRef, 4], "kN"],
        momentAboutX: ["Applied Forces", "Moment about X", ["number", inputDataRefs.momentAboutXRef, 50], "kN-m"],
        momentAboutY: ["Applied Forces", "Moment about Y", ["number", inputDataRefs.momentAboutYRef, 20], "kN-m"],
        //setup information
        sectionType: ["Setup Information", "Section Type", ["dropdown", inputDataRefs.sectionTypeRef, ["Hot Rolled", "Cold Formed"]], ""],
        effectiveLengthFactor: ["Setup Information", "Effective Length Factor", ["number", inputDataRefs.effectiveLengthFactorRef, 1], ""],
    };

    ///****************************************DIAGRAMS****************************************
    //selected diagaram type state variable
    const [selectedDiagramType, setSelectedDiagramType] = useState("sectionDiagram"); //which diagram has been selected in the diagram section
    //diagram div state variables
    const diagramDivRef = useRef(null); // ref for the div containing the diagram
    const [dimensions, setDimensions] = useState({ width: 0, height: 0 }); //dimensions of the div containing the diagram

    //resizing the diagram when the screen size changes
    useEffect(() => {
        const observeTarget = diagramDivRef.current;
        const resizeObserver = new ResizeObserver((entries) => {
            // Assuming you want to observe the first entry
            const { width, height } = entries[0].contentRect;
            setDimensions({ width, height });
        });
        // Step 2: Initialize the ResizeObserver
        if (observeTarget) {
            resizeObserver.observe(observeTarget);
        }

        // Step 3: Clean up
        return () => {
            if (observeTarget) {
                resizeObserver.disconnect();
            }
        };
    }, []); // Empty dependency array ensures this effect runs only once

    //handle dropdown change for selecting diagram
    const handleDiagramDropdownChange = (event) => {
        setSelectedDiagramType(event.target.value);
    };

    ///****************************************DESIGN RESULTS****************************************
    const [selectedDesignResult, setSelectedDesignResult] = useState("designCapacityRatios");

    //handle the change in selected design result
    const handleDesignResultsDropdownChange = (event) => {
        setSelectedDesignResult(event.target.value);
    }

    //****************************************CALCULATE BUTTON****************************************
    //calculations related state variables
    const [designStatusMessage, setDesignStatusMessage] = useState("Status: Click calculate to design section.")
    const [designCalculationsVisibility, setDesignCalculationsVisibility] = useState(false); //whether calculations are shown or not
    const [designCalculations, setDesignCalculations] = useState({}); //to store the calcualation data we get from back end
    const [appliedForcesAndCapacities, setAppliedForcesAndCapacities] = useState({}); //applied forces and capacities state variables
    const [designCapacityRatios, setDesignCapacityRatios] = useState({}); //design capacity ratios object to generate design capacity ratios table
    const [designSummaryForPDF, setDesignSummaryForPDF] = useState({}); //this is the design

    //handle the clickdown of calculate button
    const calculateButtonClicked = async () => {
        //set design status message
        setDesignStatusMessage("Status: Designing beam section.")
        //Create request object
        const requestObject = {};
        Object.keys(inputDataRefs).forEach((key) => {
            const keyName = key.replace(/Ref$/, ""); //Removing the "Ref" suffix from each of the key name
            requestObject[`${keyName}`] = inputDataRefs[key].current.value;
        });

        //Send data to back end
        axios
            .post("/api/fetchCircularHollowSteelSectionDesignCalculations", requestObject, {
                headers: {
                    "Content-Type": "application/json",
                },
            })
            .then((response) => {
                setDesignCalculations(response.data["designCalculations"]); //Set design calculations state variable
                setAppliedForcesAndCapacities(response.data["appliedForcesAndCapacities"]); //set the applied forces and capacitites
                setDesignCapacityRatios(response.data["designCapacityRatios"]); //Set design capacity ratios to generate table
                setDesignSummaryForPDF(response.data["designSummaryForPDF"]); //set design summary for section 5 of PDF

                //set the state variables that we have to change things on the front end
                setDesignCalculationsVisibility(true); //Set design calculations visibility
                setDesignStatusMessage("Status: Section design complete.");
            })
            .catch((error) => {
                console.error(`The request could not be completed beacause of ${error}`);
            });
    };

    //****************************************SETTINGS BUTTON****************************************
    //settings menu state variables
    const [displaySettingsMenu, setDisplaySettingsMenu] = useState(false); //toggle to show or not show the settings menu
    const [designedBy, setDesignedBy] = useState("John Doe"); //who has design the structural element (this is going to appear in the final PDF that we are going to print out)
    const [checkedBy, setCheckedBy] = useState("Alice Alex"); //who checked the design calculations (this is going to appear in the final PDF that we are going to print out)
    const [allowableDCR, setAllowableDCR] = useState(1); //what is the DCR that is allowed?

    //hangle clickdown of settings button
    const settingsButtonClicked = () => {
        setDisplaySettingsMenu(true);
    };

    //****************************************PRINT BUTTON****************************************
    //diagram heights and widths
    const printCanvasHeight = 400;
    const printCanvasWidth = 500;
    //handle clickdown of print pdf button
    const printButtonClicked = async () => {
        if (designCalculationsVisibility == true) {
            setDesignStatusMessage("Status: Generating PDF...");
            //diagrams
            const sectionDiagram = await convertSvgToPng("sectionDiagramForPDF", printCanvasHeight, printCanvasWidth);
            const loadingDiagram = await convertSvgToPng("loadingDiagramForPDF", printCanvasHeight, printCanvasWidth);
            //request object
            const requestObject = {};
            //applied forces    
            Object.keys(designSummaryForPDF).forEach((key) => {
                requestObject[`${key}_appliedForces`] = designSummaryForPDF[key][1];
            })
            //design strength
            Object.keys(designSummaryForPDF).forEach((key) => {
                requestObject[`${key}_designStrength`] = designSummaryForPDF[key][2];
            })
            //capacityRatio
            Object.keys(designSummaryForPDF).forEach((key) => {
                requestObject[`${key}_capacityRatio`] = designSummaryForPDF[key][3];
            })
            //input data
            Object.keys(inputData).forEach((key) => {
                requestObject[key] = inputData[key][2][1].current ? inputData[key][2][1].current.value : "";
            })
            //designCalculations
            Object.keys(designCalculations).forEach((key) => {
                requestObject[key] = designCalculations[key][2];
            })
            //diagrams
            requestObject["sectionDiagram"] = sectionDiagram;
            requestObject["loadingDiagram"] = loadingDiagram;

            //the we send this to the api that is going to generate the pdf
            fetch('/api/generateCircularHollowSteelSectionPDF', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(requestObject)
            })
                .then(response => response.blob())
                .then(blob => {
                    //set design status message
                    setDesignStatusMessage("Status: PDF Generation Complete.")
                    const url = window.URL.createObjectURL(blob);
                    window.open(url, '_blank');
                })
                .catch(error => console.error('Error:', error));
        } else {
            alert("Please design section by clicking \"Calculate\" before printing out design results.");
        }
    };

    //****************************************FEEDVACK BUTTON****************************************
    const feedbackButtonClicked = () => {
        window.open("https://forms.gle/Az8WUKkhQbNMhtnp9", "_blank");
    }

    //****************************************INFORMATION BUTTON****************************************
    //handle the clickdown of information button
    const informationButtonClicked = () => {
        window.open("../assets/circularHollowSteelSection/designManual.pdf", "_blank");
    };

    //****************************************INFORMATION OVERLAYS****************************************
    //information overlay state variables
    const [geometryInformationOverlayVisibility, setGeometryInformationOverlayVisibility] = useState(false);
    const [materialPropertiesInformationOverlayVisibility, setMaterialPropertiesInformationOverlayVisibility] = useState(false);
    const [appliedForcesInformationOverlayVisibility, setAppliedForcesInformationOverlayVisibility] = useState(false);
    const [setupInformationOverlayVisibility, setSetupInformationOverlayVisibility] = useState(false);

    //information overlay functions
    const geometryInformationButtonClicked = () => {
        setGeometryInformationOverlayVisibility(true);
    };

    const materialPropertiesInformationButtonClicked = () => {
        setMaterialPropertiesInformationOverlayVisibility(true);
    };

    const appliedForcesInformationButtonClicked = () => {
        setAppliedForcesInformationOverlayVisibility(true);
    };

    const setupInformationButtonClicked = () => {
        setSetupInformationOverlayVisibility(true);
    };

    const closeInformationOverlays = () => {
        setGeometryInformationOverlayVisibility(false);
        setMaterialPropertiesInformationOverlayVisibility(false);
        setAppliedForcesInformationOverlayVisibility(false);
        setSetupInformationOverlayVisibility(false);
    };

    const informationOverlayFunctions = [geometryInformationButtonClicked, materialPropertiesInformationButtonClicked, appliedForcesInformationButtonClicked, setupInformationButtonClicked];

    //****************************************TITLE BAR BUTTONS****************************************
    //title bar buttons
    const titleBarButtons = {
        calculateButton: ["Calculate", calculateButtonClicked],
        settingsButton: ["Settings", settingsButtonClicked],
        printButton: ["Print", printButtonClicked],
        informationButton: ["Information", informationButtonClicked],
        feebdbackButton: ["Feedback", feedbackButtonClicked]
    };

    const renderedTitleBarButtons = [];
    Object.keys(titleBarButtons).forEach((key) => {
        renderedTitleBarButtons.push(<Buttons type="textButton" text={titleBarButtons[key][0]} fontColor="black" backgroundColor="" linkTo="" onClickFunction={titleBarButtons[key][1]} />);
    });

    return (
        <>
            <div className="_containerDiv">
                {displaySettingsMenu && (
                    <SettingsMenu setDisplaySettingsMenuFunction={setDisplaySettingsMenu}
                        designedBy={designedBy}
                        setDesignedBy={setDesignedBy}
                        checkedBy={checkedBy}
                        setCheckedBy={setCheckedBy}
                        allowableDCR={allowableDCR}
                        setAllowableDCR={setAllowableDCR} />
                )}
                {geometryInformationOverlayVisibility && (
                    <InformationOverlay informationOverlayHeading="Geometric Properties"
                        informationOverlayCaption={["Geometric Properties: Section View", "Geometric Properties: Longitudinal View"]} informationOverlayImageSrc={["../assets/circularHollowSteelSection/images/geometricPropertiesInformationOverlay_1.png", "../assets/circularHollowSteelSection/images/geometricPropertiesInformationOverlay_2.png"]}
                        closeInformationOverlays={closeInformationOverlays} />
                )}
                {materialPropertiesInformationOverlayVisibility && (
                    <InformationOverlay informationOverlayHeading="Material Properties"
                        informationOverlayCaption={["Circular Hollow Steel Section: Material Properties"]}
                        informationOverlayImageSrc={["../assets/circularHollowSteelSection/images/materialPropertiesInformationOverlay.png"]}
                        closeInformationOverlays={closeInformationOverlays} />
                )}
                {appliedForcesInformationOverlayVisibility && (
                    <InformationOverlay informationOverlayHeading="Applied Forces"
                        informationOverlayCaption={["Circular Hollow Steel Section: Applied Axial Force", "Circular Hollow Steel Section: Applied Moment and Shear Forces"]}
                        informationOverlayImageSrc={["../assets/circularHollowSteelSection/images/forcesAppliedInformationOverlay_2.png", "../assets/circularHollowSteelSection/images/forcesAppliedInformationOverlay_1.png"]}
                        closeInformationOverlays={closeInformationOverlays} />
                )}
                {setupInformationOverlayVisibility && (
                    <InformationOverlay informationOverlayHeading="Setup Information"
                        informationOverlayCaption={["Circular Hollow Steel Section: Effective Length Factors from IS800:2007 (Table 11)"]} informationOverlayImageSrc={["../assets/circularHollowSteelSection/images/setupInformationInformationOverlay.png"]}
                        closeInformationOverlays={closeInformationOverlays} />
                )}
                <div className="_designInputDiv">
                    <div className="_designInputsTitleBar">
                        <label className="_dashboardHeading">Design Inputs</label>
                    </div>
                    <div className="_designInputs">
                        <InputSheet inputData={inputData}
                            updateCalculationsFunction={calculateButtonClicked}
                            informationOverlayFunctions={informationOverlayFunctions} />
                    </div>
                </div>
                <div className="_designDashboardDiv">
                    <div className="_dashboardTitleBar">
                        <label className="_dashboardHeading" style={{ color: "black" }}>
                            Circular Hollow Steel Section
                        </label>
                        <div className="_dashboardButtons">{renderedTitleBarButtons}</div>
                    </div>
                    <div className="_dashboardTiles">
                        <div id="_dashboardLeftDiv" className="_dashboardSection">
                            <div id="dashboardTopLeftDiv" className="_dashboardTile">
                                <div class="_dashboardTileTitleBar">
                                    <label className="_tileHeading" style={{ backgroundColor: "transparent" }}>Diagrams</label>
                                    <select class="_dashboardTileTitleBarDropdown" value={selectedDiagramType} onChange={handleDiagramDropdownChange}>
                                        <option value="sectionDiagram">Section Diagram</option>
                                        <option value="loadingDiagram">Loading Diagram</option>
                                    </select>
                                </div>
                                <div className="_tileContentDiv" ref={diagramDivRef}>
                                    <svg className="diagramSVG" id="sectionDiagram" style={{ display: selectedDiagramType == "sectionDiagram" ? "block" : "none" }}>
                                        <CicrularHollowSectionDiagram frameHeight={dimensions.height}
                                            frameWidth={dimensions.width}
                                            radius={inputDataRefs.radiusRef.current ? inputDataRefs.radiusRef.current.value : ""}
                                            thickness={inputDataRefs.thicknessRef.current ? inputDataRefs.thicknessRef.current.value : ""}
                                            stroke="black"
                                            fill="#F5F5F5" />
                                    </svg>
                                    <svg className="diagramSVG" id="loadingDiagram" style={{ display: selectedDiagramType == "loadingDiagram" ? "block" : "none" }}>
                                        <CircularHollowSectionLoadingDiagram frameHeight={dimensions.height}
                                            frameWidth={dimensions.width}
                                            shearForceAlongX={inputDataRefs.shearForceAlongXRef.current ? inputDataRefs.shearForceAlongXRef.current.value : ""}
                                            shearForceAlongY={inputDataRefs.shearForceAlongYRef.current ? inputDataRefs.shearForceAlongYRef.current.value : ""}
                                            momentAboutX={inputDataRefs.momentAboutXRef.current ? inputDataRefs.momentAboutXRef.current.value : ""}
                                            momentAboutY={inputDataRefs.momentAboutYRef.current ? inputDataRefs.momentAboutYRef.current.value : ""}
                                            radius={inputDataRefs.radiusRef.current ? inputDataRefs.radiusRef.current.value : ""}
                                            thickness={inputDataRefs.thicknessRef.current ? inputDataRefs.thicknessRef.current.value : ""} />
                                    </svg>
                                    <svg className="diagramSVG" id="sectionDiagramForPDF" style={{ display: "none" }}>
                                        <CicrularHollowSectionDiagram frameHeight={printCanvasHeight}
                                            frameWidth={printCanvasWidth}
                                            radius={inputDataRefs.radiusRef.current ? inputDataRefs.radiusRef.current.value : ""}
                                            thickness={inputDataRefs.thicknessRef.current ? inputDataRefs.thicknessRef.current.value : ""}
                                            stroke="black"
                                            fill="#F5F5F5" />
                                    </svg>
                                    <svg className="diagramSVG" id="loadingDiagramForPDF" style={{ display: "none" }}>
                                        <CircularHollowSectionLoadingDiagram frameHeight={printCanvasHeight}
                                            frameWidth={printCanvasWidth}
                                            shearForceAlongX={inputDataRefs.shearForceAlongXRef.current ? inputDataRefs.shearForceAlongXRef.current.value : ""}
                                            shearForceAlongY={inputDataRefs.shearForceAlongYRef.current ? inputDataRefs.shearForceAlongYRef.current.value : ""}
                                            momentAboutX={inputDataRefs.momentAboutXRef.current ? inputDataRefs.momentAboutXRef.current.value : ""}
                                            momentAboutY={inputDataRefs.momentAboutYRef.current ? inputDataRefs.momentAboutYRef.current.value : ""}
                                            radius={inputDataRefs.radiusRef.current ? inputDataRefs.radiusRef.current.value : ""}
                                            thickness={inputDataRefs.thicknessRef.current ? inputDataRefs.thicknessRef.current.value : ""} />
                                    </svg>
                                </div>
                            </div>
                            <div id="dashboardBottomLeftDiv" className="_dashboardTile">
                                <label className="_tileHeading">Applied Forces and Capacities</label>
                                <div class="_tileContentDiv" style={{ fontSize: "14px" }}>
                                    {!designCalculationsVisibility && (
                                        <p style={{ margin: "0px", padding: "0px" }}>Press "Calculate" or change design inputs to show applied forces and capacities.</p>
                                    )}
                                    {designCalculationsVisibility && (
                                        <AppliedForcesAndDesignCapacities heading={["Description", "Applied Force", "Capacity"]}
                                            data={appliedForcesAndCapacities} />
                                    )}
                                </div>
                            </div>
                        </div>
                        <div id="_dashBoardRightDiv" className="_dashboardSection">
                            <div id="dashboardTopRightDiv" className="_dashboardTile">
                                <label className="_tileHeading">Design Calculations</label>
                                <div class="_tileContentDiv" style={{ fontSize: "14px" }}>
                                    {!designCalculationsVisibility && (
                                        <p style={{ margin: "0px", padding: "0px" }}>Press "Calculate" or change design inputs to show design calculations.</p>
                                    )}
                                    {designCalculationsVisibility && (
                                        <CalculationSheet calculationData={designCalculations} />)
                                    }
                                </div>
                            </div>
                            <div id="dashboardBottomRightDiv" className="_dashboardTile">
                                <div className="_dashboardTileTitleBar">
                                    <label className="_tileHeading" style={{ backgroundColor: "transparent" }}>Design Capacity Ratios</label>
                                </div>
                                <div class="_tileContentDiv" style={{ fontSize: "14px" }}>
                                    {!designCalculationsVisibility && (
                                        <p style={{ margin: "0px", padding: "0px" }}>Press "Calculate" or change design inputs to show design capacity ratios.</p>
                                    )}
                                    {designCalculationsVisibility && (
                                        <DesignCapacityRatios heading={["Description", "Capacity Ratio"]}
                                            data={designCapacityRatios}
                                            allowableDCR={allowableDCR} />
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="_dashboardStatusBar">
                        <label>{designStatusMessage}</label>
                        <label>Structura Pro</label>
                    </div>
                </div>
            </div>
        </>
    );
}

// Function to convert SVG to PNG
async function convertSvgToPng(svgID, height, width) {
    const svgElement = document.getElementById(svgID);
    const svgData = new XMLSerializer().serializeToString(svgElement);

    return new Promise((resolve, reject) => {
        const canvas = document.createElement("canvas");
        const context = canvas.getContext("2d");
        const image = new Image(); // Use the standard Image constructor

        image.onload = function () {
            canvas.width = width;
            canvas.height = height;
            context.drawImage(image, 0, 0);

            // Convert canvas to data URL
            const dataURL = canvas.toDataURL("image/png");
            resolve(dataURL);
        };

        image.onerror = function (error) {
            reject(error);
        };

        image.src = "data:image/svg+xml;base64," + btoa(svgData);
    });
}

export default CircularHollowSteelSection;
