import "./designCapacityRatios.css";

//style: this is where you can choose whether you want bold or not. you just have to type true or false here
function TwoLineItems({ item1, item2, bold, color }) {
    return (
        <div className="_twoLineItemDiv" style={{ fontWeight: `${bold == true ? "500" : "400"}` }}>
            <div className="_twoLineItemItem1" style={{ color: color }}>
                <label>{item1}</label>
            </div>
            <div className="_twoLineItemItem2" style={{ color: color }}>
                <label>{item2}</label>
            </div>
        </div>
    );
}

function DesignCapacityRatios({ heading, data, allowableDCR }) {
    const renderedLineItems = [];
    renderedLineItems.push(<TwoLineItems item1={heading[0]} item2={heading[1]} bold={true} />);
    Object.keys(data).forEach((key) => {
        renderedLineItems.push(<TwoLineItems item1={data[key][0]} item2={data[key][1]} color={`${data[key][1] > allowableDCR ? "red" : "green"}`} />);
    });

    return (
        <div className="_table2ContainerDiv">{renderedLineItems}</div>
    );
}

export default DesignCapacityRatios;
