// src/AuthContext.js
import React, { createContext, useContext, useEffect, useState } from "react";
import { getAuth, onAuthStateChanged } from "firebase/auth";

// Create a context
const AuthContext = createContext();

// Provide a hook to use the auth context
export const useAuth = () => useContext(AuthContext);

// Auth provider component
export const AuthProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const auth = getAuth();

        const unsubscribe = onAuthStateChanged(auth, async (user) => {
            if (user) {
                const token = await user.getIdTokenResult(true); // Force refresh

                setUser({
                ...user,
                token: token,
                paid: token.claims.paid,
                paidUntil: token.claims.paidUntil,
                });
            } else {
                setUser(null);
            }
            setLoading(false);
        });
        

        // Cleanup subscription on unmount
        return () => unsubscribe();
    }, []);

    return <AuthContext.Provider value={{ user, loading }}>{!loading && children}</AuthContext.Provider>;
};
