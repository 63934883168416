//originX and originY: refer to the tip of the arrow
//taillength: this is the length of the tail of the arrow
//arrowheadheight: this is the height of the arrow height (altitude from the tip to the base)
//stroke: the line color of the arrow
//fill: fill color of the arrow head
//direction: the direction in which the arrow is facing (up, down , right, left)

function Arrow({ originX, originY, tailLength, arrowHeadHeight, stroke, fill, direction }) {
    if (direction === "up") {
        return (
            <>
                <polygon points={`${originX},${originY} ${originX + arrowHeadHeight / 4},${originY + arrowHeadHeight} ${originX - arrowHeadHeight / 4},${originY + arrowHeadHeight}`} stroke={stroke} fill={fill} />

                <line x1={`${originX}`} y1={`${originY + arrowHeadHeight}`} x2={`${originX}`} y2={`${originY + arrowHeadHeight + tailLength}`} stroke={stroke} />
            </>
        );
    } else if (direction === "down") {
        return (
            <>
                <polygon points={`${originX},${originY} ${originX / 1 + arrowHeadHeight / 4},${originY - arrowHeadHeight} ${originX - arrowHeadHeight / 4},${originY - arrowHeadHeight}`} stroke={stroke} fill={fill} />

                <line x1={`${originX}`} y1={`${originY - arrowHeadHeight}`} x2={`${originX}`} y2={`${originY - arrowHeadHeight - tailLength}`} stroke={stroke} />
            </>
        );
    } else if (direction === "right") {
        return (
            <>
                <polygon points={`${originX},${originY} ${originX - arrowHeadHeight},${originY + arrowHeadHeight / 4} ${originX - arrowHeadHeight},${originY - arrowHeadHeight / 4}`} stroke={stroke} fill={fill} />

                <line x1={`${originX - arrowHeadHeight}`} y1={`${originY}`} x2={`${originX - arrowHeadHeight - tailLength}`} y2={`${originY}`} stroke={stroke} />
            </>
        );
    } else {
        return (
            <>
                <polygon points={`${originX},${originY} ${originX + arrowHeadHeight},${originY + arrowHeadHeight / 4} ${originX + arrowHeadHeight},${originY - arrowHeadHeight / 4}`} stroke={stroke} fill={fill} />

                <line x1={`${originX + arrowHeadHeight}`} y1={`${originY}`} x2={`${originX + arrowHeadHeight + tailLength}`} y2={`${originY}`} stroke={stroke} />
            </>
        );
    }
}

export default Arrow;
