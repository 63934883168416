import "./productTile.css";
import { Link } from "react-router-dom";

function ProductTile({ productTileHeading, productTileAccessStatus, productTileDescription, productTileLinkPath }) {
    return (
        <>
            <div className="_productTileDiv">
                <label className="_productTileHeading">{productTileHeading}</label>
                <label className="_productTileAccessStatus">{productTileAccessStatus}</label>
                <label className="_productTileDescription">{productTileDescription}</label>
                <Link to={productTileLinkPath}>
                    <button className="_productTileLinkButton">Launch</button>
                </Link>
            </div>
        </>
    );
}

export default ProductTile;
