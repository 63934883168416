function ProductSection({heading, productTiles}) {
    return (
        <div style={{display: "flex", flexDirection: "column"}}>
            <label style={{fontWeight: "500", fontSize: "20px", marginLeft: "20px"}}>{heading}</label>
            <div className="_productTilesDiv">
                {productTiles}
            </div>
        </div>
    )
}

export default ProductSection;