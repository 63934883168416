import LatexCompiler from "../../components/latexCompiler/latexCompiler";
import TitleBar from "../../components/titleBar/v2/titleBar";
import "./articles.css";
import Footer from "../../components/footer/footer"


function ImageAndCaption({ imageSrc, caption }) {
    return (
        <div style={{ width: "100%", padding: "10px", alignItems: "center", display: "flex", flexDirection: "column" }}>
            <img src={imageSrc} style={{ width: "60%", height: "auto", display: "block" }} />
            <label>{caption}</label>
        </div>
    )
}

function TrussAnalysisArticle() {
    const equations = {
        eq1: String.raw`$Ku = F$`,
        eq2: String.raw`$\mathbf{k}_e = \frac{EA}{L} \begin{bmatrix}
            1 & -1 \\
            -1 & 1
            \end{bmatrix}$`,
        eq3: String.raw`$\mathbf{T} = \begin{bmatrix}
            c^2 & cs & -c^2 & -cs \\
            cs & s^2 & -cs & -s^2 \\
            -c^2 & -cs & c^2 & cs \\
            -cs & -s^2 & cs & s^2
            \end{bmatrix}$`,
        eq4: String.raw`$\mathbf{k}_{12} = \frac{EA}{L_{12}} \mathbf{T}_{12}^T \mathbf{k}_e \mathbf{T}_{12}$`,
        eq5: String.raw`$\mathbf{k}_{12} = \frac{200 \times 10^9 \times 0.01}{707.1 \times 10^{-3}} \times 
            \begin{bmatrix}
            0.5 & 0.5 & -0.5 & -0.5 \\
            0.5 & 0.5 & -0.5 & -0.5 \\
            -0.5 & -0.5 & 0.5 & 0.5 \\
            -0.5 & -0.5 & 0.5 & 0.5
            \end{bmatrix}$`,
        eq6: String.raw`$\mathbf{k}_{12} = 
            \begin{bmatrix}
            141.4 & 141.4 & -141.4 & -141.4 \\
            141.4 & 141.4 & -141.4 & -141.4 \\
            -141.4 & -141.4 & 141.4 & 141.4 \\
            -141.4 & -141.4 & 141.4 & 141.4
            \end{bmatrix} \times 10^6 N/m$`,
        eq7: String.raw`$\mathbf{k}_{23} = \frac{EA}{L_{23}} \mathbf{T}_{23}^T \mathbf{k}_e \mathbf{T}_{23}$`,
        eq8: String.raw`$\mathbf{k}_{23} = \frac{200 \times 10^9 \times 0.01}{707.1 \times 10^{-3}} \times 
            \begin{bmatrix}
            0.5 & -0.5 & -0.5 & 0.5 \\
            -0.5 & 0.5 & 0.5 & -0.5 \\
            -0.5 & 0.5 & 0.5 & -0.5 \\
            0.5 & -0.5 & -0.5 & 0.5
            \end{bmatrix}$`,
        eq9: String.raw`$\mathbf{k}_{23} = 
            \begin{bmatrix}
            141.4 & -141.4 & -141.4 & 141.4 \\
            -141.4 & 141.4 & 141.4 & -141.4 \\
            -141.4 & 141.4 & 141.4 & -141.4 \\
            141.4 & -141.4 & -141.4 & 141.4
            \end{bmatrix} \times 10^6 N/m$`,
        eq10: String.raw`$\mathbf{k}_{13} = \frac{EA}{L_{13}} \mathbf{T}_{13}^T \mathbf{k}_e \mathbf{T}_{13}$`,
        eq11: String.raw`$\mathbf{k}_{23} = \frac{200 \times 10^9 \times 0.01}{707.1 \times 10^{-3}} \times 
            \begin{bmatrix}
            0.5 & -0.5 & -0.5 & 0.5 \\
            -0.5 & 0.5 & 0.5 & -0.5 \\
            -0.5 & 0.5 & 0.5 & -0.5 \\
            0.5 & -0.5 & -0.5 & 0.5
            \end{bmatrix}$`,
        eq12: String.raw`$\mathbf{k}_{23} = 
            \begin{bmatrix}
            141.4 & -141.4 & -141.4 & 141.4 \\
            -141.4 & 141.4 & 141.4 & -141.4 \\
            -141.4 & 141.4 & 141.4 & -141.4 \\
            141.4 & -141.4 & -141.4 & 141.4
            \end{bmatrix} \times 10^6 N/m$`,
        eq13: String.raw`$\mathbf{K} = 
            \begin{bmatrix}
            341.4 & 141.4 & -141.4 & -141.4 & -200 & 0 \\
            141.4 & 141.4 & -141.4 & -141.4 & 0 & 0 \\
            -141.4 & -141.4 & 282.8 & 0 & -141.4 & 141.4 \\
            -141.4 & -141.4 & 0 & 282.8 & 141.4 & -141.4 \\
            -200 & 0 & -141.4 & 141.4 & 341.4 & -141.4 \\
            0 & 0 & 141.4 & -141.4 & -141.4 & 141.4
            \end{bmatrix} \times 10^6 N/m$`,
        eq14: String.raw`$\mathbf{K}_{reduced} = 
            \begin{bmatrix}
            282.8 & 0 \\
            0 & 282.8
            \end{bmatrix} \times 10^6 N/m$`,
        eq15: String.raw`$\mathbf{F}_{reduced} = 
            \begin{bmatrix}
            100 \times 10^3 \\
            0
            \end{bmatrix} N$`,
        eq16: String.raw`$\mathbf{u}_{reduced} = \mathbf{K}_{reduced}^{-1} \mathbf{F}_{reduced}$`,
        eq17: String.raw`$\mathbf{u}_{reduced} = 
            \begin{bmatrix}
            \frac{100 \times 10^3}{282.8 \times 10^6} \\
            0
            \end{bmatrix}$`,
        eq18: String.raw`$\mathbf{u}_{reduced} = 
            \begin{bmatrix}
            0.000354 \\
            0
            \end{bmatrix} m$`,
        eq19: String.raw`$\mathbf{f}_{12} = \mathbf{k}_{12} \times 
            \begin{bmatrix}
            0 \\
            0 \\
            0.000354 \\
            0
            \end{bmatrix} 
            = 
            \begin{bmatrix}
            -50\sqrt{2} \\
            -50\sqrt{2} \\
            50\sqrt{2} \\
            50\sqrt{2}
            \end{bmatrix} kN$`,
        eq20: String.raw`$\mathbf{f}_{23} = \mathbf{k}_{23} \times 
            \begin{bmatrix}
            0.000354 \\
            0 \\
            0 \\
            0
            \end{bmatrix}
            = 
            \begin{bmatrix}
            50\sqrt{2} \\
            -50\sqrt{2} \\
            -50\sqrt{2} \\
            50\sqrt{2}
            \end{bmatrix} kN$`,
        eq21: String.raw`$\mathbf{f}_{13} = \mathbf{k}_{13} \times 
            \begin{bmatrix}
            0 \\
            0 \\
            0.000354 \\
            0
            \end{bmatrix}
            = 
            \begin{bmatrix}
            0 \\
            0 \\
            0 \\
            0
            \end{bmatrix} kN$`
    }

    return (
        <>
            <TitleBar />
            <div style={{ width: "100%", display: "flex", flexDirection: "column", alignItems: "center", padding: "10px", overflowY: "scroll", marginBottom: "10px" }}>
                <div style={{ width: "60%", display: "flex", flexDirection: "column" }}>
                    <div style={{ display: "flex", flexDirection: "column", alignItems: "start", marginBottom: "15px", paddingBottom: "10px", width: "100%", borderBottom: "1px solid black" }}>
                        <label className="_articleContentTitle">Truss Analysis: Stiffness Matrix Method</label>
                        <label>Utkarsh Gupta | August 22, 2024</label>
                    </div>
                    <label className="_articleContentHeading">Table of Contents</label>
                    <div className="_listContainer">
                        <ol>
                            <li><a href="#introduction">Introduction</a></li>
                            <li><a href="#analysisAssumptions">Analysis Assumptions</a></li>
                            <li><a href="#theory">Theory</a>
                                <ol type="1">
                                    <li><a href="#basicConcepts">Basic Concepts</a></li>
                                    <li><a href="#elementStiffnessMatrix">Element Stiffness Matrix</a></li>
                                    <li><a href="#transformationToGlobalStiffnessMatrix">Transformation to Global Stiffness Matrix</a></li>
                                </ol>
                            </li>
                            <li><a href="#exampleProblem">Example Problem</a>
                                <ol type="1">
                                    <li><a href="#step1">Step 1: Discretize the Truss</a></li>
                                    <li><a href="#step2">Step 2: Formulate the Element Stiffness Matrices</a></li>
                                    <li><a href="#step3">Step 3: Assemble the Global Stiffness Matrix</a></li>
                                    <li><a href="#step4">Step 4: Apply Boundary Conditions</a></li>
                                    <li><a href="#step5">Step 5: Solve for Displacements</a></li>
                                    <li><a href="#step6">Step 6: Solve for Forces</a></li>
                                </ol>
                            </li>
                            <li><a href="#conculsion">Conclusion</a></li>
                        </ol>
                    </div>
                    <label className="_articleContentHeading" id="introduction">1. Introduction</label>
                    <p>This article outlines the steps and procedures of analysing trusses using the stiffness matrix method.</p>
                    <label className="_articleContentHeading" id="analysisAssumptions">2. Analysis Assumptions</label>
                    <p>The following assumptions have been made in the analysis of the provided beam:
                        <ul>
                            <li>Members are connected by friction-less pins.</li>
                            <li>Loads are applied only on the joints.</li>
                            <li>In truss analysis, it is vital to distinguish between the global coordinate system and the local coordinate system. The global coordinate system is for the entire structure, while the local coordinate system is for each truss member.</li>
                        </ul>
                    </p>
                    <label className="_articleContentHeading" id="theory">3. Theory</label>
                    <label className="_articleContentSubHeading" id="basicConcepts">3.1. Basic Concepts</label>
                    The stiffness matrix method is based on the principle of superposition and the relationship between forces and displacements in a structure. For a linear elastic structure, the relationship can be expressed as:
                    <LatexCompiler latexInput={equations.eq1} alignment="center" />
                    where:
                    <ul>
                        <li><b>K</b> is the global stiffness matrix</li>
                        <li><b>u</b> is the displacement vector</li>
                        <li><b>F</b> is the force vector</li>
                    </ul>
                    <label className="_articleContentSubHeading" id="elementStiffnessMatrix">3.2. Element Stiffness Matrix</label>
                    For a truss element, the element stiffness matrix in the local coordinate system is given by:
                    <LatexCompiler latexInput={equations.eq2} alignment="center" />
                    where:
                    <ul>
                        <li><b>E</b> is the modulus of elasticity</li>
                        <li><b>A</b> is the cross sectional area</li>
                        <li><b>L</b> is the length of the element</li>
                    </ul>
                    <label className="_articleContentSubHeading" id="transformationToGlobalStiffnessMatrix">3.3. Transformation to Global Stiffness Matrix</label>
                    To transform the element stiffness matrix from local to global coordinates, the following transformation matrix is used:
                    <LatexCompiler latexInput={equations.eq3} alignment="center" />
                    where:
                    <ul>
                        <li><LatexCompiler latexInput={String.raw`c is $\cos\theta$`} /></li>
                        <li><LatexCompiler latexInput={String.raw`s is $\sin\theta$`} /></li>
                        <li><LatexCompiler latexInput={String.raw`$\theta$ is the angle of the turss element with respest to the global coordinate system`} /></li>
                    </ul>
                    <label className="_articleContentHeading" id="exampleProblem">4. Example Problem</label>
                    Here we are going to do a step by step analysis for the truss shown in the figure below:
                    <ImageAndCaption imageSrc="../assets/trussAnalysis/images/exampleProblem.png" caption="Figure 1: Example Problem" />
                    <label className="_articleContentSubHeading" id="step1">4.1. Step 1: Discretize the Truss</label>
                    The truss consists of three members:
                    <ul>
                        <li>Member 1-2: Between Node 1 and 2</li>
                        <li>Member 2-3: Between Node 2 and 3</li>
                        <li>Member 1-3: Between Node 1 and 3</li>
                    </ul>
                    Each node has two degrees of freedom (DOF): horizontal displacement (x) and vertical displacement (y). So, we have a total of 6 DOFs.
                    <label className="_articleContentSubHeading" id="step2">4.2. Step 2: Formulate the Element Stiffness Matrices</label>
                    Assume the following properties for the truss members:
                    <ul>
                        <li><LatexCompiler latexInput={String.raw`Modulus of Elasticity, $E = 200 GPa$`} /></li>
                        <li><LatexCompiler latexInput={String.raw`Cross Sectional Area, $A = 0.01 m^2$`} /></li>
                    </ul>
                    Calculate the length and orientation of each member:
                    <ul>
                        <li>Length of Member 1-2: <LatexCompiler latexInput={String.raw`$L_{12} = \sqrt{(500 - 0)^2 + (500 - 0)^2} = 707.1 mm$`} alignment="center" /></li>
                        <li>Length of Member 2-3: <LatexCompiler latexInput={String.raw`$L_{23} = \sqrt{(1000 - 500)^2 + (0 - 500)^2} = 707.1 mm$`} alignment="center" /></li>
                        <li>Length of Member 1-3: <LatexCompiler latexInput={String.raw`$L_{13} = 1000 mm$`} alignment="center" /></li>
                    </ul>
                    The orientation of each member:
                    <ul>
                        <li><LatexCompiler latexInput={String.raw`$\theta_{12} = 45^\circ$`} /></li>
                        <li><LatexCompiler latexInput={String.raw`$\theta_{23} = -45^\circ$`} /></li>
                        <li><LatexCompiler latexInput={String.raw`$\theta_{13} = 0^\circ$`} /></li>
                    </ul>
                    Now calculate the stiffness matrix for each member in the global coordinate system.
                    <b>Member 1-2 (Node 1 to Node 2)</b>
                    <LatexCompiler latexInput={equations.eq4} alignment="center" />
                    <LatexCompiler latexInput={equations.eq5} alignment="center" />
                    <LatexCompiler latexInput={equations.eq6} alignment="center" />
                    <b>Member 2-3 (Node 2 to Node 3)</b>
                    <LatexCompiler latexInput={equations.eq7} alignment="center" />
                    <LatexCompiler latexInput={equations.eq8} alignment="center" />
                    <LatexCompiler latexInput={equations.eq9} alignment="center" />
                    <b>Member 1-3 (Node 1 to Node 3)</b>
                    <LatexCompiler latexInput={equations.eq10} alignment="center" />
                    <LatexCompiler latexInput={equations.eq11} alignment="center" />
                    <LatexCompiler latexInput={equations.eq12} alignment="center" />
                    <label className="_articleContentSubHeading" id="step3">4.3. Step 3: Assemble the Global Stiffness Matrix</label>
                    Assemble the global stiffness matrix by combining the contributions from each element. The resulting global stiffness matrix K is:
                    <LatexCompiler latexInput={equations.eq13} alignment="center" />
                    <label className="_articleContentSubHeading" id="step4">4.4. Step 4: Apply Boundary Conditions</label>
                    The boundary conditions are applied at Node 1 and Node 3 where displacements are zero. This results in the reduced stiffness matrix:
                    <LatexCompiler latexInput={equations.eq14} alignment="center" />
                    <label className="_articleContentSubHeading" id="step5">4.5. Step 5: Solve for Displacements</label>
                    Given the force vector:
                    <LatexCompiler latexInput={equations.eq15} alignment="center" />
                    Solve for displacements:
                    <LatexCompiler latexInput={equations.eq16} alignment="center" />
                    <LatexCompiler latexInput={equations.eq17} alignment="center" />
                    <LatexCompiler latexInput={equations.eq18} alignment="center" />
                    So, the displacements are as follows:
                    <LatexCompiler latexInput={String.raw`$u_{2x} = 0.000354 m$`} />
                    <LatexCompiler latexInput={String.raw`$u_{2y} = 0 m$`} />
                    For completeness, the displacements at nodes 1 and 3 are all 0 since they are restrained for moment along the X an Y direction by the pin joint.
                    <label className="_articleContentSubHeading" id="step6">4.6. Step 6: Solve for Forces</label>
                    Using the displacements obtained, calculate the internal forces in each truss member.
                    <b>For Member 1-2:</b>
                    <LatexCompiler latexInput={equations.eq19} alignment="center" />
                    <b>For Member 2-3:</b>
                    <LatexCompiler latexInput={equations.eq20} alignment="center" />
                    <b>For Member 1-3:</b>
                    <LatexCompiler latexInput={equations.eq21} alignment="center" />
                    <label className="_articleContentHeading" id="conculsion">5. Conclusion</label>
                    This detailed manual provides a thorough understanding of the stiffness matrix method and its application to truss analysis. The final displacements and internal forces are calculated accurately, and the method can be applied to other truss structures with similar procedures.
                </div>
            </div>
            <Footer textColor="white" />
        </>
    )
}

export default TrussAnalysisArticle;