import React, { useState } from 'react';
import Latex from 'react-latex-next';
import 'katex/dist/katex.min.css';

const LatexCompiler = ({ latexInput, alignment }) => {
  return (
    <div style={{ marginTop: "10px", marginBottom: "10px", display: "flex", flexDirection: "column", alignItems: `${alignment}` }} >
      <Latex>{latexInput}</Latex>
    </div >
  );
};

export default LatexCompiler;