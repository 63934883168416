import "./settingsMenu.css";
import React, { useState, useRef } from "react";

function SettingsMenu({ setDisplaySettingsMenuFunction, designedBy, setDesignedBy, checkedBy, setCheckedBy, allowableDCR, setAllowableDCR }) {
    const designedByInputRef = useRef(null);
    const checkedByInputRef = useRef(null);
    const allowableDCRRef = useRef(null);

    return (
        <div className="_settingsOverlay" id="settingsOverlay">
            <div className="_settingsDiv">
                <div className="_settingsTitleDiv">
                    {/* settings heading */}
                    <h1 className="_settingsTitleHeading">Settings</h1>
                    {/* close button */}
                    <label
                        className="_settingsTitleCloseButton"
                        onClick={() => {
                            document.getElementById("settingsOverlay").style.display = "none";
                            setDisplaySettingsMenuFunction(false);
                        }}
                    >
                        Close
                    </label>
                </div>
                <div className="_designStandardDiv">
                    {/* design standard selection dropdown */}
                    <label className="_designStandardLabel">Design Standard</label>
                    <select className="_designStandardOption">
                        <option>Indian Standard Code</option>
                    </select>
                </div>
                <div className="_allowableDCRDiv">
                    {/* allowable dcr */}
                    <label className="_allowableDCRLabel">Allowable DCR</label>
                    <input className="_allowableDCRInput" type="number" defaultValue={allowableDCR} min={0} max={1} step={0.01} ref={allowableDCRRef} />
                </div>
                <div className="_designedByDiv">
                    <label className="_designedByLabel">Designed By</label>
                    <input className="_designedByInput" type="text" defaultValue={designedBy} ref={designedByInputRef} />
                </div>
                <div className="_checkedByDiv">
                    <label className="_checkedByLabel">Checked By</label>
                    <input className="_checkedByInput" type="text" defaultValue={checkedBy} ref={checkedByInputRef} />
                </div>
                <div className="_doneButtonDiv">
                    <div style={{ flex: 1 }}></div>
                    <label
                        className="_doneButton"
                        onClick={() => {
                            //udpate designed by name
                            setDesignedBy(designedByInputRef.current ? designedByInputRef.current.value : "");
                            //update checked by name
                            setCheckedBy(checkedByInputRef.current ? checkedByInputRef.current.value : "");
                            //update allowable DCR
                            setAllowableDCR(allowableDCRRef.current ? allowableDCRRef.current.value : "");
                            //close settings menu
                            document.getElementById("settingsOverlay").style.display = "none";
                            setDisplaySettingsMenuFunction(false);
                        }}
                    >
                        Done
                    </label>
                </div>
            </div>
        </div>
    );
}

export default SettingsMenu;
