import InformationPage from "../../components/informationPage/informationPage";

function AboutUs() {
    const heading = "About Us";
    const content = {
        heading1: ["Welcome to Structura Pro!", "Welcome to Structura Pro, a groundbreaking platform dedicated to revolutionizing civil engineering education. In an era where technology is rapidly transforming every facet of our lives, it's time for the field of civil engineering to evolve as well."],
        heading2: ["Our Mission", "For over three decades, the teaching of civil engineering has remained largely unchanged, relying heavily on traditional methods. We believe it's time to shift towards a more practical, forward-thinking, and technologically advanced approach. Our mission is to empower students and educators by providing tools and resources that make learning more engaging, effective, and aligned with the demands of the modern world."],
        heading3: ["Why We Exist", "Civil engineering is fundamentally a visual and practical discipline. Concepts and structures that are difficult to grasp through textbooks and lectures can be easily understood when presented in a visual format. At Structura Pro, we leverage cutting-edge technology to create immersive and interactive learning experiences. Our goal is to make complex ideas accessible and enjoyable, fostering a deeper understanding and passion for civil engineering."],
        heading4: ["What We Offer", "Innovative Learning Tools: From 3D modeling software to virtual reality simulations, we offer a range of tools designed to enhance the learning experience.\nProfessional Development: Resources and training programs that help educators stay current with the latest technological advancements and teaching methodologies.\nCommunity and Collaboration: A platform where students and educators can connect, share ideas, and collaborate on projects, fostering a vibrant community of like-minded individuals.\nPractical Applications: Real-world case studies and projects that bridge the gap between theory and practice, preparing students for successful careers in civil engineering."],
        heading5: ["Join Us on This Journey", "We invite you to explore our website and discover how we can help you become more professional, forward-thinking, and technologically adept in the field of civil engineering. Whether you're a student eager to learn or an educator looking to inspire, Structura Pro is here to support your journey.\nThank you for being a part of our community. Together, we can shape the future of civil engineering education."]
    };

    return (
        <>
            <InformationPage informationPageHeading={heading} content={content} />
        </>
    );
}

export default AboutUs;