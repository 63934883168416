import React, { useContext } from "react";
import { TrussContext } from "./trussContext";

const ElementTable = () => {
    const { elements, nodes } = useContext(TrussContext);

    const calculateLength = (start, end) => {
        return Math.sqrt((end.x - start.x) ** 2 + (end.y - start.y) ** 2).toFixed(2);
    };

    const calculateAngle = (start, end) => {
        const radians = Math.atan2(end.y - start.y, end.x - start.x);
        let degrees = (radians * 180) / Math.PI;
        if (degrees < 0) {
            degrees += 360; // Ensure the angle is positive
        }
        return degrees.toFixed(2);
    };
    
    return (
        <table className="informationTable">
            <thead>
                <tr>
                    <th>ID</th>
                    <th>S. Node</th>
                    <th>E. Node</th>
                    <th>L</th>
                    <th>A</th>
                </tr>
            </thead>
            <tbody>
                {elements.map((element, index) => {
                    const startNodeIndex = nodes.findIndex(
                        (node) => node.x === element.start.x && node.y === element.start.y
                    );
                    const endNodeIndex = nodes.findIndex(
                        (node) => node.x === element.end.x && node.y === element.end.y
                    );

                    return (
                        <tr key={index}>
                            <td>{`E${index + 1}`}</td>
                            <td>{`N${startNodeIndex + 1}`}</td>
                            <td>{`N${endNodeIndex + 1}`}</td>
                            <td>{calculateLength(element.start, element.end)}</td>
                            <td>{calculateAngle(element.start, element.end)}</td>
                        </tr>
                    );
                })}
            </tbody>
        </table>
    );
};

export default ElementTable;