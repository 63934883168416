import Arrow from "./arrow";

//originX and originY: this is the center of the beam that we are showing the end conditions on
//length: is the length of the beam that we are showing the loading conditions on
//height: is the height of the beam we are showing the loading conditions on
//endCondition: is the actual end condition on the beam
//stroke: this is the color of the end condition lines that we want

function EndCondition({ originX, originY, length, height, endCondition, stroke }) {
    if (endCondition === "Fix-Fix") {
        return (
            <>
                {/*left vertical line*/}
                <line x1={`${originX - length / 2}`} y1={`${originY - height / 2 - 10}`} x2={`${originX - length / 2}`} y2={`${originY + height / 2 + 10}`} stroke={stroke} />
                {/*left slant shading*/}
                <line x1={`${originX - length / 2}`} y1={`${originY - height / 2 - 10}`} x2={`${originX - length / 2 - 5}`} y2={`${originY - height / 2 - 10 + 5}`} stroke={stroke} />
                <line x1={`${originX - length / 2}`} y1={`${originY - (height / 2 + 10) / 2}`} x2={`${originX - length / 2 - 5}`} y2={`${originY - (height / 2 + 10) / 2 + 5}`} stroke={stroke} />
                <line x1={`${originX - length / 2}`} y1={`${originY}`} x2={`${originX - length / 2 - 5}`} y2={`${originY + 5}`} stroke={stroke} />
                <line x1={`${originX - length / 2}`} y1={`${originY + (height / 2 + 10) / 2}`} x2={`${originX - length / 2 - 5}`} y2={`${originY + (height / 2 + 10) / 2 + 5}`} stroke={stroke} />
                <line x1={`${originX - length / 2}`} y1={`${originY + height / 2 + 10}`} x2={`${originX - length / 2 - 5}`} y2={`${originY + height / 2 + 10 + 5}`} stroke={stroke} />
                {/*right vertical line*/}
                <line x1={`${originX + length / 2}`} y1={`${originY - height / 2 - 10}`} x2={`${originX + length / 2}`} y2={`${originY + height / 2 + 10}`} stroke={stroke} />
                {/*right slant shading*/}
                <line x1={`${originX + length / 2}`} y1={`${originY - height / 2 - 10}`} x2={`${originX + length / 2 + 5}`} y2={`${originY - height / 2 - 10 + 5}`} stroke={stroke} />
                <line x1={`${originX + length / 2}`} y1={`${originY - (height / 2 + 10) / 2}`} x2={`${originX + length / 2 + 5}`} y2={`${originY - (height / 2 + 10) / 2 + 5}`} stroke={stroke} />
                <line x1={`${originX + length / 2}`} y1={`${originY}`} x2={`${originX + length / 2 + 5}`} y2={`${originY + 5}`} stroke={stroke} />
                <line x1={`${originX + length / 2}`} y1={`${originY + (height / 2 + 10) / 2}`} x2={`${originX + length / 2 + 5}`} y2={`${originY + (height / 2 + 10) / 2 + 5}`} stroke={stroke} />
                <line x1={`${originX + length / 2}`} y1={`${originY + height / 2 + 10}`} x2={`${originX + length / 2 + 5}`} y2={`${originY + height / 2 + 10 + 5}`} stroke={stroke} />
            </>
        );
    } else if (endCondition === "Fix-Free") {
        return (
            <>
                {/*left vertical line*/}
                <line x1={`${originX - length / 2}`} y1={`${originY - height / 2 - 10}`} x2={`${originX - length / 2}`} y2={`${originY + height / 2 + 10}`} stroke={stroke} />
                {/*left slant shading*/}
                <line x1={`${originX - length / 2}`} y1={`${originY - height / 2 - 10}`} x2={`${originX - length / 2 - 5}`} y2={`${originY - height / 2 - 10 + 5}`} stroke={stroke} />
                <line x1={`${originX - length / 2}`} y1={`${originY - (height / 2 + 10) / 2}`} x2={`${originX - length / 2 - 5}`} y2={`${originY - (height / 2 + 10) / 2 + 5}`} stroke={stroke} />
                <line x1={`${originX - length / 2}`} y1={`${originY}`} x2={`${originX - length / 2 - 5}`} y2={`${originY + 5}`} stroke={stroke} />
                <line x1={`${originX - length / 2}`} y1={`${originY + (height / 2 + 10) / 2}`} x2={`${originX - length / 2 - 5}`} y2={`${originY + (height / 2 + 10) / 2 + 5}`} stroke={stroke} />
                <line x1={`${originX - length / 2}`} y1={`${originY + height / 2 + 10}`} x2={`${originX - length / 2 - 5}`} y2={`${originY + height / 2 + 10 + 5}`} stroke={stroke} />
            </>
        );
    } else if (endCondition == "Free-Fix") {
        return (
            <>
                {/*right vertical line*/}
                <line x1={`${originX + length / 2}`} y1={`${originY - height / 2 - 10}`} x2={`${originX + length / 2}`} y2={`${originY + height / 2 + 10}`} stroke={stroke} />
                {/*right slant shading*/}
                <line x1={`${originX + length / 2}`} y1={`${originY - height / 2 - 10}`} x2={`${originX + length / 2 + 5}`} y2={`${originY - height / 2 - 10 + 5}`} stroke={stroke} />
                <line x1={`${originX + length / 2}`} y1={`${originY - (height / 2 + 10) / 2}`} x2={`${originX + length / 2 + 5}`} y2={`${originY - (height / 2 + 10) / 2 + 5}`} stroke={stroke} />
                <line x1={`${originX + length / 2}`} y1={`${originY}`} x2={`${originX + length / 2 + 5}`} y2={`${originY + 5}`} stroke={stroke} />
                <line x1={`${originX + length / 2}`} y1={`${originY + (height / 2 + 10) / 2}`} x2={`${originX + length / 2 + 5}`} y2={`${originY + (height / 2 + 10) / 2 + 5}`} stroke={stroke} />
                <line x1={`${originX + length / 2}`} y1={`${originY + height / 2 + 10}`} x2={`${originX + length / 2 + 5}`} y2={`${originY + height / 2 + 10 + 5}`} stroke={stroke} />
            </>
        );
    } else if (endCondition == "Fix-Pin") {
        return (
            <>
                {/*left vertical line*/}
                <line x1={`${originX - length / 2}`} y1={`${originY - height / 2 - 10}`} x2={`${originX - length / 2}`} y2={`${originY + height / 2 + 10}`} stroke={stroke} />
                {/*left slant shading*/}
                <line x1={`${originX - length / 2}`} y1={`${originY - height / 2 - 10}`} x2={`${originX - length / 2 - 5}`} y2={`${originY - height / 2 - 10 + 5}`} stroke={stroke} />
                <line x1={`${originX - length / 2}`} y1={`${originY - (height / 2 + 10) / 2}`} x2={`${originX - length / 2 - 5}`} y2={`${originY - (height / 2 + 10) / 2 + 5}`} stroke={stroke} />
                <line x1={`${originX - length / 2}`} y1={`${originY}`} x2={`${originX - length / 2 - 5}`} y2={`${originY + 5}`} stroke={stroke} />
                <line x1={`${originX - length / 2}`} y1={`${originY + (height / 2 + 10) / 2}`} x2={`${originX - length / 2 - 5}`} y2={`${originY + (height / 2 + 10) / 2 + 5}`} stroke={stroke} />
                <line x1={`${originX - length / 2}`} y1={`${originY + height / 2 + 10}`} x2={`${originX - length / 2 - 5}`} y2={`${originY + height / 2 + 10 + 5}`} stroke={stroke} />
                {/*right triangle*/}
                <Arrow originX={originX + length / 2} originY={originY + height / 2} tailLength={0} arrowHeadHeight={15} stroke="black" fill="none" direction="up" />
                {/*shading below triangle*/}
                {/*main line*/}
                <line x1={`${originX + length / 2 + 15}`} y1={`${originY + height / 2 + 15}`} x2={`${originX + length / 2 - 15}`} y2={`${originY + height / 2 + 15}`} stroke={stroke} />
                {/*shading*/}
                <line x1={`${originX + length / 2 + 15}`} y1={`${originY + height / 2 + 15}`} x2={`${originX + length / 2 + 15 - 5}`} y2={`${originY + height / 2 + 15 + 5}`} stroke={stroke} />
                <line x1={`${originX + length / 2}`} y1={`${originY + height / 2 + 15}`} x2={`${originX + length / 2 - 5}`} y2={`${originY + height / 2 + 15 + 5}`} stroke={stroke} />
                <line x1={`${originX + length / 2 - 15}`} y1={`${originY + height / 2 + 15}`} x2={`${originX + length / 2 - 15 - 5}`} y2={`${originY + height / 2 + 15 + 5}`} stroke={stroke} />
            </>
        );
    } else if (endCondition == "Pin-Fix") {
        return (
            <>
                {/*right vertical line*/}
                <line x1={`${originX + length / 2}`} y1={`${originY - height / 2 - 10}`} x2={`${originX + length / 2}`} y2={`${originY + height / 2 + 10}`} stroke={stroke} />
                {/*right slant shading*/}
                <line x1={`${originX + length / 2}`} y1={`${originY - height / 2 - 10}`} x2={`${originX + length / 2 + 5}`} y2={`${originY - height / 2 - 10 + 5}`} stroke={stroke} />
                <line x1={`${originX + length / 2}`} y1={`${originY - (height / 2 + 10) / 2}`} x2={`${originX + length / 2 + 5}`} y2={`${originY - (height / 2 + 10) / 2 + 5}`} stroke={stroke} />
                <line x1={`${originX + length / 2}`} y1={`${originY}`} x2={`${originX + length / 2 + 5}`} y2={`${originY + 5}`} stroke={stroke} />
                <line x1={`${originX + length / 2}`} y1={`${originY + (height / 2 + 10) / 2}`} x2={`${originX + length / 2 + 5}`} y2={`${originY + (height / 2 + 10) / 2 + 5}`} stroke={stroke} />
                <line x1={`${originX + length / 2}`} y1={`${originY + height / 2 + 10}`} x2={`${originX + length / 2 + 5}`} y2={`${originY + height / 2 + 10 + 5}`} stroke={stroke} />
                {/*left triangle*/}
                <Arrow originX={originX - length / 2} originY={originY + height / 2} tailLength={0} arrowHeadHeight={15} stroke="black" fill="none" direction="up" />
                {/*shading below triangle*/}
                {/*main line*/}
                <line x1={`${originX / 1 - length / 2 + 15}`} y1={`${originY + height / 2 + 15}`} x2={`${originX / 1 - length / 2 - 15}`} y2={`${originY + height / 2 + 15}`} stroke={stroke} />
                {/*shading*/}
                <line x1={`${originX - length / 2 + 15}`} y1={`${originY + height / 2 + 15}`} x2={`${originX - length / 2 + 15 - 5}`} y2={`${originY + height / 2 + 15 + 5}`} stroke={stroke} />
                <line x1={`${originX - length / 2}`} y1={`${originY + height / 2 + 15}`} x2={`${originX - length / 2 - 5}`} y2={`${originY + height / 2 + 15 + 5}`} stroke={stroke} />
                <line x1={`${originX - length / 2 - 15}`} y1={`${originY + height / 2 + 15}`} x2={`${originX - length / 2 - 15 - 5}`} y2={`${originY + height / 2 + 15 + 5}`} stroke={stroke} />
            </>
        );
    } else {
        //pin pin condition
        return (
            <>
                {/*right triangle*/}
                <Arrow originX={originX + length / 2} originY={originY + height / 2} tailLength={0} arrowHeadHeight={15} stroke="black" fill="none" direction="up" />
                {/*shading below triangle*/}
                {/*main line*/}
                <line x1={`${originX + length / 2 + 15}`} y1={`${originY + height / 2 + 15}`} x2={`${originX + length / 2 - 15}`} y2={`${originY + height / 2 + 15}`} stroke={stroke} />
                {/*shading*/}
                <line x1={`${originX + length / 2 + 15}`} y1={`${originY + height / 2 + 15}`} x2={`${originX + length / 2 + 15 - 5}`} y2={`${originY + height / 2 + 15 + 5}`} stroke={stroke} />
                <line x1={`${originX + length / 2}`} y1={`${originY + height / 2 + 15}`} x2={`${originX + length / 2 - 5}`} y2={`${originY + height / 2 + 15 + 5}`} stroke={stroke} />
                <line x1={`${originX + length / 2 - 15}`} y1={`${originY + height / 2 + 15}`} x2={`${originX + length / 2 - 15 - 5}`} y2={`${originY + height / 2 + 15 + 5}`} stroke={stroke} />
                {/*left triangle*/}
                <Arrow originX={originX - length / 2} originY={originY + height / 2} tailLength={0} arrowHeadHeight={15} stroke="black" fill="none" direction="up" />
                {/*shading below triangle*/}
                {/*main line*/}
                <line x1={`${originX - length / 2 + 15}`} y1={`${originY + height / 2 + 15}`} x2={`${originX - length / 2 - 15}`} y2={`${originY + height / 2 + 15}`} stroke={stroke} />
                {/*shading*/}
                <line x1={`${originX - length / 2 + 15}`} y1={`${originY + height / 2 + 15}`} x2={`${originX - length / 2 + 15 - 5}`} y2={`${originY + height / 2 + 15 + 5}`} stroke={stroke} />
                <line x1={`${originX - length / 2}`} y1={`${originY + height / 2 + 15}`} x2={`${originX - length / 2 - 5}`} y2={`${originY + height / 2 + 15 + 5}`} stroke={stroke} />
                <line x1={`${originX - length / 2 - 15}`} y1={`${originY + height / 2 + 15}`} x2={`${originX - length / 2 - 15 - 5}`} y2={`${originY + height / 2 + 15 + 5}`} stroke={stroke} />
            </>
        );
    }
}

export default EndCondition;
