import "./designPagesStyles.css";
import Buttons from "../../components/buttons/buttons";
import React, { useRef, useState, useEffect, useReducer } from "react";
import axios from "axios";
import InputSheet from "../../components/inputSheet/inputSheet";
import InformationOverlay from "../../components/informationOverlay/informationOverlay";
import CalculationSheet from "../../components/calculationSheet/calculationSheet";
import DataSeriesChart from "../../components/chart/dataSeriesChart/dataSeriesChart";
import BeamAnalysisDiagram from "../../components/diagrams/beamAnalysisDiagram";

//updateForModularity: change the name of the function object that is going to be returned depending on the design sheet
function BeamAnalysis() {
    //****************************************INPUT REFS AND INPUT VARIABLES****************************************
    //updateForModularity: refs for the inputs that you want to take from the user
    //Ref Variables
    const inputDataRefs = {
        lengthRef: useRef(null),
        momentOfInertiaRef: useRef(null),
        modulusOfElasticityRef: useRef(null),
        loadTypeRef: useRef(null),
        loadRef: useRef(null),
        distanceFromStartRef: useRef(null),
        endConditionRef: useRef(null)
    };

    //applied load unit
    const [appliedLoadUnit, setAppliedLoadUnit] = useState("kN/m");
    const [distanceFromStartVisibility, setDistanceFromStartVisibility] = useState(false);

    //updateForModularity: the input data object that will be used to render the input sheet on the user interface
    //Input Variables
    const inputData = {
        //geometric properties
        length: ["Geometric Properties", "Length", ["number", inputDataRefs.lengthRef, 3000], "mm"],
        momentOfInertia: ["Geometric Properties", "Moment of Inertia", ["number", inputDataRefs.momentOfInertiaRef, 53000000000], "mm⁴"],
        //material properties
        modulusOfElasticity: ["Material Properties", "Modulus of Elasticity", ["number", inputDataRefs.modulusOfElasticityRef, 200000], "MPa"],
        //applied forces
        loadType: ["Applied Forces", "Load Type", ["dropdown", inputDataRefs.loadTypeRef, ["Uniform Line Load", "Point Load"]], ""],
        load: ["Applied Forces", "Load", ["number", inputDataRefs.loadRef, 10], appliedLoadUnit],
        distanceFromStart: ["Applied Forces", "Distance From Start", ["number", inputDataRefs.distanceFromStartRef, 500], "mm", distanceFromStartVisibility],
        //setup information
        endCondition: ["Setup Information", "End Condition", ["dropdown", inputDataRefs.endConditionRef, ["Fix-Fix", "Pin-Fix", "Free-Fix", "Pin-Pin"]], ""],
    };

    ///****************************************DIAGRAMS****************************************
    //selected diagaram type state variable
    const [selectedDiagramType, setSelectedDiagramType] = useState("bendingMomentDiagram"); //which diagram has been selected in the diagram section
    //diagram div state variables
    const diagramDivRef = useRef(null); // ref for the div containing the diagram
    const [dimensions, setDimensions] = useState({ width: 0, height: 0 }); //dimensions of the div containing the diagram

    //resizing the diagram when the screen size changes
    useEffect(() => {
        const observeTarget = diagramDivRef.current;
        const resizeObserver = new ResizeObserver((entries) => {
            // Assuming you want to observe the first entry
            const { width, height } = entries[0].contentRect;
            setDimensions({ width, height });
        });
        // Step 2: Initialize the ResizeObserver
        if (observeTarget) {
            resizeObserver.observe(observeTarget);
        }

        // Step 3: Clean up
        return () => {
            if (observeTarget) {
                resizeObserver.disconnect();
            }
        };
    }, []); // Empty dependency array ensures this effect runs only once

    //handle dropdown change for selecting diagram
    const handleDiagramDropdownChange = (event) => {
        setSelectedDiagramType(event.target.value);
    };

    //****************************************CALCULATE BUTTON****************************************
    //calculations related state variables
    const [designStatusMessage, setDesignStatusMessage] = useState("Status: Click calculate to design section.")
    const [designCalculationsVisibility, setDesignCalculationsVisibility] = useState(false); //whether calculations are shown or not
    const [nodes, setNodes] = useState([]);
    const [displacements, setDisplacements] = useState([]);
    const [bendingMoments, setBendingMoments] = useState([]);
    const [shearForce, setShearForce] = useState([]);
    const [analysisResults, setAnalysisResults] = useState({});
    const [analysisEquations, setAnalysisEquations] = useState({});

    //handle the clickdown of calculate button
    const calculateButtonClicked = async () => {
        //since this is the function that is attached with the input change, we are going to use this to change the applied load unit and the distance from start visibility as well
        //check what is the load type
        const loadType = inputDataRefs.loadTypeRef.current ? inputDataRefs.loadTypeRef.current.value : "";
        if (loadType == "Uniform Line Load") {
            setAppliedLoadUnit("kN/m");
            setDistanceFromStartVisibility(false);
        } else {
            setAppliedLoadUnit("N");
            setDistanceFromStartVisibility(true);
        }
        //set design status message
        setDesignStatusMessage("Status: Analysing beam...");
        //Create request object
        const requestObject = {};
        Object.keys(inputDataRefs).forEach((key) => {
            const keyName = key.replace(/Ref$/, ""); //Removing the "Ref" suffix from each of the key name
            requestObject[`${keyName}`] = inputDataRefs[key].current.value;
        });

        //Send data to back end
        //updateForModularity: change name of fetch request depending on the design sheet
        axios
            .post("/api/fetchBeamAnalysisCalculations", requestObject, {
                headers: {
                    "Content-Type": "application/json",
                },
            })
            .then((response) => {
                setNodes(response.data["nodes"]);
                setDisplacements(response.data["displacements"]);
                setBendingMoments(response.data["bendingMoments"]);
                setShearForce(response.data["shearForces"]);
                setAnalysisResults(response.data["analysisResults"]);
                setAnalysisEquations(response.data["analysisEquations"]);


                //set the state variales that we have to change things on the front end
                setDesignCalculationsVisibility(true); //Set design calculations visibility
                setDesignStatusMessage("Status: Beam analysis complete.")
            })
            .catch((error) => {
                console.error(`The request could not be completed beacause of ${error}`);
            });
    };

    //****************************************PRINT BUTTON****************************************
    //handle clickdown of print pdf button
    const printCanvasHeight = 400;
    const printCanvasWidth = 500;
    const printButtonClicked = async () => {
        //set design status message
        setDesignStatusMessage("Status: Generating PDF...");
        //first we create a request object
        const bendingMomentDiagramURL = openCanvasAsPng("bendingMomentDiagramDivForPDF");
        const shearForceDiagramURL = openCanvasAsPng("shearForceDiagramDivForPDF");
        const displacementDiagramURL = openCanvasAsPng("displacemnentDiagramDivForPDF");
        const beamDiagramURL = await convertSvgToPng("beamDiagramForPDF", printCanvasHeight, printCanvasWidth);

        const requestObject = {
            maxDisplacement: analysisResults["maxDisplacement"][2],
            maxDisplacementAt: analysisResults["maxDisplacementNode"][2],
            startBendingMoment: analysisResults["startBendingMoment"][2],
            endBendingMoment: analysisResults["endBendingMoment"][2],
            maxMoment: analysisResults["maxMoment"][2],
            maxMomentAt: analysisResults["maxMomentNode"][2],
            minMoment: analysisResults["minMoment"][2],
            minMomentAt: analysisResults["minMomentNode"][2],
            bendingMomentDiagram: bendingMomentDiagramURL,
            shearForceDiagram: shearForceDiagramURL,
            displacementDiagram: displacementDiagramURL,
            beamDiagram: beamDiagramURL,
            startShearForce: analysisResults["startShearForce"][2],
            endShearForce: analysisResults["endShearForce"][2],
            maxShear: analysisResults["maxShear"][2],
            maxShearNode: analysisResults["maxShearNode"][2],
            minShear: analysisResults["minShear"][2],
            minShearNode: analysisResults["minShearNode"][2]
        }

        //then we send this to the api that is going to generate the pdf for us
        fetch('/api/generateBeamAnalysisPDF', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(requestObject)
        })
            .then(response => response.blob())
            .then(blob => {
                //set design status message
                setDesignStatusMessage("Status: PDF Generation Complete.")
                const url = window.URL.createObjectURL(blob);
                window.open(url, '_blank');
            })
            .catch(error => console.error('Error:', error));
    }

    //****************************************FEEDBACK BUTTON****************************************
    const feedbackButtonClicked = () => {
        window.open("https://forms.gle/Az8WUKkhQbNMhtnp9", "_blank");
    }

    //****************************************INFORMATION BUTTON****************************************
    //handle the clickdown of information button
    const informationButtonClicked = () => {
        window.open("../assets/beamAnalysis/designManual.pdf", "_blank");
    };

    //****************************************INFORMATION OVERLAYS****************************************
    //information overlay state variables
    const [geometryInformationOverlayVisibility, setGeometryInformationOverlayVisibility] = useState(false);
    const [materialPropertiesInformationOverlayVisibility, setMaterialPropertiesInformationOverlayVisibility] = useState(false);
    const [appliedForcesInformationOverlayVisibility, setAppliedForcesInformationOverlayVisibility] = useState(false);
    const [setupInformationOverlayVisibility, setSetupInformationOverlayVisibility] = useState(false);

    //information overlay functions
    const geometryInformationButtonClicked = () => {
        setGeometryInformationOverlayVisibility(true);
    };

    const materialPropertiesInformationButtonClicked = () => {
        setMaterialPropertiesInformationOverlayVisibility(true);
    };

    const appliedForcesInformationButtonClicked = () => {
        setAppliedForcesInformationOverlayVisibility(true);
    };

    const setupInformationButtonClicked = () => {
        setSetupInformationOverlayVisibility(true);
    };

    const closeInformationOverlays = () => {
        setGeometryInformationOverlayVisibility(false);
        setMaterialPropertiesInformationOverlayVisibility(false);
        setAppliedForcesInformationOverlayVisibility(false);
        setSetupInformationOverlayVisibility(false);
    };

    const informationOverlayFunctions = [geometryInformationButtonClicked, materialPropertiesInformationButtonClicked, appliedForcesInformationButtonClicked, setupInformationButtonClicked];

    //****************************************TITLE BAR BUTTONS****************************************
    //title bar buttons
    const titleBarButtons = {
        calculateButton: ["Calculate", calculateButtonClicked],
        printButton: ["Print", printButtonClicked],
        informationButton: ["Information", informationButtonClicked],
        feedbackButton: ["Feedback", feedbackButtonClicked]
    };

    const renderedTitleBarButtons = [];
    Object.keys(titleBarButtons).forEach((key) => {
        renderedTitleBarButtons.push(<Buttons type="textButton"
            text={titleBarButtons[key][0]}
            fontColor="black"
            backgroundColor=""
            linkTo=""
            onClickFunction={titleBarButtons[key][1]} />);
    });

    return (
        <>
            <div className="_containerDiv">
                {geometryInformationOverlayVisibility && (
                    <InformationOverlay informationOverlayHeading="Geometric Properties"
                        informationOverlayCaption={["Geometric Properties: Longitudinal View"]} informationOverlayImageSrc={["..\\assets\\beamAnalysis\\images\\geometricInformationOverlay.png"]}
                        closeInformationOverlays={closeInformationOverlays} />
                )}
                {materialPropertiesInformationOverlayVisibility && (
                    <InformationOverlay informationOverlayHeading="Material Properties"
                        informationOverlayCaption={["Beam Material Properties"]}
                        informationOverlayImageSrc={["..\\assets\\beamAnalysis\\images\\materialPropertiesInformationOverlay.png"]} closeInformationOverlays={closeInformationOverlays} />
                )}
                {appliedForcesInformationOverlayVisibility && (
                    <InformationOverlay informationOverlayHeading="Applied Forces"
                        informationOverlayCaption={[" Applied Forces (Uniform Line Load and Point Load)"]} informationOverlayImageSrc={["..\\assets\\beamAnalysis\\images\\appliedForcesInformationOverlay.png"]}
                        closeInformationOverlays={closeInformationOverlays} />
                )}
                {setupInformationOverlayVisibility && (
                    <InformationOverlay informationOverlayHeading="Setup Information"
                        informationOverlayCaption={["Setup Information"]} informationOverlayImageSrc={["..\\assets\\beamAnalysis\\images\\setupInformationOverlay.png"]} closeInformationOverlays={closeInformationOverlays} />
                )}
                <div className="_designInputDiv">
                    <div className="_designInputsTitleBar">
                        <label className="_dashboardHeading">Design Inputs</label>
                    </div>
                    <div className="_designInputs">
                        <InputSheet inputData={inputData}
                            updateCalculationsFunction={calculateButtonClicked}
                            informationOverlayFunctions={informationOverlayFunctions} />
                    </div>
                </div>
                <div className="_designDashboardDiv">
                    <div className="_dashboardTitleBar">
                        <label className="_dashboardHeading" style={{ color: "black" }}>
                            Beam Analysis
                        </label>
                        <div className="_dashboardButtons">{renderedTitleBarButtons}</div>
                    </div>
                    <div className="_dashboardTiles">
                        <div id="_dashboardLeftDiv" className="_dashboardSection">
                            <div id="dashboardTopLeftDiv" className="_dashboardTile">
                                <div className="_dashboardTileTitleBar">
                                    <label className="_tileHeading" style={{ backgroundColor: "transparent" }}>Diagram</label>
                                </div>
                                <div className="_tileContentDiv" ref={diagramDivRef}>
                                    <svg className="diagramSVG">
                                        <BeamAnalysisDiagram frameHeight={dimensions.height}
                                            frameWidth={dimensions.width}
                                            loadType={inputDataRefs.loadTypeRef.current ? inputDataRefs.loadTypeRef.current.value : ""}
                                            load={inputDataRefs.loadRef.current ? inputDataRefs.loadRef.current.value : ""}
                                            endCondition={inputDataRefs.endConditionRef.current ? inputDataRefs.endConditionRef.current.value : ""}
                                            length={inputDataRefs.lengthRef.current ? inputDataRefs.lengthRef.current.value : ""}
                                            distanceFromStart={inputDataRefs.distanceFromStartRef.current ? inputDataRefs.distanceFromStartRef.current.value : ""} />
                                    </svg>
                                    <svg className="diagramSVG" id="beamDiagramForPDF" style={{ display: "none" }}>
                                        <BeamAnalysisDiagram frameHeight={printCanvasHeight}
                                            frameWidth={printCanvasWidth}
                                            loadType={inputDataRefs.loadTypeRef.current ? inputDataRefs.loadTypeRef.current.value : ""}
                                            load={inputDataRefs.loadRef.current ? inputDataRefs.loadRef.current.value : ""}
                                            endCondition={inputDataRefs.endConditionRef.current ? inputDataRefs.endConditionRef.current.value : ""}
                                            length={inputDataRefs.lengthRef.current ? inputDataRefs.lengthRef.current.value : ""}
                                            distanceFromStart={inputDataRefs.distanceFromStartRef.current ? inputDataRefs.distanceFromStartRef.current.value : ""} />
                                    </svg>
                                </div>
                            </div>
                            <div id="dashboardBottomLeftDiv" className="_dashboardTile">
                                <div className="_dashboardTileTitleBar">
                                    <label className="_tileHeading" style={{ backgroundColor: "transparent" }}>BMD and SFD</label>
                                    <select className="_dashboardTileTitleBarDropdown" value={selectedDiagramType} onChange={handleDiagramDropdownChange}>
                                        <option value="bendingMomentDiagram">Bending Moment Diagram</option>
                                        <option value="shearForceDiargram">Shear Force Diagram</option>
                                    </select>
                                </div>
                                <div class="_tileContentDiv" style={{ fontSize: "14px", position: "relative" }} ref={diagramDivRef}>
                                    {!designCalculationsVisibility && (
                                        <p style={{ margin: "0px", padding: "0px" }}>Press "Calculate" or change design inputs to show bending moment and shear force diagram.</p>
                                    )}
                                    {designCalculationsVisibility && (
                                        <>
                                            <div id="bendingMomentDiagram" style={{ display: selectedDiagramType == "bendingMomentDiagram" ? "block" : "none", position: "absolute", top: "0", left: "0" }}>
                                                <DataSeriesChart chartID="bendingMomentDiagramDiv"
                                                    frameHeight={dimensions.height}
                                                    frameWidth={dimensions.width}
                                                    xDataset={nodes}
                                                    yDataset={bendingMoments}
                                                    xAxisTitle="Distance Along X (mm)"
                                                    yAxisTitle="Bending Moments (kN-m)" />
                                            </div>
                                            <div id="shearForceDiagram" style={{ display: selectedDiagramType == "shearForceDiargram" ? "block" : "none", position: "absolute", top: "0", left: "0" }}>
                                                <DataSeriesChart chartID="shearForceDiagramDiv"
                                                    frameHeight={dimensions.height}
                                                    frameWidth={dimensions.width}
                                                    xDataset={nodes}
                                                    yDataset={shearForce}
                                                    xAxisTitle="Distance Along X (mm)"
                                                    yAxisTitle="Shear Force (kN)" />
                                            </div>
                                            <div style={{ display: "block", position: "absolute", top: "0", left: "0", zIndex: "-5" }}>
                                                <DataSeriesChart chartID="bendingMomentDiagramDivForPDF"
                                                    frameHeight={dimensions.height}
                                                    frameWidth={dimensions.width}
                                                    xDataset={nodes}
                                                    yDataset={bendingMoments}
                                                    xAxisTitle="Distance Along X (mm)"
                                                    yAxisTitle="Bending Moments (kN-m)" />
                                            </div>
                                            <div style={{ display: "block", position: "absolute", top: "0", left: "0", zIndex: "-5" }}>
                                                <DataSeriesChart chartID="shearForceDiagramDivForPDF"
                                                    frameHeight={dimensions.height}
                                                    frameWidth={dimensions.width}
                                                    xDataset={nodes}
                                                    yDataset={shearForce}
                                                    xAxisTitle="Distance Along X (mm)"
                                                    yAxisTitle="Shear Force (kN)" />
                                            </div>
                                        </>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div id="_dashBoardRightDiv" className="_dashboardSection">
                            <div id="dashboardTopRightDiv" className="_dashboardTile">
                                <label className="_tileHeading">Analysis Results</label>
                                <div class="_tileContentDiv" style={{ fontSize: "14px" }}>
                                    {!designCalculationsVisibility && (
                                        <p style={{ margin: "0px", padding: "0px" }}>Press "Calculate" or change design inputs to show analysis results.</p>
                                    )}
                                    {designCalculationsVisibility && (
                                        <CalculationSheet calculationData={analysisResults} />)
                                    }
                                </div>
                            </div>
                            <div id="dashboardBottomRightDiv" className="_dashboardTile">
                                <div className="_dashboardTileTitleBar">
                                    <label className="_tileHeading" style={{ backgroundColor: "transparent" }}>Displacement Graph</label>
                                </div>
                                <div class="_tileContentDiv" style={{ fontSize: "14px", position: "relative" }}>
                                    {!designCalculationsVisibility && (
                                        <p style={{ margin: "0px", padding: "0px", fontSize: "14px" }}>Press "Calculate" or change design inputs to show displacement graph.</p>
                                    )}
                                    {designCalculationsVisibility && (
                                        <>
                                            <div style={{ display: "block", position: "absolute", top: "0", left: "0" }}>
                                                <DataSeriesChart chartID="displacemnentDiagramDiv"
                                                    frameHeight={dimensions.height}
                                                    frameWidth={dimensions.width}
                                                    xDataset={nodes}
                                                    yDataset={displacements}
                                                    xAxisTitle="Distance Along X (mm)"
                                                    yAxisTitle="Displacements (mm)" />
                                            </div>
                                            <div style={{ display: "block", position: "absolute", top: "0", left: "0", zIndex: "-5" }}>
                                                <DataSeriesChart chartID="displacemnentDiagramDivForPDF"
                                                    frameHeight={dimensions.height}
                                                    frameWidth={dimensions.width}
                                                    xDataset={nodes}
                                                    yDataset={displacements}
                                                    xAxisTitle="Distance Along X (mm)"
                                                    yAxisTitle="Displacements (mm)" />
                                            </div>
                                        </>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="_dashboardStatusBar">
                        <label>{designStatusMessage}</label>
                        <label>Structura Pro</label>
                    </div>
                </div>
            </div>
        </>
    );
}

// Function to convert SVG to PNG
async function convertSvgToPng(svgID, height, width) {
    const svgElement = document.getElementById(svgID);
    const svgData = new XMLSerializer().serializeToString(svgElement);

    return new Promise((resolve, reject) => {
        const canvas = document.createElement("canvas");
        const context = canvas.getContext("2d");
        const image = new Image(); // Use the standard Image constructor

        image.onload = function () {
            canvas.width = width;
            canvas.height = height;
            context.drawImage(image, 0, 0);

            // Convert canvas to data URL
            const dataURL = canvas.toDataURL("image/png");
            resolve(dataURL);
        };

        image.onerror = function (error) {
            reject(error);
        };

        image.src = "data:image/svg+xml;base64," + btoa(svgData);
    });
}

function openCanvasAsPng(divId) {
    // Get the div element by ID
    const div = document.getElementById(divId);

    // Find the canvas element within the div
    const canvas = div.querySelector('canvas');

    // Check if a canvas element was found
    if (canvas) {
        // Convert the canvas content to a data URL (PNG format)
        const dataUrl = canvas.toDataURL('image/png');
        return dataUrl;
    } else {
        console.error('No canvas element found in the specified div.');
        return "could not find image";
    }
}

export default BeamAnalysis;
