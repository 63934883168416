import DimensionLine from "./elements/dimensionLine";
import Legend from "./elements/legend";
import Circle from "./elements/circle";
import DottedLine from "./elements/dottedLine";

function CicrularHollowSectionDiagram({ frameHeight, frameWidth, radius, thickness, stroke, fill }) {
    const frameCenterX = frameWidth / 2;
    const frameCenterY = frameHeight / 2;
    const displayRadius = (frameHeight) / 3.5;
    const displayThickness = 10;
    const dottedLineExtension = 20;
    const legend = {
        radius: ["black", `Radius: ${radius} mm`],
        thickness: ["black", `Thickness: ${thickness} mm`],
    };

    return (
        <>
            {/*outer circle*/}
            <Circle originX={frameCenterX} originY={frameCenterY} radius={displayRadius} fill={fill} stroke={stroke} />
            {/*inner circle*/}
            <Circle originX={frameCenterX} originY={frameCenterY} radius={displayRadius - displayThickness} fill="white" stroke={stroke} />
            {/*radius dimension line*/}
            <DimensionLine originX={frameCenterX + displayRadius / 2} originY={frameCenterY} spanDirection="horizontal" span={displayRadius} text={`${radius} mm`} textAlignment="top" stroke="black" />
            {/*thickness dimension line*/}
            <DimensionLine originX={frameCenterX} originY={frameCenterY + displayRadius - displayThickness / 2} spanDirection="vertical" span={displayThickness} text={`${thickness} mm`} textAlignment="top" stroke="black" />
            {/*x-axis*/}
            <DottedLine originX={frameCenterX}
                originY={frameCenterY}
                length={2 * displayRadius + dottedLineExtension}
                spanDirection="horizontal"
                stroke="darkgrey" />
            <text x={frameCenterX}
                y={frameCenterY - displayRadius - dottedLineExtension}
                fill="darkgrey"
                dominantBaseline="middle"
                textAnchor="middle">Y</text>
            <text x={frameCenterX}
                y={frameCenterY + displayRadius + dottedLineExtension}
                fill="darkgrey"
                dominantBaseline="middle"
                textAnchor="middle">Y</text>
            {/*y-axis*/}
            <DottedLine originX={frameCenterX}
                originY={frameCenterY}
                length={2 * displayRadius + dottedLineExtension}
                spanDirection="vertical"
                stroke="darkgrey" />
            <text x={frameCenterX + displayRadius + dottedLineExtension}
                y={frameCenterY}
                fill="darkgrey"
                dominantBaseline="middle"
                textAnchor="middle">X</text>
            <text x={frameCenterX - displayRadius - dottedLineExtension}
                y={frameCenterY}
                fill="darkgrey"
                dominantBaseline="middle"
                textAnchor="middle">X</text>
            {/*legend*/}
            <Legend originX={10} originY={frameCenterY + 30} sideLength={5} verticalSpacing={20} items={legend} />
        </>
    );
}

export default CicrularHollowSectionDiagram;
