import React, { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";

const PaymentProtectedRoute = ({ children }) => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [isPaidUser, setIsPaidUser] = useState(false);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const checkUserStatus = async () => {
            try {
                const response = await fetch('/api/check-authenticated-user', {
                    method: 'GET',
                    credentials: 'include', // This will include the HTTP-only cookies in the request
                });

                if (response.ok) {
                    const data = await response.json();
                    setIsAuthenticated(data.isAuthenticatedUser);
                } else {
                    setIsAuthenticated(false);
                }
            } catch (error) {
                console.error('Error checking user authentication status:', error);
                setIsAuthenticated(false);
            }

            try {
                const response = await fetch('/api/check-paid-user', {
                    method: 'GET',
                    credentials: 'include', // This will include the HTTP-only cookies in the request
                });

                if (response.ok) {
                    const data = await response.json();
                    setIsPaidUser(data.isPaidUser);
                } else {
                    setIsPaidUser(false);
                }
            } catch (error) {
                console.error('Error checking user paid status:', error);
                setIsPaidUser(false);
            } finally {
                setLoading(false);
            }
        };

        checkUserStatus();
    }, []);

    if (loading) {
        return <div>Loading...</div>; // You can customize this as needed
    }


    if (!isAuthenticated) {
        return <Navigate to="/signin" />;
    } else if (!isPaidUser) {
        return <Navigate to="/unpaidUser" />;
    }

    return children;
};


const SignInProtectedRoute = ({ children }) => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const checkUserStatus = async () => {
            try {
                const response = await fetch('/api/check-authenticated-user', {
                    method: 'GET',
                    credentials: 'include', // This will include the HTTP-only cookies in the request
                });

                if (response.ok) {
                    const data = await response.json();
                    setIsAuthenticated(data.isAuthenticatedUser);
                } else {
                    setIsAuthenticated(false);
                }
            } catch (error) {
                console.error('Error checking user authentication status:', error);
                setIsAuthenticated(false);
            } finally {
                setLoading(false);
            }
        };

        checkUserStatus();
    }, []);

    if (loading) {
        return <div>Loading...</div>; // You can customize this as needed
    }


    if (!isAuthenticated) {
        return <Navigate to="/unauthenticatedUser" />;
    }

    return children;
};

export { PaymentProtectedRoute, SignInProtectedRoute };
