//originX and originY: to the middle of the line
//length: length of the line you want to make
//spanDiection: in which direction you want the line to span; horizontal or vertical

function DottedLine({ originX, originY, length, spanDirection, stroke }) {
    if (spanDirection === "horizontal") {
        return (
            <>
                <line x1={originX + length / 2} y1={originY} x2={originX - length / 2} y2={originY} stroke={stroke} strokeDasharray="4 4" />
            </>
        );
    } else {
        //vertical span direction
        return (
            <>
                <line x1={originX} y1={originY + length / 2} x2={originX} y2={originY - length / 2} stroke={stroke} strokeDasharray="4 4" />
            </>
        );
    }
}

export default DottedLine;
