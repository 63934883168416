import React, { createContext, useState } from 'react';

export const TrussContext = createContext();

export const TrussProvider = ({ children }) => {
    const [nodes, setNodes] = useState([]);
    const [elements, setElements] = useState([]);
    const [selectedNodes, setSelectedNodes] = useState([]);
    const [selectedElements, setSelectedElements] = useState([]);

    return (
        <TrussContext.Provider value={{ nodes, setNodes, elements, setElements, selectedNodes, setSelectedNodes, selectedElements, setSelectedElements }}>
            {children}
        </TrussContext.Provider>
    );
};
