import "./trussAnalysisCanvas.css";
import React, { useEffect, useRef, useState, useContext } from "react";
import { TrussContext } from "./trussContext";

function TrussAnalysisCanvas() {
    const trussAnalysisCanvasRef = useRef(null);
    const [currentNode, setCurrentNode] = useState(null);
    const [highlightedNode, setHighlightedNode] = useState(null);
    const [highlightedElement, setHighlightedElement] = useState(null);
    const [inputMode, setInputMode] = useState(false);
    const [lengthInput, setLengthInput] = useState("");
    const [angleInput, setAngleInput] = useState("");
    const [isLengthActive, setIsLengthActive] = useState(true);
    const [mousePos, setMousePos] = useState({ x: 0, y: 0 });
    const [selectionRect, setSelectionRect] = useState(null);
    const [isPanning, setIsPanning] = useState(false);
    const [panStart, setPanStart] = useState({ x: 0, y: 0 });
    const [panOffset, setPanOffset] = useState({ x: 0, y: 0 });
    const [scale, setScale] = useState(0.5);

    const { nodes, setNodes, elements, setElements, selectedNodes, setSelectedNodes, selectedElements, setSelectedElements } = useContext(TrussContext);

    const SNAP_THRESHOLD = 10; // Define the snapping distance

    useEffect(() => {
        const canvas = trussAnalysisCanvasRef.current;
        const context = canvas.getContext("2d");

        const setCanvasSize = () => {
            canvas.width = canvas.offsetWidth;
            canvas.height = canvas.offsetHeight;
            redrawCanvas();
        };


        const drawNode = (x, y, dof, force, label, highlight = false, selected = false) => {
            context.fillStyle = selected ? "yellow" : highlight ? "yellow" : "blue";

            if (dof.Tx === 0 && dof.Ty === 0 && dof.Rx === 1 && dof.Ry === 1) {
                // Draw a triangle with the tip at the node location
                context.beginPath();
                context.moveTo(x, y - 10);
                context.lineTo(x - 10, y + 10);
                context.lineTo(x + 10, y + 10);
                context.closePath();
                context.fill();
            } else if (dof.Tx === 0 && dof.Ty === 0 && dof.Rx === 0 && dof.Ry === 0) {
                // Draw a square centered at the node location
                context.beginPath();
                context.rect(x - 10, y - 10, 20, 20);
                context.fill();
            } else {
                // Draw a circle (default)
                context.beginPath();
                context.arc(x, y, 10, 0, 2 * Math.PI);
                context.fill();
            }

            // Draw the force arrow if there is a force applied
            if (force.magnitude !== 0) {
                const arrowLength = 60; // Fixed length for visualization
                const radians = (force.angle * Math.PI) / 180;
                const endX = x + arrowLength * Math.cos(radians);
                const endY = y - arrowLength * Math.sin(radians);

                context.strokeStyle = "red";
                context.fillStyle = "red";
                context.beginPath();
                context.moveTo(x, y);
                context.lineTo(endX, endY);
                context.stroke();

                // Draw arrowhead
                context.beginPath();
                context.moveTo(endX, endY);
                context.lineTo(endX - 10 * Math.cos(radians - Math.PI / 6), endY + 10 * Math.sin(radians - Math.PI / 6));
                context.lineTo(endX - 10 * Math.cos(radians + Math.PI / 6), endY + 10 * Math.sin(radians + Math.PI / 6));
                context.closePath();
                context.fill();

                // Draw the force label
                context.fillStyle = "red";
                context.font = "20px Arial";
                context.fillText(`${force.magnitude} kN`, endX + 5, endY - 5);
            }

            if (label) {
                context.fillStyle = "black";
                context.font = "20px Arial";
                context.fillText(label, x + 16, y - 16);
            }
        };

        const drawElement = (startNode, endNode, label, highlight = false, selected = false) => {
            context.strokeStyle = selected ? "red" : highlight ? "lightgrey" : "black";
            context.beginPath();
            context.moveTo(startNode.x, startNode.y);
            context.lineTo(endNode.x, endNode.y);
            context.stroke();
            if (label) {
                const midX = (startNode.x + endNode.x) / 2;
                const midY = (startNode.y + endNode.y) / 2;
                context.fillStyle = "black";
                context.font = "20px Arial";
                context.fillText(label, midX + 16, midY - 16);
            }
        };

        const redrawCanvas = () => {
            context.clearRect(0, 0, canvas.width, canvas.height);
            context.save();
            context.translate(panOffset.x, panOffset.y);
            context.scale(scale, scale);
            nodes.forEach((node, index) => {
                drawNode(node.x, node.y, node.dof, node.force, `N${index + 1}`, node === highlightedNode, selectedNodes.includes(node));
            });
            elements.forEach((element, index) => {
                drawElement(element.start, element.end, `E${index + 1}`, index === highlightedElement, selectedElements.includes(index));
            });

            // Draw the current element preview if in input mode
            if (currentNode) {
                if (inputMode && lengthInput !== "" && angleInput !== "") {
                    const radians = (angleInput * Math.PI) / 180;
                    const endX = currentNode.x + lengthInput * Math.cos(radians);
                    const endY = currentNode.y - lengthInput * Math.sin(radians);

                    context.strokeStyle = "gray";
                    context.beginPath();
                    context.moveTo(currentNode.x, currentNode.y);
                    context.lineTo(endX, endY);
                    context.stroke();

                    // Draw the length and angle input fields
                    context.fillStyle = isLengthActive ? "blue" : "red";
                    context.fillText(`L: ${lengthInput}`, mousePos.x + 10, mousePos.y + 10);
                    context.fillStyle = !isLengthActive ? "blue" : "red";
                    context.fillText(`A: ${angleInput}`, mousePos.x + 10, mousePos.y + 25);
                } else {
                    context.strokeStyle = "gray";
                    context.beginPath();
                    context.moveTo(currentNode.x, currentNode.y);
                    context.lineTo(mousePos.x, mousePos.y);
                    context.stroke();

                    // Draw the length and angle input fields
                    context.fillStyle = isLengthActive ? "blue" : "red";
                    context.fillText(`L: ${lengthInput}`, mousePos.x + 10, mousePos.y + 10);
                    context.fillStyle = !isLengthActive ? "blue" : "red";
                    context.fillText(`A: ${angleInput}`, mousePos.x + 10, mousePos.y + 25);
                }
            }

            // Draw selection rectangle
            if (selectionRect) {
                context.strokeStyle = "blue";
                context.strokeRect(
                    selectionRect.x,
                    selectionRect.y,
                    selectionRect.width,
                    selectionRect.height
                );
            }

            context.restore();
        };

        const handleCanvasClick = (event) => {
            if (selectionRect) {
                setSelectionRect(null);
                setSelectedNodes([]);
                setSelectedElements([]);
                redrawCanvas();
                return;
            }

            const rect = canvas.getBoundingClientRect();
            let x = (event.clientX - rect.left - panOffset.x) / scale;
            let y = (event.clientY - rect.top - panOffset.y) / scale;

            let snappedNode = getSnappedNode(x, y);
            if (snappedNode) {
                x = snappedNode.x;
                y = snappedNode.y;
            }


            if (!currentNode) {
                if (nodes.length === 0 || snappedNode) {  // Allow the first node anywhere or subsequent nodes to be snapped nodes
                    setCurrentNode(snappedNode || { x, y, dof: { Tx: 1, Ty: 1, Rx: 1, Ry: 1 }, force: { magnitude: 0, angle: 0 } });
                    if (!snappedNode) {
                        setNodes((prevNodes) => [...prevNodes, { x, y, dof: { Tx: 1, Ty: 1, Rx: 1, Ry: 1 }, force: { magnitude: 0, angle: 0 } }]);
                    }
                }
            } else {
                if (!snappedNode) {
                    setNodes((prevNodes) => [...prevNodes, { x, y, dof: { Tx: 1, Ty: 1, Rx: 1, Ry: 1 }, force: { magnitude: 0, angle: 0 } }]);
                }
                setElements((prevElements) => [
                    ...prevElements,
                    { start: currentNode, end: { x, y, dof: { Tx: 1, Ty: 1, Rx: 1, Ry: 1 }, force: { magnitude: 0, angle: 0 } } },
                ]);
                setCurrentNode(null);
                redrawCanvas();  // Redraw the canvas to include the new element
            }
        };

        const handleMouseMove = (event) => {
            const rect = canvas.getBoundingClientRect();
            let x = (event.clientX - rect.left - panOffset.x) / scale;
            let y = (event.clientY - rect.top - panOffset.y) / scale;

            setMousePos({ x, y });

            if (isPanning) {
                const dx = event.clientX - panStart.x;
                const dy = event.clientY - panStart.y;
                setPanOffset({ x: panOffset.x + dx, y: panOffset.y + dy });
                setPanStart({ x: event.clientX, y: event.clientY });
                redrawCanvas();
                return;
            }

            if (selectionRect) {
                const width = x - selectionRect.startX;
                const height = y - selectionRect.startY;
                setSelectionRect({
                    ...selectionRect,
                    width,
                    height,
                });
                redrawCanvas();
                return;
            }

            let snappedNode = getSnappedNode(x, y);
            if (snappedNode) {
                x = snappedNode.x;
                y = snappedNode.y;
            }

            setCursorStyle(event, x, y);
            redrawCanvas();

            // Draw the current node and temporary preview
            if (currentNode && !inputMode) {
                context.save(); // Save the current context state
                context.translate(panOffset.x, panOffset.y); // Apply pan offset
                context.scale(scale, scale); // Apply scale

                drawNode(currentNode.x, currentNode.y, currentNode.dof, currentNode.force, null);

                // Draw the current element preview
                context.strokeStyle = "gray";
                context.beginPath();
                context.moveTo(currentNode.x, currentNode.y);
                context.lineTo(x, y);
                context.stroke();

                context.restore(); // Restore the context state
            }

            // Draw a temporary cursor indicator at the snapped node location
            if (snappedNode) {
                context.save(); // Save the current context state
                context.translate(panOffset.x, panOffset.y); // Apply pan offset
                context.scale(scale, scale); // Apply scale

                drawTemporaryCursor(snappedNode.x, snappedNode.y);

                context.restore(); // Restore the context state
            }
        };

        const handleMouseDown = (event) => {
            if (event.button === 1) { // Middle mouse button
                event.preventDefault();
                setIsPanning(true);
                setPanStart({ x: event.clientX, y: event.clientY });
            } else if (event.button === 0) { // Left mouse button for selection rectangle
                const rect = canvas.getBoundingClientRect();
                const startX = (event.clientX - rect.left - panOffset.x) / scale;
                const startY = (event.clientY - rect.top - panOffset.y) / scale;

                setSelectionRect({
                    startX,
                    startY,
                    x: startX,
                    y: startY,
                    width: 0,
                    height: 0,
                });
            }
        };

        const handleMouseUp = (event) => {
            if (event.button === 1) { // Middle mouse button
                setIsPanning(false);
            } else if (event.button === 0 && selectionRect) { // Left mouse button
                const rect = canvas.getBoundingClientRect();
                const endX = (event.clientX - rect.left - panOffset.x) / scale;
                const endY = (event.clientY - rect.top - panOffset.y) / scale;
                const { startX, startY } = selectionRect;

                const newSelectedNodes = nodes.filter(
                    (node) =>
                        node.x >= Math.min(startX, endX) &&
                        node.x <= Math.max(startX, endX) &&
                        node.y >= Math.min(startY, endY) &&
                        node.y <= Math.max(startY, endY)
                );

                const newSelectedElements = elements.map((element, index) => {
                    const startWithin = element.start.x >= Math.min(startX, endX) &&
                        element.start.x <= Math.max(startX, endX) &&
                        element.start.y >= Math.min(startY, endY) &&
                        element.start.y <= Math.max(startY, endY);

                    const endWithin = element.end.x >= Math.min(startX, endX) &&
                        element.end.x <= Math.max(startX, endX) &&
                        element.end.y >= Math.min(startY, endY) &&
                        element.end.y <= Math.max(startY, endY);

                    return startWithin && endWithin ? index : -1;
                }).filter(index => index !== -1);

                setSelectedNodes(newSelectedNodes);
                setSelectedElements(newSelectedElements);
                setSelectionRect(null);
                redrawCanvas();
            }
        };

        const handleWheel = (event) => {
            event.preventDefault();

            const rect = canvas.getBoundingClientRect();
            const mouseX = (event.clientX - rect.left - panOffset.x) / scale;
            const mouseY = (event.clientY - rect.top - panOffset.y) / scale;

            const zoomFactor = 0.1;
            const newScale = event.deltaY < 0 ? scale + zoomFactor : scale - zoomFactor;
            const clampedScale = newScale > 0.1 ? newScale : 0.1;

            const newPanOffsetX = mouseX * (scale - clampedScale);
            const newPanOffsetY = mouseY * (scale - clampedScale);

            setScale(clampedScale);
            setPanOffset({
                x: panOffset.x + newPanOffsetX,
                y: panOffset.y + newPanOffsetY,
            });

            redrawCanvas();
        };

        const drawTemporaryCursor = (x, y) => {
            context.strokeStyle = "red";
            context.lineWidth = 1;
            context.beginPath();
            context.moveTo(x - 5, y - 5);
            context.lineTo(x + 5, y + 5);
            context.moveTo(x + 5, y - 5);
            context.lineTo(x - 5, y + 5);
            context.stroke();
        };

        const setCursorStyle = (event, x, y) => {
            let nodeHighlight = null;
            let elementHighlight = null;

            const nearNode = nodes.some((node) => {
                const isNear = Math.hypot(node.x - x, node.y - y) < SNAP_THRESHOLD;
                if (isNear) nodeHighlight = node;
                return isNear;
            });

            const nearElement = elements.some((element, index) => {
                const distanceToSegment = pointToSegmentDistance(x, y, element.start, element.end);
                if (distanceToSegment < 5) {
                    elementHighlight = index;
                    return true;
                }
                return false;
            });

            if (nearNode) {
                canvas.style.cursor = "crosshair";
            } else if (nearElement) {
                canvas.style.cursor = "pointer";
            } else {
                canvas.style.cursor = "default";
            }

            setHighlightedNode(nodeHighlight);
            setHighlightedElement(elementHighlight);
            redrawCanvas();
        };

        const getSnappedNode = (x, y) => {
            return nodes.find((node) => Math.hypot(node.x - x, node.y - y) < SNAP_THRESHOLD) || null;
        };

        const pointToSegmentDistance = (px, py, startNode, endNode) => {
            const { x: x1, y: y1 } = startNode;
            const { x: x2, y: y2 } = endNode;
            const A = px - x1;
            const B = py - y1;
            const C = x2 - x1;
            const D = y2 - y1;

            const dot = A * C + B * D;
            const len_sq = C * C + D * D;
            const param = len_sq !== 0 ? dot / len_sq : -1;

            let xx, yy;

            if (param < 0) {
                xx = x1;
                yy = y1;
            } else if (param > 1) {
                xx = x2;
                yy = y2;
            } else {
                xx = x1 + param * C;
                yy = y1 + param * D;
            }

            const dx = px - xx;
            const dy = py - yy;
            return Math.sqrt(dx * dx + dy * dy);
        };

        const handleKeyDown = (event) => {
            if (event.key === "Escape") {
                if (currentNode) {
                    if (nodes.length === 1) {
                        // Case 1: No elements on the canvas, clear the single node
                        setNodes([]);
                    }
                    // Case 2: There are elements on the canvas, abort the drawing
                    setCurrentNode(null);
                    redrawCanvas();
                }
                setSelectedNodes([]);
                setSelectedElements([]);
                redrawCanvas();
            } else if (event.key === "Tab") {
                event.preventDefault();
                setIsLengthActive((prev) => !prev);
            } else if (event.key === "Backspace") {
                if (isLengthActive) {
                    setLengthInput((prev) => prev.slice(0, -1));
                } else {
                    setAngleInput((prev) => prev.slice(0, -1));
                }
                redrawCanvas();
            } else if (/^\d$/.test(event.key)) {
                if (isLengthActive) {
                    setLengthInput((prev) => prev + event.key);
                } else {
                    setAngleInput((prev) => prev + event.key);
                }
                redrawCanvas();
            } else if (event.key === "Enter") {
                if (lengthInput !== "" && angleInput !== "") {
                    const radians = (angleInput * Math.PI) / 180;
                    const endX = currentNode.x + lengthInput * Math.cos(radians);
                    const endY = currentNode.y - lengthInput * Math.sin(radians);

                    const endNode = { x: endX, y: endY, dof: { Tx: 1, Ty: 1, Rx: 1, Ry: 1 }, force: { magnitude: 0, angle: 0 } };

                    setNodes((prevNodes) => [...prevNodes, endNode]);
                    setElements((prevElements) => [
                        ...prevElements,
                        { start: currentNode, end: endNode },
                    ]);
                    setInputMode(false);
                    setCurrentNode(null);
                    setLengthInput("");
                    setAngleInput("");
                    redrawCanvas();
                    //reset the length to be active
                    setIsLengthActive(true);
                }
            }
        };

        setCanvasSize();
        window.addEventListener("resize", setCanvasSize);
        canvas.addEventListener("click", handleCanvasClick);
        canvas.addEventListener("mousedown", handleMouseDown);
        canvas.addEventListener("mousemove", handleMouseMove);
        canvas.addEventListener("mouseup", handleMouseUp);
        canvas.addEventListener("wheel", handleWheel);
        window.addEventListener("keydown", handleKeyDown);

        return () => {
            window.removeEventListener("resize", setCanvasSize);
            canvas.removeEventListener("click", handleCanvasClick);
            canvas.removeEventListener("mousedown", handleMouseDown);
            canvas.removeEventListener("mousemove", handleMouseMove);
            canvas.removeEventListener("mouseup", handleMouseUp);
            canvas.removeEventListener("wheel", handleWheel);
            window.removeEventListener("keydown", handleKeyDown);
        };
    }, [currentNode, nodes, elements, highlightedNode, highlightedElement, inputMode, lengthInput, angleInput, isLengthActive, mousePos, selectionRect, selectedNodes, selectedElements, panOffset, scale]);

    return (
        <canvas ref={trussAnalysisCanvasRef} className="trussAnalysisCanvas"></canvas>
    );
}

export default TrussAnalysisCanvas;
