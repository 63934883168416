import React, { useContext } from "react";
import { TrussContext } from "./trussContext";

const NodeTable = () => {
    const { nodes } = useContext(TrussContext);

    return (
        <table className="informationTable">
            <thead>
                <tr>
                    <th>ID</th>
                    <th>X</th>
                    <th>Y</th>
                    <th>Tx</th>
                    <th>Ty</th>
                    <th>Rx</th>
                    <th>Ry</th>
                </tr>
            </thead>
            <tbody>
                {nodes.map((node, index) => (
                    <tr key={index}>
                        <td>{`N${index + 1}`}</td>
                        <td>{node.x.toFixed(2)}</td>
                        <td>{node.y.toFixed(2)}</td>
                        <td>{node.dof.Tx}</td>
                        <td>{node.dof.Ty}</td>
                        <td>{node.dof.Rx}</td>
                        <td>{node.dof.Ry}</td>
                    </tr>
                ))}
            </tbody>
        </table>
    );
};

export default NodeTable;
