import "./comingSoon.css";
import TitleBar from "../../components/titleBar/v2/titleBar";

function ComingSoon() {

    return (
        <>
            <TitleBar/>
            <div className="_comingSoonContentDiv">
                <label className="_comingSoonMainLabel">Coming Soon!</label>
            </div>
        </>
    );
}

export default ComingSoon;
