//imports
import { Page, Text, View, Document, StyleSheet, pdf, Image } from "@react-pdf/renderer";
import companyLogo from "../../companyLogo.png";

//variables
const currentDate = new Date();

// Define your document styles
const styles = StyleSheet.create({
    page: {
        flexDirection: "column",
        backgroundColor: "#ffffff",
    },
    header: {
        flexDirection: "row",
        justifyContent: "space-between",
        borderBottom: "1 solid #000000",
        marginLeft: 20,
        marginRight: 20,
        paddingBottom: 10,
        paddingTop: 20,
        fontSize: 12,
    },
    footer: {
        textAlign: "center",
        paddingTop: 10,
        marginLeft: 20,
        marginRight: 20,
        marginBottom: 20,
        borderTop: "1 solid #000000",
        position: "absolute",
        bottom: 10,
        left: 0,
        right: 0,
        fontSize: 10,
    },
    heading: {
        margin: 10,
        marginLeft: 20,
        fontSize: 12,
        fontWeight: "bold",
    },
    displayItem: {
        flexDireciton: "row",
        justifyContent: "space-between",
        marginLeft: 20,
    },
    diplayLabel: {
        fontSize: 10,
    },
    column: {
        flexDirection: "row",
        justifyContent: "space-between",
    },
    section: {
        margin: 10,
        flexGrow: 1,
    },
    item: {
        fontSize: 10,
        marginLeft: 10,
    },
    entry: {
        flexDirection: "row",
        margin: 5,
        marginLeft: 20,
        marginRight: 20,
    },
    entryLabel: {
        width: 180,
        fontSize: 10,
    },
    entryValue: {
        fontSize: 10,
    },
    entryUnit: {
        fontSize: 10,
        marginLeft: 2,
    },
    companyLogo: {
        height: 20,
        width: 20,
    },
    table: {
        alignContent: "center",
        flexDirection: "column",
        marginTop: 10,
    },
    tableRow: {
        flexDirection: "row",
        margin: "auto",
    },
    tableCol1: {
        width: 40,
        alignItems: "center",
        justifyContent: "center",
        height: "auto",
        padding: 5,
        borderWidth: 1,
        borderStyle: "solid",
    },
    tableCol2: {
        width: 200,
        height: "auto",
        padding: 5,
        alignItems: "center",
        justifyContent: "center",
        borderWidth: 1,
        borderStyle: "solid",
    },
    tableCol3: {
        width: 100,
        height: "auto",
        padding: 5,
        alignItems: "center",
        justifyContent: "center",
        borderWidth: 1,
        borderStyle: "solid",
    },
    tableCol4: {
        width: 100,
        height: "auto",
        padding: 5,
        alignItems: "center",
        justifyContent: "center",
        borderWidth: 1,
        borderStyle: "solid",
    },
    tableCol5: {
        width: 100,
        height: "auto",
        padding: 5,
        alignItems: "center",
        justifyContent: "center",
        borderWidth: 1,
        borderStyle: "solid",
    },
    tableCell: {
        fontSize: 10,
    },
    diagramImage: {
        width: 300,
        height: 180,
        justifyContent: "center",
        alignItems: "center",
        margin: 0,
        padding: 0,
    },
    diagramImageView: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        margin: 0,
        padding: 0,
    },
});

// Header component
const Header = ({ header }) => (
    <View style={styles.header}>
        <View style={styles.column}>
            <Image src={companyLogo} style={styles.companyLogo}></Image>
            <View style={{ justifyContent: "center" }}>
                <Text style={{ fontSize: 12, marginLeft: 5 }}>Structura Pro</Text>
            </View>
        </View>
        <View style={{ justifyContent: "center" }}>
            <Text style={{ fontSize: 12 }}>{header}</Text>
        </View>
    </View>
);

// Footer component
const Footer = ({ pageNumber, totalPages }) => (
    <Text style={styles.footer}>
        Page {pageNumber} of {totalPages}
    </Text>
);

const DesignDetailsPDF = ({ designModule, designedBy, checkedBy }) => (
    <View>
        <View style={styles.entry}>
            <Text style={styles.entryLabel}>Design Module</Text>
            <Text style={styles.entryValue}>{designModule}</Text>
        </View>
        <View style={styles.entry}>
            <Text style={styles.entryLabel}>Date</Text>
            <Text style={styles.entryValue}>{String(currentDate)}</Text>
        </View>
        <View style={styles.entry}>
            <Text style={styles.entryLabel}>Design Standard</Text>
            <Text style={styles.entryValue}>{String("Indian Standard Code (IS800:2007)")}</Text>
        </View>
        <View style={styles.entry}>
            <Text style={styles.entryLabel}>Designed By</Text>
            <Text style={styles.entryValue}>{designedBy}</Text>
        </View>
        <View style={styles.entry}>
            <Text style={styles.entryLabel}>Checked By</Text>
            <Text style={styles.entryValue}>{checkedBy}</Text>
        </View>
    </View>
);

const InputDataPDF = ({ uniqueHeadings, inputDataForPDF }) => (
    <View style={styles.column}>
        <View>
            {uniqueHeadings.map((heading, index) => {
                if (index % 2 == 0) {
                    return (
                        <View>
                            <Text style={styles.heading}>{`5.${index + 1} ${heading}`}</Text>
                            {Object.keys(inputDataForPDF).map((key) => {
                                if (inputDataForPDF[key][0] == heading) {
                                    return (
                                        <View style={styles.entry}>
                                            <Text style={styles.entryLabel}>{`${inputDataForPDF[key][1]}:`}</Text>
                                            <Text style={styles.entryValue}>{inputDataForPDF[key][2]}</Text>
                                            <Text style={styles.entryUnit}>{inputDataForPDF[key][3]}</Text>
                                        </View>
                                    );
                                }
                            })}
                        </View>
                    );
                }
            })}
        </View>
        <View>
            {uniqueHeadings.map((heading, index) => {
                if (index % 2 != 0) {
                    return (
                        <View>
                            <Text style={styles.heading}>{`5.${index + 1} ${heading}`}</Text>
                            {Object.keys(inputDataForPDF).map((key) => {
                                if (inputDataForPDF[key][0] == heading) {
                                    return (
                                        <View style={styles.entry}>
                                            <Text style={styles.entryLabel}>{`${inputDataForPDF[key][1]}:`}</Text>
                                            <Text style={styles.entryValue}>{inputDataForPDF[key][2]}</Text>
                                            <Text style={styles.entryUnit}>{inputDataForPDF[key][3]}</Text>
                                        </View>
                                    );
                                }
                            })}
                        </View>
                    );
                }
            })}
        </View>
    </View>
);

const CalculationDataPDF = ({ uniqueHeadings, calculationDataForPDF }) => (
    <View style={styles.column}>
        <View>
            {uniqueHeadings.map((heading, index) => {
                if (index % 2 == 0) {
                    return (
                        <View>
                            <Text style={styles.heading}>{`7.${index + 1} ${heading}`}</Text>
                            {Object.keys(calculationDataForPDF).map((key) => {
                                if (calculationDataForPDF[key][0] == heading) {
                                    return (
                                        <View style={styles.entry}>
                                            <Text style={styles.entryLabel}>{`${calculationDataForPDF[key][1]}:`}</Text>
                                            <Text style={styles.entryValue}>{calculationDataForPDF[key][2]}</Text>
                                            <Text style={styles.entryUnit}>{calculationDataForPDF[key][3]}</Text>
                                        </View>
                                    );
                                }
                                return null;
                            })}
                        </View>
                    );
                }
            })}
        </View>
        <View>
            {uniqueHeadings.map((heading, index) => {
                if (index % 2 != 0) {
                    return (
                        <View>
                            <Text style={styles.heading}>{`7.${index + 1} ${heading}`}</Text>
                            {Object.keys(calculationDataForPDF).map((key) => {
                                if (calculationDataForPDF[key][0] == heading) {
                                    return (
                                        <View style={styles.entry}>
                                            <Text style={styles.entryLabel}>{`${calculationDataForPDF[key][1]}:`}</Text>
                                            <Text style={styles.entryValue}>{calculationDataForPDF[key][2]}</Text>
                                            <Text style={styles.entryUnit}>{calculationDataForPDF[key][3]}</Text>
                                        </View>
                                    );
                                }
                                return null;
                            })}
                        </View>
                    );
                }
            })}
        </View>
    </View>
);

const DesignSummaryPDF = ({ designSummaryForPDF }) => (
    <View style={styles.table}>
        {/* Table Header */}
        <View style={styles.tableRow}>
            <View style={styles.tableCol2}>
                <Text style={styles.tableCell}>Description</Text>
            </View>
            <View style={styles.tableCol3}>
                <Text style={styles.tableCell}>Applied Force</Text>
            </View>
            <View style={styles.tableCol4}>
                <Text style={styles.tableCell}>Section Strength</Text>
            </View>
            <View style={styles.tableCol5}>
                <Text style={styles.tableCell}>Capacity Ratio</Text>
            </View>
        </View>
        {/* Table Rows */}
        {Object.keys(designSummaryForPDF).map((key) => (
            <View style={styles.tableRow}>
                <View style={styles.tableCol2}>
                    <Text style={styles.tableCell}>{designSummaryForPDF[key][0]}</Text>
                </View>
                <View style={styles.tableCol3}>
                    <Text style={styles.tableCell}>{designSummaryForPDF[key][1]}</Text>
                </View>
                <View style={styles.tableCol4}>
                    <Text style={styles.tableCell}>{designSummaryForPDF[key][2]}</Text>
                </View>
                <View style={styles.tableCol5}>
                    <Text style={styles.tableCell}>{designSummaryForPDF[key][3]}</Text>
                </View>
            </View>
        ))}
    </View>
);

const DesignResults = ({ designSummaryForPDF, failures }) => (
    <View style={{ marginLeft: 20 }}>
        {!failures && (
            <View>
                <Text style={{ marginBottom: 10, fontSize: 10 }}>Section passes for all design checks as per IS800:2007.</Text>
            </View>
        )}
        {failures && (
            <View>
                <Text style={{ marginBottom: 10, fontSize: 10 }}>Given section fails for the below mentioned design checks with the given DCR:</Text>
                {Object.keys(designSummaryForPDF).map((key) => {
                    if (designSummaryForPDF[key][3] > 1) {
                        return <Text style={{ marginBottom: 10, fontSize: 10 }}>{`${designSummaryForPDF[key][0]}: ${designSummaryForPDF[key][3]}`}</Text>;
                    }
                })}
            </View>
        )}
    </View>
);

const Diagrams = ({ diagrams }) => (
    <View style={{ marginTop: -30 }}>
        <View style={styles.column}>
            <View style={{ marginLeft: 20 }}>
                {Object.keys(diagrams).map((key, index) => {
                    if (index % 2 == 0) {
                        return (
                            <View style={styles.diagramImageView}>
                                <View style={styles.diagramImage}>
                                    <Image src={diagrams[key][1]} quality={1} renderingMode="smooth" />
                                </View>
                                <Text style={{ fontSize: 10 }}>{diagrams[key][0]}</Text>
                            </View>
                        );
                    }
                    return null;
                })}
            </View>
            <View>
                {Object.keys(diagrams).map((key, index) => {
                    if (index % 2 != 0) {
                        return (
                            <View style={styles.diagramImageView}>
                                <View style={styles.diagramImage}>
                                    <Image src={diagrams[key][1]} quality={1} renderingMode="smooth" />
                                </View>
                                <Text style={{ fontSize: 10 }}>{diagrams[key][0]}</Text>
                            </View>
                        );
                    }
                    return null;
                })}
            </View>
        </View>
    </View>
);

function ConcreteBeamDesignPDF({ header, inputDataForPDF, calculationDataForPDF, designSummaryForPDF, diagrams, reinforcementDiagram, designedBy, checkedBy }) {
    const uniqueHeadingsForInput = returnUniqueHeadings(inputDataForPDF);
    const uniqueHeadingsForOutput = returnUniqueHeadings(calculationDataForPDF);
    let failures = false;

    Object.keys(designSummaryForPDF).forEach((key) => {
        if (designSummaryForPDF[key][3] > 1) {
            failures = true;
        }
    });

    return (
        <Document>
            <Page size="A4" style={styles.page}>
                <Header header={header} />
                <Text style={styles.heading}>1. Design Details</Text>
                <DesignDetailsPDF designModule={header} designedBy={designedBy} checkedBy={checkedBy} />
                <Text style={styles.heading}>2. Design Summary</Text>
                <DesignSummaryPDF designSummaryForPDF={designSummaryForPDF} />
                <View style={{ alignItems: "center" }}>
                    <Text style={{ fontSize: 10, marginTop: 10 }}>Table 1: Design Capacity and Capacity Ratios</Text>
                </View>
                <Text style={styles.heading}>3. Design Results</Text>
                <DesignResults designSummaryForPDF={designSummaryForPDF} failures={failures} />
                <Text style={styles.heading}>4. Provided Reinforcement</Text>
                <View style={styles.diagramImageView}>
                    <View style={styles.diagramImage}>
                        <Image src={reinforcementDiagram} quality={1} renderingMode="smooth" />
                    </View>
                    <Text style={{ fontSize: 10 }}>Figure 1: Provided Reinforcement Diagram</Text>
                </View>
                <Footer pageNumber={1} totalPages={3} />
            </Page>
            <Page size="A4" styles={styles.page}>
                <Header header={header} />
                <Text style={styles.heading}>5. Input Data</Text>
                <InputDataPDF uniqueHeadings={uniqueHeadingsForInput} inputDataForPDF={inputDataForPDF} />
                <Text style={styles.heading}>6. Figures</Text>
                <Diagrams diagrams={diagrams} />
                <Footer pageNumber={2} totalPages={3} />
            </Page>
            <Page size="A4" style={styles.page}>
                <Header header={header} />
                <Text style={styles.heading}>7. Design Calculations</Text>
                <CalculationDataPDF uniqueHeadings={uniqueHeadingsForOutput} calculationDataForPDF={calculationDataForPDF} />
                <Footer pageNumber={3} totalPages={3} />
            </Page>
        </Document>
    );
}

function returnUniqueHeadings(inputDataForPDF) {
    const allHeadings = [];

    Object.keys(inputDataForPDF).forEach((key) => {
        allHeadings.push(inputDataForPDF[key][0]);
    });

    const uniqueHeadings = [...new Set(allHeadings)];

    return uniqueHeadings;
}

export default ConcreteBeamDesignPDF;
