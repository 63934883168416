import ExpandableSection from "../expandableSection/expandableSection";

function ExpandableSectionLineItem({ title, value }) {
    return (
        <div
            style={{
                display: "flex",
                marginTop: "5px",
                marginBottom: "5px",
                marginLeft: "30px",
            }}
        >
            <div>{title}</div>
            <div style={{ flex: 1 }}></div>
            <div>{value}</div>
        </div>
    );
}

function CreateExpandableSection({ heading, calculationData }) {
    let calculationDataItems = [];
    Object.keys(calculationData).forEach((key) => {
        if (calculationData[key][0] === heading) {
            calculationDataItems.push(<ExpandableSectionLineItem title={calculationData[key][1]} value={`${calculationData[key][2]} ${calculationData[key][3]}`} />);
        }
    });
    return <ExpandableSection title={heading} children={calculationDataItems} />;
}

function CalculationSheet({ calculationData }) {
    //Format for Calculation Data
    // const calculationData = {
    //     [key]: ["Heading", "Label Title", "Value", "Unit"]
    // }

    //Get out all the headings from the calculation data. The "...new Set" method is going to remove any duplicates and keep only the unique ones
    let allHeadings = [];
    Object.keys(calculationData).forEach((key) => {
        allHeadings.push(calculationData[key][0]);
    });

    let uniqueHeadings = [...new Set(allHeadings)];

    //put the rendered data entries into the temp object for rendering
    const renderedCalculationSheet = [];
    uniqueHeadings.forEach((uniqueHeading) => {
        renderedCalculationSheet.push(<CreateExpandableSection heading={uniqueHeading} calculationData={calculationData} />);
    });

    return <>{renderedCalculationSheet}</>;
}

export default CalculationSheet;
