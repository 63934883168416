//here originX and originY refers to the center of the rectangle
//width refers to the length of the rectangle in the x-axis
//height refers to the length of the rectangle in the y-axis
//stroke refers to the color of the border of the rectangle
//fil refers to the color that we want to fill in inside that rectangle

function Rectangle({ originX, originY, width, height, stroke, fill }) {
    const x1 = originX - width / 2;
    const y1 = originY - height / 2;
    const x2 = originX + width / 2;
    const y2 = originY - height / 2;
    const x3 = originX + width / 2;
    const y3 = originY + height / 2;
    const x4 = originX - width / 2;
    const y4 = originY + height / 2;

    return <polygon points={`${x1},${y1} ${x2},${y2} ${x3},${y3} ${x4},${y4}`} stroke={stroke} fill={fill} />;
}

export default Rectangle;
