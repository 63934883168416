import Arrow from "./arrow";

function Loading({ loadType, load, originX, originY, length, height, stroke }) {
    if (loadType === "Uniform Line Load") {
        return (
            <>
                {/*horizontal line showing span of load*/}
                <line x1={`${originX - length / 2}`} y1={`${originY - height / 2 - 30}`} x2={`${originX + length / 2}`} y2={`${originY - height / 2 - 30}`} stroke={stroke} />
                {/*vertical arrows*/}
                <Arrow originX={`${originX - length / 2}`} originY={`${originY - height / 2}`} tailLength={20} arrowHeadHeight={10} stroke={stroke} fill={stroke} direction="down" />
                <Arrow originX={`${originX - length / 4}`} originY={`${originY - height / 2}`} tailLength={20} arrowHeadHeight={10} stroke={stroke} fill={stroke} direction="down" />
                <Arrow originX={`${originX}`} originY={`${originY - height / 2}`} tailLength={20} arrowHeadHeight={10} stroke={stroke} fill={stroke} direction="down" />
                <Arrow originX={`${originX + length / 4}`} originY={`${originY - height / 2}`} tailLength={20} arrowHeadHeight={10} stroke={stroke} fill={stroke} direction="down" />
                <Arrow originX={`${originX + length / 2}`} originY={`${originY - height / 2}`} tailLength={20} arrowHeadHeight={10} stroke={stroke} fill={stroke} direction="down" />
                {/*load text*/}
                <text x={originX} y={originY - height / 2 - 30 - 10} fill={stroke} dominantBaseline="middle" textAnchor="middle" fontFamily="system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif">{`${load} kN/m`}</text>
            </>
        );
    } else {
        //point load
        return (
            <>
                {/*arrowhead showing the point load*/}
                <Arrow originX={originX} originY={originY - height / 2} tailLength={30} arrowHeadHeight={10} stroke={stroke} fill={stroke} direction="down" />
                <text x={originX} y={originY - height / 2 - 40 - 10} fill={stroke} dominantBaseline="middle" textAnchor="middle" fontFamily="system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif">{`${load} N`}</text>
            </>
        );
    }
}

export default Loading;
