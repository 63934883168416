import "./buttons.css";
import { Link } from "react-router-dom";

//type: this is the type of button you want. linkButton (this is a button that can redirect to another page), textButton (this is a button with a function), linkButton_bounded (this is a link button that has a border around it), textButton_bounded (this is a text button that has a border around it)
//text: the text that you want to display inside the button
//fontColor: the color of the font that you want for the button
//backgroundColor: the backgroundColor that you want for your button
//linkTo: if you want a link button, set the route for this here otherwise just leave as ""
//onClickFunction: if you are using a text button, show the function that you want to execute when you click the button. if you wish to leave this empty, just put in () => {}

function Buttons({ type, text, fontColor, backgroundColor, linkTo, onClickFunction }) {
    if (type === "linkButton") {
        return (
            <Link to={linkTo} className="_buttonLink">
                <button
                    className="_reactButtons"
                    style={{
                        color: fontColor,
                        margin: "16px",
                        fontSize: "16px",
                        background: "transparent",
                        border: "none",
                        cursor: "pointer",
                    }}
                >
                    {text}
                </button>
            </Link>
        );
    } else if (type === "textButton") {
        return (
            <button
                className="_reactButtons"
                style={{
                    color: fontColor,
                    padding: "16px",
                    fontSize: "16px",
                    background: "transparent",
                    border: "none",
                    cursor: "pointer",
                }}
                onClick={onClickFunction}
            >
                {text}
            </button>
        );
    } else {
        //add more in case we need them
    }
}

export default Buttons;
