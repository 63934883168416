import InformationPage from "../../components/informationPage/informationPage";

function TermsOfUse() {
    const heading = "Terms of Use";
    const content = {
        heading1: ["1. Use of the Service", "You may use the Service for your personal, non-commercial use only. You agree not to reproduce, duplicate, copy, sell, resell, or exploit any portion of the Service without the express written permission of Structura Pro."],
        heading2: ["2. User Content", "You are solely responsible for any content that you upload, post, or otherwise transmit through the Service (User Content). You agree not to upload, post, or transmit any User Content that is unlawful, defamatory, obscene, offensive, or otherwise objectionable. By uploading, posting, or transmitting User Content, you grant Structura Pro a worldwide, non-exclusive, royalty-free, sublicensable license to use, reproduce, modify, adapt, publish, translate, distribute, and display such User Content in connection with the Service."],
        heading3: ["3. Intellectual Property", "All content and materials available on the Service, including but not limited to text, graphics, logos, button icons, images, audio clips, and software, are the property of Structura Pro or its licensors and are protected by copyright, trademark, and other intellectual property laws. You may not modify, copy, distribute, transmit, display, perform, reproduce, publish, license, create derivative works from, transfer, or sell any information, software, products, or services obtained from the Service."],
        heading4: ["4. Disclaimer of Warranties", "The Service is provided on an as-is and as-available basis without any warranties of any kind, whether express or implied. Structura Pro does not warrant that the Service will be uninterrupted, error-free, or free of viruses or other harmful components."],
        heading5: ["5. Limitation of Liability", "In no event shall Structura Pro be liable for any direct, indirect, incidental, special, consequential, or exemplary damages, including but not limited to, damages for loss of profits, goodwill, use, data, or other intangible losses arising out of or in connection with your use of the Service."],
        heading6: ["6. Governing Law", "These terms and conditions are governed by and construed in accordance with the laws of India, and you irrevocably submit to the exclusive jurisdiction of the courts in that State or location."],
        heading7: ["7. Changes to the Terms of Use", "Structura Pro may revise these Terms of Use for its website at any time without notice. By using this website, you are agreeing to be bound by the then current version of these Terms of Use."],
        heading8: ["Contact Us", "If you have any questions or concerns about these Terms of Use, please contact us at utkarshg17@gmail.com."],
    };

    return (
        <>
            <InformationPage informationPageHeading={heading} content={content} />
        </>
    );
}

export default TermsOfUse;
