import "./appliedForcesAndDesignCapacities.css";

//this component is going to show the design capacity ratios. the format of data for this is as follows:
// const appliedForcesAndDesignCapacitites = {
//     name: ["name string", "applied force value", "capacity value"]
// }

//style: this is where you can choose whether you want bold or not. you just have to type true or false here
function ThreeLineItems({ item1, item2, item3, bold }) {
    return (
        <div className="_threeLineItemDiv" style={{ fontWeight: `${bold == true ? "500" : "400"}` }}>
            <div className="_threeLineItemItem1">
                <label>{item1}</label>
            </div>
            <div className="_threeLineItemItem2">
                <label>{item2}</label>
            </div>
            <div className="_threeLineItemItem3">
                <label>{item3}</label>
            </div>
        </div>
    );
}

function AppliedForcesAndDesignCapacities({ heading, data }) {
    const renderedLineItems = [];
    renderedLineItems.push(<ThreeLineItems item1={heading[0]} item2={heading[1]} item3={heading[2]} bold={true} />);
    Object.keys(data).forEach((key) => {
        renderedLineItems.push(<ThreeLineItems item1={data[key][0]} item2={data[key][1]} item3={data[key][2]} />);
    });

    return (
        <div className="_table1ContainerDiv">{renderedLineItems}</div>
    );
}

export default AppliedForcesAndDesignCapacities;
