import "./designPagesStyles.css";
import Buttons from "../../components/buttons/buttons";
import React, { useRef, useState, useEffect } from "react";
import axios from "axios";
import SettingsMenu from "../../components/settingsMenu/settingsMenu";
//import CalculationSheet from "../../components/calculationSheet/calculationSheet";
import InputSheet from "../../components/inputSheet/inputSheet";
import InformationOverlay from "../../components/informationOverlay/informationOverlay";
import RectangularHollowSectionDiagram from "../../components/diagrams/rectangularHollowSectionDiagram";
import RectangularHollowSectionLoadingDiagram from "../../components/diagrams/rectangularHollowSectionLoadingDiagram";
import CalculationSheet from "../../components/calculationSheet/calculationSheet";
import AppliedForcesAndDesignCapacities from "../../components/tables/appliedForcesAndDesignCapacities/appliedForcesAndDesignCapacitites";
import DesignCapacityRatios from "../../components/tables/designCapacityRatios/designCapacityRatios";
import LoadingOverlay from "../../components/loadingOverlay/loadingOverlay";

function RectangularHollowSteelSectionOptimizer() {
    //****************************************INPUT REFS AND INPUT VARIABLES****************************************
    //Ref Variables
    const inputDataRefs = {
        minHeightRef: useRef(null),
        maxHeightRef: useRef(null),
        minWidthRef: useRef(null),
        maxWidthRef: useRef(null),
        minThicknessRef: useRef(null),
        maxThicknessRef: useRef(null),
        lengthRef: useRef(null),
        densityOfSteelRef: useRef(null),
        yieldStrengthOfSteelRef: useRef(null),
        modulusOfElasticityOfSteelRef: useRef(null),
        axialForceRef: useRef(null),
        shearForceAlongXRef: useRef(null),
        shearForceAlongYRef: useRef(null),
        momentAboutXRef: useRef(null),
        momentAboutYRef: useRef(null),
        sectionTypeRef: useRef(null),
        effectiveLengthFactorRef: useRef(null),
    };

    //Input Variables
    const inputData = {
        //geometric properties
        minHeight: ["Geometric Properties", "Min. Height", ["number", inputDataRefs.minHeightRef, 400], "mm"],
        maxHeight: ["Geometric Properties", "Max. Height", ["number", inputDataRefs.maxHeightRef, 600], "mm"],
        minWidth: ["Geometric Properties", "Min. Width", ["number", inputDataRefs.minWidthRef, 400], "mm"],
        maxWidth: ["Geometric Properties", "Max. Width", ["number", inputDataRefs.maxWidthRef, 600], "mm"],
        minThickness: ["Geometric Properties", "Min. Thickness", ["number", inputDataRefs.minThicknessRef, 8], "mm"],
        maxThickness: ["Geometric Properties", "Max. Thickness", ["number", inputDataRefs.maxThicknessRef, 12], "mm"],
        length: ["Geometric Properties", "Length", ["number", inputDataRefs.lengthRef, 2000], "mm"],
        //material properties
        densityOfSteel: ["Material Properties", "Density of Steel", ["number", inputDataRefs.densityOfSteelRef, 7850], "kg/m³"],
        yieldStrengthOfSteel: ["Material Properties", "Yield Strength of Steel", ["number", inputDataRefs.yieldStrengthOfSteelRef, 455], "MPa"],
        modulusOfElasticityOfSteel: ["Material Properties", "Modulus of Elasticity", ["number", inputDataRefs.modulusOfElasticityOfSteelRef, 20000], "MPa"],
        //applied forces
        axialForce: ["Applied Forces", "Axial Force", ["number", inputDataRefs.axialForceRef, 600], "kN"],
        shearForceAlongX: ["Applied Forces", "Shear Force Along X", ["number", inputDataRefs.shearForceAlongXRef, 5], "kN"],
        shearForceAlongY: ["Applied Forces", "Shear Force Along Y", ["number", inputDataRefs.shearForceAlongYRef, 4], "kN"],
        momentAboutX: ["Applied Forces", "Moment about X", ["number", inputDataRefs.momentAboutXRef, 50], "kN-m"],
        momentAboutY: ["Applied Forces", "Moment about Y", ["number", inputDataRefs.momentAboutYRef, 20], "kN-m"],
        //setup information
        sectionType: ["Setup Information", "Section Type", ["dropdown", inputDataRefs.sectionTypeRef, ["Hot Rolled", "Cold Formed"]], ""],
        effectiveLengthFactor: ["Setup Information", "Effective Length Factor", ["number", inputDataRefs.effectiveLengthFactorRef, 1], ""],
    };

    ///****************************************DIAGRAMS****************************************
    //keep track of which digram has been selected
    const [selectedDiagram, setSelectedDiagram] = useState("sectionDiagram");
    //ref for the div in which the diagram is being displayed
    const diagramDivRef = useRef(null);
    //dimensions of the div in which the diagram is being displayed
    const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

    //resizing the diagram when the screen size changes
    useEffect(() => {
        const observeTarget = diagramDivRef.current;
        const resizeObserver = new ResizeObserver((entries) => {
            // Assuming you want to observe the first entry
            const { width, height } = entries[0].contentRect;
            setDimensions({ width, height });
        });
        // Step 2: Initialize the ResizeObserver
        if (observeTarget) {
            resizeObserver.observe(observeTarget);
        }

        // Step 3: Clean up
        return () => {
            if (observeTarget) {
                resizeObserver.disconnect();
            }
        };
    }, []);

    //handle dropdown change for selecting diagram
    const handleDiagramDropdownChange = (event) => {
        setSelectedDiagram(event.target.value);
    };

    //****************************************CALCULATE BUTTON****************************************
    //calculations related state variables
    const [designStatusMessage, setDesignStatusMessage] = useState("Status: Click Calculate to design section.")
    const [designCalculationsVisibility, setDesignCalculationsVisibility] = useState(false); //whether calculations are shown or not
    const [designCalculations, setDesignCalculations] = useState({}); //to store the calcualation data we get from back end
    const [appliedForcesAndCapacities, setAppliedForcesAndCapacities] = useState({}); //applied forces and capacities state variables
    const [designCapacityRatios, setDesignCapacityRatios] = useState({}); //design capacity ratios object to generate design capacity ratios table
    const [designSummaryForPDF, setDesignSummaryForPDF] = useState({}); //this is the design summary we are printing in section 5 of the design PDF
    const [designDimensions, setDesignDimensions] = useState({})    //the final dimensions that we get once the entire section has been optimized
    const [fetchingData, setFetchingData] = useState(false);
    let cancelTokenSource = null;

    //handle the clickdown of calculate button
    const calculateButtonClicked = async () => {
        //get all the dimensions that have been input by the user
        const minHeight = inputDataRefs.minHeightRef.current ? parseFloat(inputDataRefs.minHeightRef.current.value) : 0;
        const maxHeight = inputDataRefs.maxHeightRef.current ? parseFloat(inputDataRefs.maxHeightRef.current.value) : 0;
        const minWidth = inputDataRefs.minWidthRef.current ? parseFloat(inputDataRefs.minWidthRef.current.value) : 0;
        const maxWidth = inputDataRefs.maxWidthRef.current ? parseFloat(inputDataRefs.maxWidthRef.current.value) : 0;
        const minThickness = inputDataRefs.minThicknessRef.current ? parseFloat(inputDataRefs.minThicknessRef.current.value) : 0;
        const maxThickness = inputDataRefs.maxThicknessRef.current ? parseFloat(inputDataRefs.maxThicknessRef.current.value) : 0;

        if (maxHeight - minHeight > 1000) {
            alert("Maximum height variance can only be 1000 mm");
        } else if (maxWidth - minWidth > 1000) {
            alert("Maximum width variance can only be 1000 mm");
        } else if (maxThickness - minThickness > 10) {
            alert("Maximum thickness variance can only be 10 mm");
        } else if (maxHeight < minHeight) {
            alert("Maximum height cannot be less than minimum height");
        } else if (maxWidth < minWidth) {
            alert("Maximum width cannot be less than minimum width");
        } else if (maxThickness < minThickness) {
            alert("Maximum thickness cannot be less than minimum thickness");
        } else {
            //set design status message
            setDesignStatusMessage("Status: Designing beam section...");
            setFetchingData(true);
            //cancel the pending requests if there are any
            if (cancelTokenSource) {
                cancelTokenSource.cancel("Operation cancelled due to new request");
            }
            //create a new cancel token source
            cancelTokenSource = axios.CancelToken.source();
            //Create request object
            const requestObject = {};
            Object.keys(inputDataRefs).forEach((key) => {
                const keyName = key.replace(/Ref$/, ""); //Removing the "Ref" suffix from each of the key name
                requestObject[`${keyName}`] = inputDataRefs[key].current.value;
            });

            //Send data to back end
            axios.post("/api/fetchRectangularHollowSteelSectionOptimizationCalculations", requestObject, {
                cancelToken: cancelTokenSource.token,
                headers: {
                    "Content-Type": "application/json",
                },
            }).then((response) => {
                setDesignDimensions(response.data["designDimensions"]); //the final dimensions that we are going to use for the design once the optimization is complete
                if (designDimensions["height"] == 0) {
                    //show alert that optimized section could not be produced
                    alert("No optimized section can be calculated as per the input dimesion constraints and forces. Please reduce applied forces or increase maximum dimension to generate the most optimized section.")
                    //reset everything to show nothing
                    setDesignCalculationsVisibility(false);
                } else {
                    //set all the data that we get from the back end
                    setDesignCalculations(response.data["designCalculations"]); //Set design calculations state variable
                    setAppliedForcesAndCapacities(response.data["appliedForcesAndCapacities"]); //set the applied forces and capacitites
                    setDesignCapacityRatios(response.data["designCapacityRatios"]); //Set design capacity ratios to generate table
                    setDesignSummaryForPDF(response.data["designSummaryForPDF"]); //set design summary for section 5 of PDF

                    //set the state variables that we have to change things on the front end
                    setDesignCalculationsVisibility(true); //Set design calculations visibility
                    setDesignStatusMessage("Status: Section design complete.");    //set design status message as design complete
                    setFetchingData(false);
                }
            }).catch((error) => {
                console.error(`The request could not be completed beacause of ${error}`);
            });
        }
    };

    //****************************************SETTINGS BUTTON****************************************
    //settings menu state variables
    const [displaySettingsMenu, setDisplaySettingsMenu] = useState(false); //toggle to show or not show the settings menu
    const [designedBy, setDesignedBy] = useState("John Doe"); //who has design the structural element (this is going to appear in the final PDF that we are going to print out)
    const [checkedBy, setCheckedBy] = useState("Alice Alex"); //who checked the design calculations (this is going to appear in the final PDF that we are going to print out)
    const [allowableDCR, setAllowableDCR] = useState(1); //what is the DCR that is allowed?

    //hangle clickdown of settings button
    const settingsButtonClicked = () => {
        setDisplaySettingsMenu(true);
    };

    //****************************************PRINT BUTTON****************************************
    //diagrams heights and widths
    const printCanvasHeight = 400;
    const printCanvasWidth = 500;
    //handle clickdown of print pdf button
    const printButtonClicked = async () => {
        if (designCalculationsVisibility == true) {
            //set design status message
            setDesignStatusMessage("Status: Generating PDF...");
            //diagrams
            const sectionDiagram = await convertSvgToPng("sectionDiagramForPDF", printCanvasHeight, printCanvasWidth);
            const loadingDiagram = await convertSvgToPng("loadingDiagramForPDF", printCanvasHeight, printCanvasWidth);
            //request object
            const requestObject = {
                appliedTensileForce: designSummaryForPDF["tensileRatio"][1],
                appliedCompressiveForce: designSummaryForPDF["compressiveRatio"][1],
                appliedShearForceAlongX: designSummaryForPDF["shearAlongXRatio"][1],
                appliedShearForceAlongY: designSummaryForPDF["shearAlongYRatio"][1],
                appliedMomentAboutX: designSummaryForPDF["momentAboutXRatio"][1],
                appliedMomentAboutY: designSummaryForPDF["momentAboutYRatio"][1],
                combinedAxialAndBendingRatio: designSummaryForPDF["combinedAxialAndBendingRatio"][3],
                tensileCapacity: designSummaryForPDF["tensileRatio"][2],
                compressiveCapacity: designSummaryForPDF["compressiveRatio"][2],
                shearForceAlongXCapacity: designSummaryForPDF["shearAlongXRatio"][2],
                shearForceAlongYCapacity: designSummaryForPDF["shearAlongYRatio"][2],
                momentAboutXCapacity: designSummaryForPDF["momentAboutXRatio"][2],
                momentAboutYCapacity: designSummaryForPDF["momentAboutYRatio"][2],
                tensileCapacityRatio: designSummaryForPDF["tensileRatio"][3],
                compressiveCapacityRatio: designSummaryForPDF["compressiveRatio"][3],
                shearForceAlongXCapacityRatio: designSummaryForPDF["shearAlongXRatio"][3],
                shearForceAlongYCapacityRatio: designSummaryForPDF["shearAlongYRatio"][3],
                momentAboutXCapacityRatio: designSummaryForPDF["momentAboutXRatio"][3],
                momentAboutYCapacityRatio: designSummaryForPDF["momentAboutYRatio"][3],
                sectionDiagram: sectionDiagram,
                loadingDiagram: loadingDiagram
            };
            //adding inputdata to the request object
            Object.keys(inputData).forEach((key) => {
                requestObject[key] = inputData[key][2][1].current ? inputData[key][2][1].current.value : "";
            })
            //adding height, width and thickness separately since they are not a part of the inputs
            requestObject["height"] = designDimensions["height"];
            requestObject["width"] = designDimensions["width"];
            requestObject["thickness"] = designDimensions["thickness"];
            //adding the design calculations to the request object
            Object.keys(designCalculations).forEach((key) => {
                requestObject[key] = designCalculations[key][2];
            })
            //then we send this to the api that is going to generate the pdf
            fetch('/api/generateRectangularHollowSteelSectionPDF', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(requestObject)
            })
                .then(response => response.blob())
                .then(blob => {
                    //set design status message
                    setDesignStatusMessage("Status: PDF Generation Complete.")
                    const url = window.URL.createObjectURL(blob);
                    window.open(url, '_blank');
                })
                .catch(error => console.error('Error:', error));
        } else {
            alert("Please design section by clicking \"Calculate\" before printing out design results.");
        }
    };

    //****************************************FEEDBACK BUTTON****************************************
    const feedbackButtonClicked = () => {
        window.open("https://forms.gle/Az8WUKkhQbNMhtnp9", "_blank");
    }


    //****************************************INFORMATION BUTTON****************************************
    //handle the clickdown of information button
    const informationButtonClicked = () => {
        window.open("../assets/rectangularHollowSteelSectionOptimizer/designManual.pdf", "_blank");
    };

    //****************************************INFORMATION OVERLAYS****************************************
    //information overlay state variables
    const [geometryInformationOverlayVisibility, setGeometryInformationOverlayVisibility] = useState(false);
    const [materialPropertiesInformationOverlayVisibility, setMaterialPropertiesInformationOverlayVisibility] = useState(false);
    const [appliedForcesInformationOverlayVisibility, setAppliedForcesInformationOverlayVisibility] = useState(false);
    const [setupInformationOverlayVisibility, setSetupInformationOverlayVisibility] = useState(false);

    //information overlay functions
    const geometryInformationButtonClicked = () => {
        setGeometryInformationOverlayVisibility(true);
    };

    const materialPropertiesInformationButtonClicked = () => {
        setMaterialPropertiesInformationOverlayVisibility(true);
    };

    const appliedForcesInformationButtonClicked = () => {
        setAppliedForcesInformationOverlayVisibility(true);
    };

    const setupInformationButtonClicked = () => {
        setSetupInformationOverlayVisibility(true);
    };

    const closeInformationOverlays = () => {
        setGeometryInformationOverlayVisibility(false);
        setMaterialPropertiesInformationOverlayVisibility(false);
        setAppliedForcesInformationOverlayVisibility(false);
        setSetupInformationOverlayVisibility(false);
    };

    const informationOverlayFunctions = [geometryInformationButtonClicked, materialPropertiesInformationButtonClicked, appliedForcesInformationButtonClicked, setupInformationButtonClicked];

    //****************************************TITLE BAR BUTTONS****************************************
    //title bar buttons
    const titleBarButtons = {
        calculateButton: ["Calculate", calculateButtonClicked],
        settingsButton: ["Settings", settingsButtonClicked],
        printButton: ["Print", printButtonClicked],
        informationButton: ["Information", informationButtonClicked],
        feedbackButton: ["Feedback", feedbackButtonClicked]
    };

    const renderedTitleBarButtons = [];
    Object.keys(titleBarButtons).forEach((key) => {
        renderedTitleBarButtons.push(<Buttons type="textButton"
            text={titleBarButtons[key][0]}
            fontColor="black"
            backgroundColor=""
            linkTo=""
            onClickFunction={titleBarButtons[key][1]} />);
    });

    return (
        <>
            <div className="_containerDiv">
                {fetchingData && (
                    <LoadingOverlay />
                )}
                {displaySettingsMenu && (
                    <SettingsMenu setDisplaySettingsMenuFunction={setDisplaySettingsMenu}
                        designedBy={designedBy}
                        setDesignedBy={setDesignedBy}
                        checkedBy={checkedBy}
                        setCheckedBy={setCheckedBy}
                        allowableDCR={allowableDCR}
                        setAllowableDCR={setAllowableDCR} />
                )}
                {geometryInformationOverlayVisibility && (
                    <InformationOverlay informationOverlayHeading="Geometric Properties"
                        informationOverlayCaption={["Geometric Properties: Section View", "Geometric Properties: Longitudinal View"]}
                        informationOverlayImageSrc={["../assets/rectangularHollowSteelSectionOptimizer/images/geometricPropertiesInformationOverlay_1.png", "../assets/rectangularHollowSteelSectionOptimizer/images/geometricPropertiesInformationOverlay_2.png"]}
                        closeInformationOverlays={closeInformationOverlays} />
                )}
                {materialPropertiesInformationOverlayVisibility && (
                    <InformationOverlay informationOverlayHeading="Material Properties"
                        informationOverlayCaption={["Rectangular Hollow Steel Section: Material Properties"]}
                        informationOverlayImageSrc={["../assets/rectangularHollowSteelSectionOptimizer/images/materialPropertiesInformationOverlay.png"]}
                        closeInformationOverlays={closeInformationOverlays} />
                )}
                {appliedForcesInformationOverlayVisibility && (
                    <InformationOverlay informationOverlayHeading="Applied Forces"
                        informationOverlayCaption={["Rectangular Hollow Steel Section: Applied Axial Forces", "Rectangular Hollow Steel Section: Applied Moment and Shear Forces"]}
                        informationOverlayImageSrc={["../assets/rectangularHollowSteelSectionOptimizer/images/forcesAppliedInformationOverlay_2.png", "../assets/rectangularHollowSteelSectionOptimizer/images/forcesAppliedInformationOverlay_1.png"]}
                        closeInformationOverlays={closeInformationOverlays} />
                )}
                {setupInformationOverlayVisibility && (
                    <InformationOverlay informationOverlayHeading="Setup Information"
                        informationOverlayCaption={["Rectangular Hollow Steel Section: Effective Length Factors from IS800:2007 (Table 11)"]}
                        informationOverlayImageSrc={["../assets/rectangularHollowSteelSectionOptimizer/images/setupInformationInformationOverlay.png"]}
                        closeInformationOverlays={closeInformationOverlays} />
                )}
                <div className="_designInputDiv">
                    <div className="_designInputsTitleBar">
                        <label className="_dashboardHeading">Design Inputs</label>
                    </div>
                    <div className="_designInputs">
                        <InputSheet inputData={inputData}
                            updateCalculationsFunction={() => { setDesignStatusMessage("Status: Input parameters have changed. Please click Calculate to redesign.") }} informationOverlayFunctions={informationOverlayFunctions} />
                    </div>
                </div>
                <div className="_designDashboardDiv">
                    <div className="_dashboardTitleBar">
                        <label className="_dashboardHeading" style={{ color: "black" }}>
                            Rectangular Hollow Steel Section Optimizer
                        </label>
                        <div className="_dashboardButtons">{renderedTitleBarButtons}</div>
                    </div>
                    <div className="_dashboardTiles">
                        <div id="_dashboardLeftDiv" className="_dashboardSection">
                            <div id="dashboardTopLeftDiv" className="_dashboardTile">
                                <div class="_dashboardTileTitleBar">
                                    <label className="_tileHeading" style={{ backgroundColor: "transparent" }}>Diagrams</label>
                                    <select class="_dashboardTileTitleBarDropdown" value={selectedDiagram} onChange={handleDiagramDropdownChange}>
                                        <option value="sectionDiagram">Section Diagram</option>
                                        <option value="loadingDiagram">Loading Diagram</option>
                                    </select>
                                </div>
                                <div className="_tileContentDiv" ref={diagramDivRef}>
                                    {!designCalculationsVisibility && (
                                        <p style={{ margin: "0px", padding: "0px", fontSize: "14px" }}>Press "Calculate" or change design inputs to show applied forces and capacities.</p>
                                    )}
                                    {designCalculationsVisibility && (
                                        <>
                                            <svg className="diagramSVG" id="sectionDiagram" style={{ display: selectedDiagram == "sectionDiagram" ? "block" : "none" }}>
                                                <RectangularHollowSectionDiagram frameHeight={dimensions.height}
                                                    frameWidth={dimensions.width}
                                                    height={designDimensions["height"]}
                                                    width={designDimensions["width"]}
                                                    thickness={designDimensions["thickness"]}
                                                    stroke="black"
                                                    fill="#F5F5F5" />
                                            </svg>
                                            <svg className="diagramSVG" id="loadingDiagram" style={{ display: selectedDiagram == "loadingDiagram" ? "block" : "none" }}>
                                                <RectangularHollowSectionLoadingDiagram frameHeight={dimensions.height}
                                                    frameWidth={dimensions.width}
                                                    shearForceAlongX={inputDataRefs.shearForceAlongXRef.current ? inputDataRefs.shearForceAlongXRef.current.value : ""}
                                                    shearForceAlongY={inputDataRefs.shearForceAlongYRef.current ? inputDataRefs.shearForceAlongYRef.current.value : ""}
                                                    momentAboutX={inputDataRefs.momentAboutXRef.current ? inputDataRefs.momentAboutXRef.current.value : ""}
                                                    momentAboutY={inputDataRefs.momentAboutYRef.current ? inputDataRefs.momentAboutYRef.current.value : ""}
                                                    height={designDimensions["height"]}
                                                    width={designDimensions["width"]}
                                                    thickness={designDimensions["thickness"]} />
                                            </svg>
                                            <svg className="diagramSVG" id="sectionDiagramForPDF" style={{ display: "none" }}>
                                                <RectangularHollowSectionDiagram frameHeight={printCanvasHeight}
                                                    frameWidth={printCanvasWidth}
                                                    height={designDimensions["height"]}
                                                    width={designDimensions["width"]}
                                                    thickness={designDimensions["thickness"]}
                                                    stroke="black"
                                                    fill="#F5F5F5" />
                                            </svg>
                                            <svg className="diagramSVG" id="loadingDiagramForPDF" style={{ display: "none" }}>
                                                <RectangularHollowSectionLoadingDiagram frameHeight={printCanvasHeight}
                                                    frameWidth={printCanvasWidth}
                                                    shearForceAlongX={inputDataRefs.shearForceAlongXRef.current ? inputDataRefs.shearForceAlongXRef.current.value : ""}
                                                    shearForceAlongY={inputDataRefs.shearForceAlongYRef.current ? inputDataRefs.shearForceAlongYRef.current.value : ""}
                                                    momentAboutX={inputDataRefs.momentAboutXRef.current ? inputDataRefs.momentAboutXRef.current.value : ""}
                                                    momentAboutY={inputDataRefs.momentAboutYRef.current ? inputDataRefs.momentAboutYRef.current.value : ""}
                                                    height={designDimensions["height"]}
                                                    width={designDimensions["width"]}
                                                    thickness={designDimensions["thickness"]} />
                                            </svg>
                                        </>
                                    )}
                                </div>
                            </div>
                            <div id="dashboardBottomLeftDiv" className="_dashboardTile">
                                <label className="_tileHeading">Applied Forces and Capacities</label>
                                <div class="_tileContentDiv" style={{ fontSize: "14px" }}>
                                    {!designCalculationsVisibility && (
                                        <p style={{ margin: "0px", padding: "0px" }}>Press "Calculate" or change design inputs to show applied forces and capacities.</p>
                                    )}
                                    {designCalculationsVisibility && (
                                        <AppliedForcesAndDesignCapacities heading={["Description", "Applied Force", "Capacity"]}
                                            data={appliedForcesAndCapacities} />
                                    )}
                                </div>
                            </div>
                        </div>
                        <div id="_dashBoardRightDiv" className="_dashboardSection">
                            <div id="dashboardTopRightDiv" className="_dashboardTile">
                                <label className="_tileHeading">Design Calculations</label>
                                <div class="_tileContentDiv" style={{ fontSize: "14px" }}>
                                    {!designCalculationsVisibility && (
                                        <p style={{ margin: "0px", padding: "0px" }}>Press "Calculate" or change design inputs to show design calculations.</p>
                                    )}
                                    {designCalculationsVisibility && (
                                        <CalculationSheet calculationData={designCalculations} />)
                                    }
                                </div>
                            </div>
                            <div id="dashboardBottomRightDiv" className="_dashboardTile">
                                <div className="_dashboardTileTitleBar">
                                    <label className="_tileHeading" style={{ backgroundColor: "transparent" }}>Design Capacity Ratios</label>
                                </div>
                                <div class="_tileContentDiv" style={{ fontSize: "14px" }}>
                                    {!designCalculationsVisibility && (
                                        <p style={{ margin: "0px", padding: "0px" }}>Press "Calculate" or change design inputs to show design capacity ratios.</p>
                                    )}
                                    {designCalculationsVisibility && (
                                        <DesignCapacityRatios heading={["Description", "Capacity Ratio"]}
                                            data={designCapacityRatios}
                                            allowableDCR={allowableDCR} />
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="_dashboardStatusBar">
                        <label>{designStatusMessage}</label>
                        <label>Structura Pro</label>
                    </div>
                </div>
            </div>
        </>
    );
}

// Function to convert SVG to PNG
async function convertSvgToPng(svgID, height, width) {
    const svgElement = document.getElementById(svgID);
    const svgData = new XMLSerializer().serializeToString(svgElement);

    return new Promise((resolve, reject) => {
        const canvas = document.createElement("canvas");
        const context = canvas.getContext("2d");
        const image = new Image(); // Use the standard Image constructor

        image.onload = function () {
            canvas.width = width;
            canvas.height = height;
            context.drawImage(image, 0, 0);

            // Convert canvas to data URL
            const dataURL = canvas.toDataURL("image/png");
            resolve(dataURL);
        };

        image.onerror = function (error) {
            reject(error);
        };

        image.src = "data:image/svg+xml;base64," + btoa(svgData);
    });
}

export default RectangularHollowSteelSectionOptimizer;