import InformationPage from "../../components/informationPage/informationPage";

function PrivacyStatement() {
    const heading = "Privacy Statement";
    const content = {
        heading1: ["1. Information Collection and Use", "We may collect personal information that you provide directly to us when you use our Service. This may include contact information such as your name, email address, and phone number, user credentials such as username and password, communication preferences. We may also collect certain information automatically when you access or use the Service, including, usage data such as pages visited, features used, and interactions with the Service, device information such as IP address, browser type, and operating system. We use the collected information for various purposes, including, providing and maintaining the Service, personalizing your experience and delivering customized content, analyzing usage trends and improving the Service, communicating with you about updates, offers, and promotions."],
        heading2: ["2. Data Sharing and Disclosure", "We may share your personal information with third-party service providers who assist us in operating our business and providing the Service. These third parties are obligated to protect your information and are prohibited from using it for any other purpose."],
        heading3: ["3. Data Security", "We take reasonable measures to protect your personal information from unauthorized access, disclosure, alteration, or destruction. However, no method of transmission over the internet or electronic storage is 100% secure, and we cannot guarantee absolute security."],
        heading4: ["4. Changes to this Privacy Policy", "We may update this Privacy Policy from time to time by posting the revised version on this page. We encourage you to review this page periodically for any changes. Your continued use of the Service after the posting of the updated Privacy Policy will constitute your acceptance of the changes."],
        heading5: ["Contact Us", "If you have any questions or concerns about this Privacy Policy, please contact us at utkarshg17@gmail.com."],
    };

    return (
        <>
            <InformationPage informationPageHeading={heading} content={content} />
        </>
    );
}

export default PrivacyStatement;
