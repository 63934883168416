import Rectangle from "./elements/rectangle";
import DimensionLine from "./elements/dimensionLine";
import Legend from "./elements/legend";
import DottedLine from "./elements/dottedLine";

//frameHeight and frameWidth: these are the heights and widths of the frame that we are going to place the diagram in
//height: actual height of the section
//width: actual width of the section
//thickness: actual thickness of the section
//stroke: the stroke color for the boundary of the diagram
//fill: the fill color that you want to place inside the between material for the section

function RectangularHollowSectionDiagram({ frameHeight, frameWidth, height, width, thickness, stroke, fill }) {
    const frameCenterX = frameWidth / 2;
    const frameCenterY = frameHeight / 2 - 10;
    const aspectRatio = width / height;
    const displayHeight = (frameHeight) * 0.4;
    const displayWidth = displayHeight * aspectRatio;
    const displayThickness = thickness;
    const dottedLineExtension = 20;
    // const legend = {
    //     height: ["black", `Height: ${height} mm`],
    //     width: ["black", `Width: ${width} mm`],
    //     thickness: ["black", `Thickness: ${thickness} mm`],
    // };

    return (
        <>
            {/*outer rectangle*/}
            <Rectangle originX={frameCenterX} originY={frameCenterY} width={displayWidth} height={displayHeight} stroke={stroke} fill={fill} />
            {/*inner rectangle*/}
            <Rectangle originX={frameCenterX} originY={frameCenterY} width={displayWidth - 2 * displayThickness} height={displayHeight - 2 * displayThickness} stroke={stroke} fill="white" />
            {/*x axis*/}
            <DottedLine originX={frameCenterX} originY={frameCenterY} length={displayWidth + dottedLineExtension} spanDirection="horizontal" stroke="darkgrey" />
            <text x={frameCenterX + displayWidth / 2 + dottedLineExtension / 2 + 10} y={frameCenterY} fill="darkgrey" dominantBaseline="middle" textAnchor="middle">
                X
            </text>
            <text x={frameCenterX - displayWidth / 2 - dottedLineExtension / 2 - 10} y={frameCenterY} fill="darkgrey" dominantBaseline="middle" textAnchor="middle">
                X
            </text>
            {/*y axis*/}
            <DottedLine originX={frameCenterX} originY={frameCenterY} length={displayHeight + dottedLineExtension} spanDirection="vertical" stroke="darkgrey" />
            <text x={frameCenterX} y={frameCenterY + displayHeight / 2 + dottedLineExtension / 2 + 10} fill="darkgrey" dominantBaseline="middle" textAnchor="middle">
                Y
            </text>
            <text x={frameCenterX} y={frameCenterY - displayHeight / 2 - dottedLineExtension / 2 - 10} fill="darkgrey" dominantBaseline="middle" textAnchor="middle">
                Y
            </text>
            {/*dimension line for height*/}
            <DimensionLine originX={frameCenterX + displayWidth / 2 + 40} originY={frameCenterY} spanDirection="vertical" span={displayHeight} text={`${height} mm`} textAlignment="right" stroke="black" />
            {/*dimension line for width*/}
            <DimensionLine originX={frameCenterX} originY={frameCenterY + displayHeight / 2 + 40} spanDirection="horizontal" span={displayWidth} text={`${width} mm`} textAlignment="bottom" stroke="black" />
            {/*dimension line for thickness*/}
            <DimensionLine originX={frameCenterX - displayWidth / 2 - 20} originY={frameCenterY - displayHeight / 2 + displayThickness / 2} spanDirection="vertical" span={displayThickness} text={`${thickness} mm`} textAlignment="left" stroke="black" />
            {/*legend*/}
            {/* <Legend originX={10} originY={frameCenterY} sideLength={5} verticalSpacing={20} items={legend} /> */}
        </>
    );
}

export default RectangularHollowSectionDiagram;
