import React from 'react';
import { Line } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
} from 'chart.js';

// Register Chart.js components
ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

const ChartComponent = ({ frameHeight, frameWidth, xDataset, yDataset, xAxisTitle, yAxisTitle, appliedAxialLoad, appliedMoment }) => {
    // Ensure the datasets are defined and have values
    const validXDataset = Array.isArray(xDataset) && xDataset.length > 0 ? xDataset : [0];
    const validYDataset = Array.isArray(yDataset) && yDataset.length > 0 ? yDataset : [0];

    const data = {
        labels: validXDataset,
        datasets: [
            {
                label: yAxisTitle,
                data: validYDataset,
                borderColor: 'black',
                borderWidth: 1,
                fill: false,
                pointRadius: 1,
                pointBackgroundColor: 'black'
            },
            {
                label: 'Applied Point',
                data: [{ x: appliedMoment, y: appliedAxialLoad }],
                borderColor: 'blue',
                borderWidth: 1,
                fill: false,
                pointRadius: 1.5,
                pointBackgroundColor: 'blue'
            }
        ]
    };

    const options = {
        scales: {
            x: {
                title: {
                    display: true,
                    text: xAxisTitle
                },
                type: 'linear',
                position: 'bottom',
                ticks: {
                    autoSkip: true,
                    maxTicksLimit: 10
                },
                suggestedMin: Math.min(...validXDataset),
                suggestedMax: Math.max(...validXDataset)
            },
            y: {
                title: {
                    display: true,
                    text: yAxisTitle
                },
                ticks: {
                    autoSkip: true,
                    maxTicksLimit: 10
                },
                suggestedMin: Math.min(...validYDataset),
                suggestedMax: Math.max(...validYDataset)
            }
        },
        plugins: {
            tooltip: {
                callbacks: {
                    label: function (context) {
                        if (context.dataset.label === 'Applied Point') {
                            return `(${context.raw.x}, ${context.raw.y})`;
                        }
                        return context.dataset.label;
                    }
                }
            },
            legend: {
                display: false // Disable the legend for the first dataset
            }
        },
        responsive: true,
        maintainAspectRatio: false
    };

    return (
        <div style={{ height: frameHeight, width: frameWidth }}>
            <Line data={data} options={options} />
        </div>
    );
};

export default ChartComponent;
