import { Line } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
} from 'chart.js';

// Create the plugin to draw the horizontal line
const horizontalLinePlugin = {
    id: 'horizontalLine',
    afterDraw: (chart) => {
        const ctx = chart.ctx;
        const yAxis = chart.scales['y'];
        const xAxis = chart.scales['x'];
        // Find the pixel for y=0
        const yValue = yAxis.getPixelForValue(0);
        // Draw the horizontal line
        ctx.save();
        ctx.beginPath();
        ctx.moveTo(xAxis.left, yValue);
        ctx.lineTo(xAxis.right, yValue);
        ctx.lineWidth = 1;
        ctx.strokeStyle = 'black';
        ctx.setLineDash([5, 5]); // Dotted line
        ctx.stroke();
        ctx.restore();
    }
};

// Register Chart.js components
ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    horizontalLinePlugin
);

const DataSeriesChart = ({ chartID, frameHeight, frameWidth, xDataset, yDataset, xAxisTitle, yAxisTitle }) => {
    // Ensure the datasets are defined and have values
    const validXDataset = Array.isArray(xDataset) && xDataset.length > 0 ? xDataset : [0];
    const validYDataset = Array.isArray(yDataset) && yDataset.length > 0 ? yDataset : [0];

    const data = {
        labels: validXDataset,
        datasets: [
            {
                label: yAxisTitle,
                data: validYDataset,
                borderColor: 'black',
                borderWidth: 1,
                fill: false,
                pointRadius: 0,
                pointBackgroundColor: 'black'
            },
        ]
    };

    const options = {
        scales: {
            x: {
                title: {
                    display: true,
                    text: xAxisTitle
                },
                type: 'linear',
                position: 'bottom',
                ticks: {
                    autoSkip: true,
                    maxTicksLimit: 10
                },
                suggestedMin: Math.min(...validXDataset),
                suggestedMax: Math.max(...validXDataset)
            },
            y: {
                title: {
                    display: true,
                    text: yAxisTitle
                },
                ticks: {
                    autoSkip: true,
                    maxTicksLimit: 10
                },
                suggestedMin: Math.min(...validYDataset),
                suggestedMax: Math.max(...validYDataset)
            }
        },
        plugins: {
            legend: {
                display: false,
            },
            horizontalLinePlugin: true
        },
        responsive: true,
        maintainAspectRatio: false
    };

    return (
        <div id={chartID} style={{ height: frameHeight, width: frameWidth }}>
            <Line data={data} options={options} />
        </div>
    );
};

export default DataSeriesChart;
