import EndCondition from "./elements/endCondition";
import Loading from "./elements/loading";
import DimensionLine from "./elements/dimensionLine";
import Legend from "./elements/legend";

function BeamAnalysisDiagram({ frameHeight, frameWidth, loadType, load, endCondition, length, distanceFromStart }) {
    const frameCenterX = frameWidth / 2;
    const frameCenterY = frameHeight / 2 - 20;
    const displayLength = frameWidth * 0.4;
    const loadUnit = loadType == "Uniform Line Load" ? "kN/m" : "N";
    const legend = {
        length: ["black", `Length: ${length} mm`],
        endCondition: ["black", `End Condition: ${endCondition}`],
        loadType: ["black", `Load Type: ${loadType}`],
        load: ["black", `Applied Load: ${load} ${loadUnit}`]
    }

    if (endCondition == "Fix-Fix" && loadType == "Uniform Line Load") {
        return (
            <>
                {/*beam line*/}
                <line x1={frameCenterX - displayLength / 2}
                    y1={frameCenterY}
                    x2={frameCenterX + displayLength / 2}
                    y2={frameCenterY}
                    stroke="black" />
                {/*end conditions*/}
                <EndCondition originX={frameCenterX}
                    originY={frameCenterY}
                    length={displayLength}
                    height={0}
                    endCondition="Fix-Fix"
                    stroke="black" />
                {/*loading*/}
                <Loading loadType="Uniform Line Load"
                    load={load}
                    originX={frameCenterX}
                    originY={frameCenterY}
                    length={displayLength}
                    height={0}
                    stroke="blue" />
                {/*dimension line*/}
                <DimensionLine originX={frameCenterX}
                    originY={frameCenterY + 10 + 20}
                    spanDirection="horizontal"
                    span={displayLength}
                    text={`${length} mm`}
                    textAlignment="bottom"
                    stroke="darkgrey" />
                {/*legend*/}
                <Legend originX={10}
                    originY={frameCenterY + 60}
                    sideLength={5}
                    verticalSpacing={20}
                    items={legend} />
            </>
        )
    } else if (endCondition == "Pin-Pin" && loadType == "Uniform Line Load") {
        return (
            <>
                {/*beam line*/}
                <line x1={frameCenterX - displayLength / 2}
                    y1={frameCenterY}
                    x2={frameCenterX + displayLength / 2}
                    y2={frameCenterY}
                    stroke="black" />
                {/*end conditions*/}
                <EndCondition originX={frameCenterX}
                    originY={frameCenterY}
                    length={displayLength}
                    height={0}
                    endCondition="Pin-Pin"
                    stroke="black" />
                {/*loading*/}
                <Loading loadType="Uniform Line Load"
                    load={load}
                    originX={frameCenterX}
                    originY={frameCenterY}
                    length={displayLength}
                    height={0}
                    stroke="blue" />
                {/*dimension line*/}
                <DimensionLine originX={frameCenterX}
                    originY={frameCenterY + 10 + 20}
                    spanDirection="horizontal"
                    span={displayLength}
                    text={`${length} mm`}
                    textAlignment="bottom"
                    stroke="darkgrey" />
                {/*legend*/}
                <Legend originX={10}
                    originY={frameCenterY + 60}
                    sideLength={5}
                    verticalSpacing={20}
                    items={legend} />
            </>
        )
    } else if (endCondition == "Pin-Fix" && loadType == "Uniform Line Load") {
        return (
            <>
                {/*beam line*/}
                <line x1={frameCenterX - displayLength / 2}
                    y1={frameCenterY}
                    x2={frameCenterX + displayLength / 2}
                    y2={frameCenterY}
                    stroke="black" />
                {/*end conditions*/}
                <EndCondition originX={frameCenterX}
                    originY={frameCenterY}
                    length={displayLength}
                    height={0}
                    endCondition="Pin-Fix"
                    stroke="black" />
                {/*loading*/}
                <Loading loadType="Uniform Line Load"
                    load={load}
                    originX={frameCenterX}
                    originY={frameCenterY}
                    length={displayLength}
                    height={0}
                    stroke="blue" />
                {/*dimension line*/}
                <DimensionLine originX={frameCenterX}
                    originY={frameCenterY + 10 + 20}
                    spanDirection="horizontal"
                    span={displayLength}
                    text={`${length} mm`}
                    textAlignment="bottom"
                    stroke="darkgrey" />
                {/*legend*/}
                <Legend originX={10}
                    originY={frameCenterY + 60}
                    sideLength={5}
                    verticalSpacing={20}
                    items={legend} />
            </>
        )
    } else if (endCondition == "Free-Fix" && loadType == "Uniform Line Load") {
        return (
            <>
                {/*beam line*/}
                <line x1={frameCenterX - displayLength / 2}
                    y1={frameCenterY}
                    x2={frameCenterX + displayLength / 2}
                    y2={frameCenterY}
                    stroke="black" />
                {/*end conditions*/}
                <EndCondition originX={frameCenterX}
                    originY={frameCenterY}
                    length={displayLength}
                    height={0}
                    endCondition="Free-Fix"
                    stroke="black" />
                {/*loading*/}
                <Loading loadType="Uniform Line Load"
                    load={load}
                    originX={frameCenterX}
                    originY={frameCenterY}
                    length={displayLength}
                    height={0}
                    stroke="blue" />
                {/*dimension line*/}
                <DimensionLine originX={frameCenterX}
                    originY={frameCenterY + 10 + 20}
                    spanDirection="horizontal"
                    span={displayLength}
                    text={`${length} mm`}
                    textAlignment="bottom"
                    stroke="darkgrey" />
                {/*legend*/}
                <Legend originX={10}
                    originY={frameCenterY + 60}
                    sideLength={5}
                    verticalSpacing={20}
                    items={legend} />
            </>
        )
    } else if (endCondition == "Pin-Fix" && loadType == "Point Load") {
        return (
            <>
                {/*beam line*/}
                <line x1={frameCenterX - displayLength / 2}
                    y1={frameCenterY}
                    x2={frameCenterX + displayLength / 2}
                    y2={frameCenterY}
                    stroke="black" />
                {/*end conditions*/}
                <EndCondition originX={frameCenterX}
                    originY={frameCenterY}
                    length={displayLength}
                    height={0}
                    endCondition="Pin-Fix"
                    stroke="black" />
                {/*loading*/}
                <Loading loadType="Point Load"
                    load={load}
                    originX={frameCenterX - displayLength / 2 + displayLength * distanceFromStart / length}
                    originY={frameCenterY}
                    length={displayLength}
                    height={0}
                    stroke="blue" />
                {/*dimension line*/}
                <DimensionLine originX={frameCenterX}
                    originY={frameCenterY + 10 + 20}
                    spanDirection="horizontal"
                    span={displayLength}
                    text={`${length} mm`}
                    textAlignment="bottom"
                    stroke="darkgrey" />
                {/*dimension line for point load*/}
                <DimensionLine originX={frameCenterX - displayLength / 2 + displayLength * distanceFromStart / (2 * length)}
                    originY={frameCenterY - 30 - 40}
                    spanDirection="horizontal"
                    span={displayLength * distanceFromStart / length}
                    text={`${distanceFromStart} mm`}
                    textAlignment="top"
                    stroke="darkgrey" />
                {/*legend*/}
                <Legend originX={10}
                    originY={frameCenterY + 60}
                    sideLength={5}
                    verticalSpacing={20}
                    items={legend} />
            </>
        )
    } else if (endCondition == "Free-Fix" && loadType == "Point Load") {
        return (
            <>
                {/*beam line*/}
                <line x1={frameCenterX - displayLength / 2}
                    y1={frameCenterY}
                    x2={frameCenterX + displayLength / 2}
                    y2={frameCenterY}
                    stroke="black" />
                {/*end conditions*/}
                <EndCondition originX={frameCenterX}
                    originY={frameCenterY}
                    length={displayLength}
                    height={0}
                    endCondition="Free-Fix"
                    stroke="black" />
                {/*loading*/}
                <Loading loadType="Point Load"
                    load={load}
                    originX={frameCenterX - displayLength / 2 + displayLength * distanceFromStart / length}
                    originY={frameCenterY}
                    length={displayLength}
                    height={0}
                    stroke="blue" />
                {/*dimension line*/}
                <DimensionLine originX={frameCenterX}
                    originY={frameCenterY + 10 + 20}
                    spanDirection="horizontal"
                    span={displayLength}
                    text={`${length} mm`}
                    textAlignment="bottom"
                    stroke="darkgrey" />
                {/*dimension line for point load*/}
                <DimensionLine originX={frameCenterX - displayLength / 2 + displayLength * distanceFromStart / (2 * length)}
                    originY={frameCenterY - 30 - 40}
                    spanDirection="horizontal"
                    span={displayLength * distanceFromStart / length}
                    text={`${distanceFromStart} mm`}
                    textAlignment="top"
                    stroke="darkgrey" />
                {/*legend*/}
                <Legend originX={10}
                    originY={frameCenterY + 60}
                    sideLength={5}
                    verticalSpacing={20}
                    items={legend} />
            </>
        )
    } else if (endCondition == "Pin-Pin" && loadType == "Point Load") {
        return (
            <>
                {/*beam line*/}
                <line x1={frameCenterX - displayLength / 2}
                    y1={frameCenterY}
                    x2={frameCenterX + displayLength / 2}
                    y2={frameCenterY}
                    stroke="black" />
                {/*end conditions*/}
                <EndCondition originX={frameCenterX}
                    originY={frameCenterY}
                    length={displayLength}
                    height={0}
                    endCondition="Pin-Pin"
                    stroke="black" />
                {/*loading*/}
                <Loading loadType="Point Load"
                    load={load}
                    originX={frameCenterX - displayLength / 2 + displayLength * distanceFromStart / length}
                    originY={frameCenterY}
                    length={displayLength}
                    height={0}
                    stroke="blue" />
                {/*dimension line*/}
                <DimensionLine originX={frameCenterX}
                    originY={frameCenterY + 10 + 20}
                    spanDirection="horizontal"
                    span={displayLength}
                    text={`${length} mm`}
                    textAlignment="bottom"
                    stroke="darkgrey" />
                {/*dimension line for point load*/}
                <DimensionLine originX={frameCenterX - displayLength / 2 + displayLength * distanceFromStart / (2 * length)}
                    originY={frameCenterY - 30 - 40}
                    spanDirection="horizontal"
                    span={displayLength * distanceFromStart / length}
                    text={`${distanceFromStart} mm`}
                    textAlignment="top"
                    stroke="darkgrey" />
                {/*legend*/}
                <Legend originX={10}
                    originY={frameCenterY + 60}
                    sideLength={5}
                    verticalSpacing={20}
                    items={legend} />
            </>
        )
    } else if (endCondition == "Fix-Fix" && loadType == "Point Load") {
        return (
            <>
                {/*beam line*/}
                <line x1={frameCenterX - displayLength / 2}
                    y1={frameCenterY}
                    x2={frameCenterX + displayLength / 2}
                    y2={frameCenterY}
                    stroke="black" />
                {/*end conditions*/}
                <EndCondition originX={frameCenterX}
                    originY={frameCenterY}
                    length={displayLength}
                    height={0}
                    endCondition="Fix-Fix"
                    stroke="black" />
                {/*loading*/}
                <Loading loadType="Point Load"
                    load={load}
                    originX={frameCenterX - displayLength / 2 + displayLength * distanceFromStart / length}
                    originY={frameCenterY}
                    length={displayLength}
                    height={0}
                    stroke="blue" />
                {/*dimension line*/}
                <DimensionLine originX={frameCenterX}
                    originY={frameCenterY + 10 + 20}
                    spanDirection="horizontal"
                    span={displayLength}
                    text={`${length} mm`}
                    textAlignment="bottom"
                    stroke="darkgrey" />
                {/*dimension line for point load*/}
                <DimensionLine originX={frameCenterX - displayLength / 2 + displayLength * distanceFromStart / (2 * length)}
                    originY={frameCenterY - 30 - 40}
                    spanDirection="horizontal"
                    span={displayLength * distanceFromStart / length}
                    text={`${distanceFromStart} mm`}
                    textAlignment="top"
                    stroke="darkgrey" />
                {/*legend*/}
                <Legend originX={10}
                    originY={frameCenterY + 60}
                    sideLength={5}
                    verticalSpacing={20}
                    items={legend} />
            </>
        )
    }
}

export default BeamAnalysisDiagram;