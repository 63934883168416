import React from "react";
import { useNavigate } from "react-router-dom";
import TitleBar from "./titleBar/v2/titleBar";
import { useState, useEffect } from "react";
import axios from "axios";
import { useAuth } from "../authContext";

function Subscription() {
    const { user, loading } = useAuth();
    const navigate = useNavigate();

    useEffect(() => {
        // This script adds the Razorpay checkout script to the document
        const script = document.createElement("script");
        script.src = "https://checkout.razorpay.com/v1/checkout.js";
        script.async = true;
        document.body.appendChild(script);

        script.onload = async () => {
            const orderUrl = "/api/createOrder";
            const orderData = {
                amount: 10000, // amount in currency subunits
                currency: "INR",
                receipt: "receipt#1",
                token: user.token
            };

            // Razorpay options
            try {
                const { data } = await axios.post(orderUrl, orderData);
                const razorpayKeyId = process.env.REACT_APP_RAZORPAY_KEY_ID;

                const options = {
                    key: razorpayKeyId, // Enter the Key ID generated from the Dashboard
                    amount: data.amount, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
                    currency: data.currency,
                    name: "Structura Pro", // your business name
                    description: "Prod Transaction",
                    image: "https://structurapro.co/api/images/companyLogo",
                    order_id: data.id, // This is a sample Order ID. Pass the `id` obtained in the response of Step 1
                    handler: function (response) {
                        handlePaymentSuccess(response, user, data.amount, data.currency, navigate);
                    },
                    prefill: {
                        // We recommend using the prefill parameter to auto-fill customer's contact information especially their phone number
                        name: user.displayName, // your customer's name
                        email: user.email,
                        contact: user.phoneNumber, // Provide the customer's phone number for better conversion rates
                    },
                    notes: {
                        address: "Razorpay Corporate Office",
                    },
                    theme: {
                        color: "#3399cc",
                    },
                };

                // Event listener for the button click
                document.getElementById("rzp-button1").onclick = function (e) {
                    var rzp1 = new window.Razorpay(options);
                    rzp1.open();
                    e.preventDefault();
                };
            } catch (error) {
                console.error("Error creating order", error);
            }
        };

        // Cleanup function to remove the script
        return () => {
            document.body.removeChild(script);
        };
    }, []);

    if (loading) {
        return <p>Loading...</p>; // You can customize the loading state as needed
    }

    const paidUntilDate = new Date(user.paidUntil); // If user.paidUntil is in milliseconds
    const formattedDate = paidUntilDate.toLocaleDateString("en-US", {
        year: "numeric",
        month: "long",
        day: "numeric",
    });

    return (
        <div className="subscription-container">
            {user && user.paid && user.paidUntil ? (
                <div className="subscription-info">
                    <p>Monthly subscription active, expires on {formattedDate}</p>
                </div>
            ) : (
                <div className="subscription-info" style={{ marginTop: "20px" }}>
                    <div className="subscription-info" style={{ display: "flex", flexDirection: "row", justifyContent: "center" }}>
                        <p style={{ fontSize: "20px", fontWeight: "500" }}>Subscribe today to unlock premium tools!</p>
                    </div>
                    <button id="rzp-button1" className="pay-button"><span className="strikethrough">Pay ₹150</span> <span className="current-amount">Introductory Offer! Pay ₹100</span> to Subscribe for one month</button>
                    <p className="note">
                        NOTE: New Subscriptions may take up to 30 minutes to reflect on the profile. Please do not pay again if the Payment Succeeded alert pops up. Try refreshing the page to remove stale status.
                    </p>
                </div>
            )}
        </div>
    );

}

const handlePaymentSuccess = async (response, user, amount, currency, navigate) => {
    console.log("Payment response received", response);

    try {
        const res = await axios.post(
            "/verifyPayment", // Your backend verification endpoint
            {
                paymentId: response.razorpay_payment_id,
                orderId: response.razorpay_order_id,
                signature: response.razorpay_signature,
            },
            {
                withCredentials: true,
            }
        );

        if (res.data.success) {
            console.log("Payment successful and verified", res.data);
            try {
                const token = user.token;
        
                const paymentData = {
                    token: token.token,
                    paymentId: response.razorpay_payment_id,
                    orderId: response.razorpay_order_id,
                    amount: amount,
                    currency: currency,
                };
        
                const res = await axios.post("/upgradeUserToPaid", paymentData, {
                    withCredentials: true,
                });
                const statusCode = res.status;
        
                if (statusCode == 200) {
                    console.log("User upgraded to paid successfully");
                    alert("Upgrade Successful. Please sign in again to access the paid features");
                    // the user needs to login again since the access token needs to be refreshed to reflect paid status
                    handleLogout();
                    navigate("/signIn");
                } else {
                    console.error("Failed to upgrade user to paid");
                }
        
                // Update the token in cookies to avoid re-signing in again
            } catch (error) {
                console.error("Payment verified but error in updating user status:", error);
            }
        } else {
            console.error("Payment verification failed", res.data);
            // Handle the failure case, maybe show an error message to the user
        }
    } catch (error) {
        console.error("Error verifying payment", error);
    }


};

const handleLogout = () => {
    // Clear the authentication token from localStorage
    console.log("logging out");
    localStorage.removeItem("authToken");

    // Clear the authentication cookie
    document.cookie = "authToken=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
};

function UnpaidUser() {
    const navigate = useNavigate();

    const handleUpgradeClick = () => {
        navigate("/profile");
    };

    return (
        <div>
            <TitleBar />
            <Subscription />
        </div>
    );
}

export default UnpaidUser;
