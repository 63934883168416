import Circle from "./elements/circle";
import DottedLine from "./elements/dottedLine";
import Arrow from "./elements/arrow";
import Legend from "./elements/legend";

//frameheight: height of the frame in which you want to place the diagram
//framewidth: width of the frame in which you want to place the diagram
//shearForceAlongX: shear force that is acting along the x direction
//shearForceAlongY: shear force that is acting along the y direction
//momentAboutX: moment that is acting about the x axis
//momentAboutY: moment that is acting about the y axis

function CircularHollowSectionLoadingDiagram({ frameHeight, frameWidth, shearForceAlongX, shearForceAlongY, momentAboutX, momentAboutY, radius, thickness }) {
    const frameCenterX = frameWidth / 2;
    const frameCenterY = frameHeight / 2;
    const displayRadius = (frameHeight) / 3.5;
    const dottedLineOffset = 50;
    const legend = {
        mx: ["blue", `Mx: ${momentAboutX} kN-m`],
        my: ["blue", `My: ${momentAboutY} kN-m`],
        sx: ["green", `Sx: ${shearForceAlongX} kN`],
        sy: ["green", `Sy: ${shearForceAlongY} kN`],
    };

    return (
        <>
            {/*outer circle*/}
            <Circle originX={frameCenterX}
                originY={frameCenterY}
                radius={displayRadius}
                fill="#F5F5F5"
                stroke="black" />
            {/*inner rectangle*/}
            <Circle originX={frameCenterX}
                originY={frameCenterY}
                radius={displayRadius - thickness}
                fill="white"
                stroke="black" />
            {/*vertical dotted line*/}
            <DottedLine originX={frameCenterX} originY={frameCenterY} length={2 * displayRadius + dottedLineOffset} spanDirection="vertical" stroke="darkgrey" />
            <text x={frameCenterX} y={frameCenterY - displayRadius - dottedLineOffset / 2 - 15} fill="darkgrey" dominantBaseline="hanging" textAnchor="middle">
                Y
            </text>
            {/*horizontal dotted line*/}
            <DottedLine originX={frameCenterX} originY={frameCenterY} length={2 * displayRadius + dottedLineOffset} spanDirection="horizontal" stroke="darkgrey" />
            <text x={frameCenterX + displayRadius + dottedLineOffset / 2 + 10} y={frameCenterY} fill="darkgrey" dominantBaseline="middle" textAnchor="middle">
                X
            </text>
            {/*mx ellipse*/}
            <path
                d={`M ${frameCenterX + displayRadius + 20},${frameCenterY + 20}
                A 50,30 0 0 1 ${frameCenterX + displayRadius + 20},${frameCenterY - 20}`}
                fill="none"
                stroke="blue"
            />
            <line x1={frameCenterX + displayRadius + 20} y1={frameCenterY + 20} x2={frameCenterX + displayRadius + 20 - 2} y2={frameCenterY + 20 - 7} stroke="blue" />
            <line x1={frameCenterX + displayRadius + 20} y1={frameCenterY + 20} x2={frameCenterX + displayRadius + 20 - 7} y2={frameCenterY + 20 + 1} stroke="blue" />
            <text x={frameCenterX + displayRadius + 20} y={frameCenterY - 20 - 5} fill="blue" dominantBaseline="auto" textAnchor="start">{`${momentAboutX} kN-m`}</text>
            {/*my ellipse*/}
            <path
                d={`M ${frameCenterX + 20},${frameCenterY - displayRadius - 20}
                A 30,50 0 0 1 ${frameCenterX - 20},${frameCenterY - displayRadius - 20}`}
                fill="none"
                stroke="blue"
            />
            <line x1={frameCenterX + 20} y1={frameCenterY - displayRadius - 20} x2={frameCenterX + 20 - 1} y2={frameCenterY - displayRadius - 20 + 9} stroke="blue" />
            <line x1={frameCenterX + 20} y1={frameCenterY - displayRadius - 20} x2={frameCenterX + 20 - 8} y2={frameCenterY - displayRadius - 20 + 2} stroke="blue" />
            <text x={frameCenterX - 20 - 5} y={frameCenterY - displayRadius - 20} fill="blue" dominantBaseline="middle" textAnchor="end">{`${momentAboutY} kN-m`}</text>
            {/*sx arrow*/}
            <Arrow originX={frameCenterX - displayRadius} originY={frameCenterY} tailLength={30} arrowHeadHeight={8} stroke="green" fill="green" direction="right" />
            <text x={frameCenterX - displayRadius - 38 - 5} y={frameCenterY} dominantBaseline="middle" textAnchor="end" fill="green">{`${shearForceAlongX} kN`}</text>
            {/*sy arrow*/}
            <Arrow originX={frameCenterX} originY={frameCenterY + displayRadius} tailLength={30} arrowHeadHeight={8} stroke="green" fill="green" direction="up" />
            <text x={frameCenterX} y={frameCenterY + displayRadius + 38 + 5} dominantBaseline="hanging" textAnchor="middle" fill="green">{`${shearForceAlongY} kN`}</text>
            {/*legend*/}
            <Legend originX={10} originY={frameCenterY + 30} sideLength={5} verticalSpacing={20} items={legend} />
        </>
    );
}

export default CircularHollowSectionLoadingDiagram;
