import TitleBar from "../../components/titleBar/v2/titleBar";
import Footer from "../../components/footer/footer";
import { Link } from "react-router-dom";
import { useEffect, useRef } from "react";
import "./home.css"

//Section V1
function SectionV1() {
    const leftSectionTitle = "Empowering Structural Engineering Education";
    const leftSectionContent = "At Structura Pro, we provide premium tools and resources for students and educators in structural engineering. Try our free tool to experience the quality and effectiveness of our offerings. Enhance your learning, streamline projects, and foster innovation with Structura Pro.";
    return (
        <>
            <div className="_sectionDivV1">
                <div className="_sectionInnerDivV1">
                    <div className="_leftSectionDivV1">
                        <div className="_leftSectionInnerDivV1">
                            <label className="_leftSectionTitleV1">{leftSectionTitle}</label>
                            <label className="_leftSectionContentV1">{leftSectionContent}</label>
                            <Link to="/designTools">
                                <button className="_leftSectionButtonV1">
                                    Get Started
                                </button>
                            </Link>
                        </div>
                    </div>
                    <div className="_rightSectionDivV1">
                        <img className="_rightSectionImageV1" src="./assets/landingPageImage.png" />
                    </div>
                </div>
            </div>
        </>
    );
}

//beam analysis section
function BeamAnalysis() {
    const leftSectionTitle = "Discover Excellence Today. Try out our Beam Analysis Tool.";
    const leftSectionContent = "Analyze beams based on loading and end conditions with our free, easy-to-use tool. Experience the precision and quality of Structura Pro's advanced engineering resources.";
    return (
        <>
            <div className="_sectionDivV1" style={{background: "#F5F5F5"}}>
                <div className="_sectionInnerDivV1" style={{width: "100%"}}>
                    <div className="_rightSectionDivV1" style={{overflow: "hidden", animation: "slideInLeft 0.5s ease-in-out forwards"}}>
                        <img className="_beamAnalysisImage" src="./assets/beamAnalysisImage.png" />
                    </div>
                    <div className="_leftSectionDivV1" style={{animation: "slideInRight 0.5s ease-in-out forwards"}}>
                        <div className="_leftSectionInnerDivV1">
                            <label className="_leftSectionTitleV1">{leftSectionTitle}</label>
                            <label className="_leftSectionContentV1">{leftSectionContent}</label>
                            <Link to="/designTools/beamAnalysis">
                                <button className="_leftSectionButtonV1">
                                    Launch
                                </button>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

// Section V2
function ContentBlockV2({ contentTitle, className, content }) {
    return (
        <div className={`_contentBlockDivV2 ${className}`}>
            <label className="_contentBlockTitleV2">{contentTitle}</label>
            <label style={{ fontSize: "16px" }}>{content}</label>
        </div>
    );
}

function SectionV2() {
    const sectionRef = useRef(null);

    useEffect(() => {
        const section = sectionRef.current;
        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting) {
                    section.querySelectorAll('._contentBlockDivV2').forEach(block => {
                        block.style.animationPlayState = 'running';
                    });
                    observer.unobserve(section);
                }
            },
            { threshold: 0.1 } // Adjust this value based on when you want the animation to trigger
        );

        if (section) {
            observer.observe(section);
        }

        return () => {
            if (section) {
                observer.unobserve(section);
            }
        };
    }, []);

    return (
        <div className="_sectionDivV2" ref={sectionRef}>
            <canvas id="_sectionDivV2BackgroundAnimationCanvas" className="_sectionDivV2BackgroundAnimationCanvas"></canvas>
            <label className="_sectionTitleV2">Salient Features</label>
            <label className="_sectionDescriptionV2">Structura Pro offers the best in class suite of tools to aid your design process</label>
            <div className="_sectionContentV2">
                <ContentBlockV2 contentTitle="Web Based" className="block1" content="Structura Pro offers unparalleled accessibility, allowing users to work from anywhere with an internet connection. It enables real-time collaboration fosters communication and coordination among team members, streamlining the design process. Centralized updates and maintenance ensures users always have access to the latest features and enhancements. Scalability enables the tool to adapt to projects of any size and complexity without additional investment." />
                <ContentBlockV2 contentTitle="Documentation" className="block2" content="By utilizing a web-based structural design tool, our firm can establish a unified format for all documentation, fostering a cohesive design standard internally. This consistency not only streamlines our workflow but also presents a professional and organized image to clients. From calculations to reports, having a single format enhances clarity, efficiency, and ultimately, client satisfaction" />
                <ContentBlockV2 contentTitle="Design Standards" className="block3" content="With Structura Pro, we consolidate multiple design standards and codes into one accessible platform. This centralization simplifies the process of working with international codes, providing engineers with easy access to a comprehensive database of regulations. By housing diverse standards in a single location, our tool facilitates seamless compliance and ensures global project compatibility." />
                <ContentBlockV2 contentTitle="User-Friendly" className="block4" content="Structura Pro features an intuitive and user-friendly interface, enabling engineers to quickly familiarize themselves with its functionalities. This simplicity promotes efficient knowledge transfer within the firm, eliminating the need for multiple proprietary tools. Engineers can onboard in minutes, saving time and effort while ensuring consistency across projects. With a streamlined interface, our tool enhances productivity and facilitates a cohesive workflow within the firm." />
            </div>
        </div>
    );
}

//section 3
function ContentBlock({ contentBlockTitle, contentBlockContent, contentBlockButtonLinkTo, contentBlockButtonTitle, animateClass }) {
    const contentBlockRef = useRef();

    useEffect(() => {
        const observer = new IntersectionObserver(
            entries => {
                entries.forEach(entry => {
                    if (entry.isIntersecting) {
                        entry.target.classList.add(animateClass);
                        observer.unobserve(entry.target);
                    }
                });
            },
            { threshold: 0.1 }
        );

        if (contentBlockRef.current) {
            observer.observe(contentBlockRef.current);
        }

        return () => {
            if (contentBlockRef.current) {
                observer.unobserve(contentBlockRef.current);
            }
        };
    }, [animateClass]);

    return (
        <div ref={contentBlockRef} className="_contentBlockDivV3">
            <label className="_contentBlockTitleV3">{contentBlockTitle}</label>
            <label className="_contentBlockContentV3">{contentBlockContent}</label>
            <Link to={contentBlockButtonLinkTo}>
                <button className="_contentBlockButtonV3">{contentBlockButtonTitle}</button>
            </Link>
        </div>
    );
}

function SectionV3() {
    return (
        <div className="_sectionDivV3">
            <ContentBlock
                contentBlockTitle="Articles"
                contentBlockContent="Discover insightful articles on structural engineering, covering design, materials, and industry trends. Stay informed with expert-authored content, enhancing your understanding of key principles and practices. Explore the latest advancements and best practices in the field, empowering your professional growth."
                contentBlockButtonLinkTo="/articles"
                contentBlockButtonTitle="Explore"
                animateClass="animate-left"
            />
            <ContentBlock
                contentBlockTitle="Community"
                contentBlockContent="Connect with structural engineers in our dynamic community platform. Ask questions, share insights, and collaborate on industry challenges. Gain valuable knowledge and elevate your professional network base through participation in our dynamic community platform."
                contentBlockButtonLinkTo="/comingSoon"
                contentBlockButtonTitle="Coming Soon"
                animateClass="animate-right"
            />
        </div>
    );
}

//section
function FounderInformationSection({ imgSrc, name, information, animateClass }) {
    const sectionRef = useRef();

    useEffect(() => {
        const observer = new IntersectionObserver(
            entries => {
                entries.forEach(entry => {
                    if (entry.isIntersecting) {
                        entry.target.classList.add(animateClass);
                        observer.unobserve(entry.target);
                    }
                });
            },
            { threshold: 0.1 }
        );

        if (sectionRef.current) {
            observer.observe(sectionRef.current);
        }

        return () => {
            if (sectionRef.current) {
                observer.unobserve(sectionRef.current);
            }
        };
    }, [animateClass]);

    const renderedInformation = [];
    Object.keys(information).forEach((key) => {
        renderedInformation.push(<label key={key} style={{ fontWeight: 500, padding: "5px" }}>{key}</label>)
        information[key].forEach((data, index) => {
            renderedInformation.push(<label key={index} style={{ padding: "5px" }}>{data}</label>)
        });
    });

    return (
        <div ref={sectionRef} className="_founderInformationSection">
            <div style={{ display: "flex", flexDirection: "row", justifyContent: "center" }}>
                <img className="_founderSectionImage" src={imgSrc} alt={`${name} headshot`} />
            </div>
            <label className="_founderName">{name}</label>
            {renderedInformation}
        </div>
    );
}

function SectionV4() {
    const ugInformation = {
        "Education": ["IIT Delhi: Bachelor's in Civil Engineering (2018-22)"],
        "Work Experience": ["Walter P Moore, India: Graduate Structural Engineer (2022-23)"],
        "Current Role": ["Stanford University: MS in Sustainable Design and Construction (2023-Present)"]
    }

    const ksInformation = {
        "Education": ["IIT Kanpur: Bachelor's in Civil Engineering (2018-22)"],
        "Current Role": ["Amazon, London: Software Development Engineer (2022-Present)"]
    }

    return (
        <div className="_sectionV4">
            <label style={{ fontSize: "32px", fontWeight: "500", paddingLeft: "20px", marginTop: "10px" }}>The Team</label>
            <FounderInformationSection
                imgSrc="./assets/ugHeadshot.jpeg"
                name="Utkarsh Gupta"
                information={ugInformation}
                animateClass="animate-left"
            />
            <FounderInformationSection
                imgSrc="./assets/ksHeadshot.jpeg"
                name="Kartikeya Sanwal"
                information={ksInformation}
                animateClass="animate-right"
            />
        </div>
    );
}

function Home() {
    const careerForm = () => {
        window.open("https://docs.google.com/forms/d/e/1FAIpQLSeSJTVWx5WuSpeSWseGvA_LOXEHJ4oK0FvKkymEpayaBMFVWw/viewform?usp=sf_link", "_blank");
    };

    const middleSectionButtons = {
        designTools: ["linkButton", "Design Tools", "black", "", "/designTools", () => { }],
        articles: ["linkButton", "Articles", "black", "", "/comingSoon", () => { }],
        community: ["linkButton", "Community", "black", "", "/comingSoon", () => { }],
    };

    const endSectionButtons = {
        career: ["textButton", "Career", "black", "", "", careerForm],
        login: ["linkButton", "Login", "black", "", "/comingSoon", () => { }],
    };

    return (
        <>
            <TitleBar titleBarHeading="Structura Pro" middleSectionButtons={middleSectionButtons} endSectionButtons={endSectionButtons} />
            <SectionV1 />
            <BeamAnalysis />
            <SectionV2 />
            <SectionV3 />
            <SectionV4 />
            <Footer textColor="white" />
        </>
    );
}

export default Home;
