//originX and originY: this is the center of the dimensionline
//spanDirection: this is how you want the dimension line to span, horizontal or vertical
//span: this is the actual span length of the dimension line
//text: the text you want to show with the dimesion line
//textAlignment: where do you want to place the text, top, bottom, right and left
//stroke: this is the stroke color of the dimesion line

function DimensionLine({ originX, originY, spanDirection, span, text, textAlignment, stroke }) {
    if (spanDirection === "horizontal" && textAlignment === "top") {
        const x1 = originX - span / 2;
        const y1 = originY;
        const x2 = originX + span / 2;
        const y2 = originY;

        return (
            <>
                {/* horizontal line */}
                <line x1={x1} y1={y1} x2={x2} y2={y2} stroke={stroke} />
                {/* left vertical line to show the dimension line ends */}
                <line x1={x1} y1={y1 - 5} x2={x1} y2={y1 + 5} stroke={stroke} />
                {/* right vertical line to show the dimension line ends */}
                <line x1={x2} y1={y2 - 5} x2={x2} y2={y2 + 5} stroke={stroke} />
                {/* text that has to go above the dimension line */}
                <text x={originX} y={originY - 10} dominantBaseline="middle" textAnchor="middle" fontFamily="system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif">
                    {text}
                </text>
            </>
        );
    } else if (spanDirection === "horizontal" && textAlignment === "bottom") {
        const x1 = originX - span / 2;
        const y1 = originY;
        const x2 = originX + span / 2;
        const y2 = originY;

        return (
            <>
                {/* horizontal line */}
                <line x1={x1} y1={y1} x2={x2} y2={y2} stroke={stroke} />
                {/* left vertical line to show the dimension line ends */}
                <line x1={x1} y1={y1 - 5} x2={x1} y2={y1 + 5} stroke={stroke} />
                {/* right vertical line to show the dimension line ends */}
                <line x1={x2} y1={y2 - 5} x2={x2} y2={y2 + 5} stroke={stroke} />
                {/* text that has to go below the dimension line */}
                <text x={originX} y={originY + 10} dominantBaseline="middle" textAnchor="middle" fontFamily="system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif">
                    {text}
                </text>
            </>
        );
    } else if (spanDirection === "vertical" && textAlignment === "right") {
        const x1 = originX;
        const y1 = originY - span / 2;
        const x2 = originX;
        const y2 = originY + span / 2;

        return (
            <>
                {/* vertical line */}
                <line x1={x1} y1={y1} x2={x2} y2={y2} stroke={stroke} />
                {/* top horizontal line to show the dimension line ends */}
                <line x1={x1 + 5} y1={y1} x2={x1 - 5} y2={y1} stroke={stroke} />
                {/* bottom horizontal line to show the dimension line ends */}
                <line x1={x2 + 5} y1={y2} x2={x2 - 5} y2={y2} stroke={stroke} />
                {/*text that has to go to the right of the dimension line*/}
                <text x={originX + 10} y={originY} dominantBaseline="middle" textAnchor="left" fontFamily="system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif">
                    {text}
                </text>
            </>
        );
    } else if (spanDirection === "vertical" && textAlignment === "top") {
        const x1 = originX;
        const y1 = originY - span / 2;
        const x2 = originX;
        const y2 = originY + span / 2;

        return (
            <>
                {/* vertical line */}
                <line x1={x1} y1={y1} x2={x2} y2={y2} stroke={stroke} />
                {/* top horizontal line to show the dimension line ends */}
                <line x1={x1 + 5} y1={y1} x2={x1 - 5} y2={y1} stroke={stroke} />
                {/* bottom horizontal line to show the dimension line ends */}
                <line x1={x2 + 5} y1={y2} x2={x2 - 5} y2={y2} stroke={stroke} />
                {/*text that has to go to the right of the dimension line*/}
                <text x={originX} y={originY - span / 2 - 10} dominantBaseline="middle" textAnchor="middle" fontFamily="system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif">
                    {text}
                </text>
            </>
        );
    } else {
        //this one is vertical and left text alignment
        const x1 = originX;
        const y1 = originY - span / 2;
        const x2 = originX;
        const y2 = originY + span / 2;

        return (
            <>
                {/* vertical line */}
                <line x1={x1} y1={y1} x2={x2} y2={y2} stroke={stroke} />
                {/* top horizontal line to show the dimension line ends */}
                <line x1={x1 + 5} y1={y1} x2={x1 - 5} y2={y1} stroke={stroke} />
                {/* bottom horizontal line to show the dimension line ends */}
                <line x1={x2 + 5} y1={y2} x2={x2 - 5} y2={y2} stroke={stroke} />
                {/*text that has to go to the right of the dimension line*/}
                <text x={originX - 10} y={originY} dominantBaseline="middle" textAnchor="end" fontFamily="system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif">
                    {text}
                </text>
            </>
        );
    }
}

export default DimensionLine;
