import "./designPagesStyles.css";
import Buttons from "../../components/buttons/buttons";
import React, { useRef, useState, useEffect, useContext } from "react";
import axios from "axios";
import SettingsMenu from "../../components/settingsMenu/settingsMenu";
//import CalculationSheet from "../../components/calculationSheet/calculationSheet";
import InputSheet from "../../components/inputSheet/inputSheet";
import InformationOverlay from "../../components/informationOverlay/informationOverlay";
import TrussAnalysisCanvas from "../../components/trussAnalysisCanvas/trussAnalysisCanvas";
import NodeTable from "../../components/trussAnalysisCanvas/nodeTable";
import ElementTable from "../../components/trussAnalysisCanvas/elementTable";
import { TrussProvider, TrussContext } from "../../components/trussAnalysisCanvas/trussContext";

function TrussAnalysis() {
    //****************************************INPUT REFS AND INPUT VARIABLES****************************************
    //Ref Variables
    const inputDataRefs = {
        translationAlongXRef: useRef(null),
        translationAlongYRef: useRef(null),
        rotationAboutXRef: useRef(null),
        rotationAboutYRef: useRef(null),
        appliedForceRef: useRef(null),
        angleRef: useRef(null),
        modulusOfElasticityRef: useRef(null),
        crossSectionalAreaRef: useRef(null)
    };

    //Input Variables
    const inputData = {
        //degree of freedom
        translationAlongX: ["Degree of Freedom", "Translation along X", ["checkbox", inputDataRefs.translationAlongXRef, 0], "", true],
        translationAlongY: ["Degree of Freedom", "Translation along Y", ["checkbox", inputDataRefs.translationAlongYRef, 0], "", true],
        rotationAboutX: ["Degree of Freedom", "Rotation about X", ["checkbox", inputDataRefs.rotationAboutXRef, 0], "", true],
        rotationAboutY: ["Degree of Freedom", "Rotation about Y", ["checkbox", inputDataRefs.rotationAboutYRef, 0], "", true],
        //applied force
        appliedForce: ["Applied Force", "Applied Force", ["number", inputDataRefs.appliedForceRef, 10], "kN", true],
        angle: ["Applied Force", "Angle", ["number", inputDataRefs.angleRef, 0], "deg.", true],
        //member properties
        modulusOfElasticity: ["Member Properties", "Modulus of Elasticity", ["number", inputDataRefs.modulusOfElasticityRef, 200000], "MPa", true],
        crossSectionalArea: ["Member Properties", "Cross Sectional Area", ["number", inputDataRefs.crossSectionalAreaRef, 5000], "mm²", true]
    };

    //const ref for update button
    const updateButtonRef = useRef(null);
    const undoButtonRef = useRef(null);

    //code to update the node information (degree of freedoms)
    const { nodes, selectedNodes, setNodes, elements, setElements } = useContext(TrussContext);

    useEffect(() => {
        //update button and the inputs fiels associated with it
        const selectedNode = selectedNodes.length === 1 ? selectedNodes[0] : null;
        if (selectedNode) {
            const node = nodes.find(node => node === selectedNode);
            if (node) {
                inputDataRefs.translationAlongXRef.current.checked = node.dof.Tx === 1;
                inputDataRefs.translationAlongYRef.current.checked = node.dof.Ty === 1;
                inputDataRefs.rotationAboutXRef.current.checked = node.dof.Rx === 1;
                inputDataRefs.rotationAboutYRef.current.checked = node.dof.Ry === 1;
                inputDataRefs.appliedForceRef.current.value = node.force.magnitude;
                inputDataRefs.angleRef.current.value = node.force.angle;
                updateButtonRef.current.disabled = false;
            }
        } else {
            inputDataRefs.translationAlongXRef.current.checked = false;
            inputDataRefs.translationAlongYRef.current.checked = false;
            inputDataRefs.rotationAboutXRef.current.checked = false;
            inputDataRefs.rotationAboutYRef.current.checked = false;
            inputDataRefs.appliedForceRef.current.value = '';
            inputDataRefs.angleRef.current.value = '';
        }

        const disableCheckboxes = selectedNodes.length !== 1;
        inputDataRefs.translationAlongXRef.current.disabled = disableCheckboxes;
        inputDataRefs.translationAlongYRef.current.disabled = disableCheckboxes;
        inputDataRefs.rotationAboutXRef.current.disabled = disableCheckboxes;
        inputDataRefs.rotationAboutYRef.current.disabled = disableCheckboxes;
        inputDataRefs.appliedForceRef.current.disabled = disableCheckboxes;
        inputDataRefs.angleRef.current.disabled = disableCheckboxes;
        updateButtonRef.current.disabled = disableCheckboxes;
        //undo button
        if (elements.length == 0) {
            undoButtonRef.current.disabled = true;
        } else {
            undoButtonRef.current.disabled = false;
        }
    }, [selectedNodes, nodes, inputDataRefs, elements]);

    const handleUpdate = () => {
        if (selectedNodes.length === 1) {
            const selectedNode = selectedNodes[0];
            setNodes(prevNodes =>
                prevNodes.map(node =>
                    node === selectedNode
                        ? {
                            ...node,
                            dof: {
                                Tx: inputDataRefs.translationAlongXRef.current.checked ? 1 : 0,
                                Ty: inputDataRefs.translationAlongYRef.current.checked ? 1 : 0,
                                Rx: inputDataRefs.rotationAboutXRef.current.checked ? 1 : 0,
                                Ry: inputDataRefs.rotationAboutYRef.current.checked ? 1 : 0,
                            },
                            force: {
                                magnitude: parseFloat(inputDataRefs.appliedForceRef.current.value) || 0,
                                angle: parseFloat(inputDataRefs.angleRef.current.value) || 0
                            }
                        }
                        : node
                )
            );
        }
    };

    const handleUndo = () => {
        if (elements.length === 0) return; // If no elements, do nothing

        // Remove the last element
        const lastElement = elements[elements.length - 1];
        const startNode = lastElement.start;
        const endNode = lastElement.end;
        const updatedElements = elements.slice(0, -1);

        // Helper function to check if a node is still connected to any other elements
        const checkNodeConnectivity = (node, elements) => {
            //if the node is not connected to any element, this function returns false
            //if the node is connected to any element, this function returns true
            let check = false;
            elements.forEach((element) => {
                if ((element["start"]["x"] == node["x"] && element["start"]["y"] == node["y"]) || (element["end"]["x"] == node["x"] && element["end"]["y"] == node["y"])) {
                    check = true;
                }
            })
            return check;
        }

        const removeNode = (nodeToRemove, nodes) => {
            let updatedNodes = nodes;
            updatedNodes.forEach((node, index) => {
                if (node["x"] == nodeToRemove["x"] && node["y"] == nodeToRemove["y"]) {
                    updatedNodes.splice(index, 1);
                }
            })
            return updatedNodes;
        }

        let updatedNodes = [...nodes];

        // Check if the start node is free after removing the element
        if (!checkNodeConnectivity(startNode, updatedElements)) {
            updatedNodes = removeNode(startNode, updatedNodes)
        }

        // Check if the end node is free after removing the element
        if (!checkNodeConnectivity(endNode, updatedElements)) {
            updatedNodes = removeNode(endNode, updatedNodes)
        }

        // Update the states
        setNodes(updatedNodes);
        setElements(updatedElements);
    };

    //****************************************CALCULATE BUTTON****************************************
    //calculations related state variables
    const [designStatusMessage, setDesignStatusMessage] = useState("Status: Click Analyse to analyse truss.")
    const [designCalculationsVisibility, setDesignCalculationsVisibility] = useState(false); //whether calculations are shown or not
    const [memberForces, setMemberForces] = useState([])    //forces that are acting in the members
    const [nodeDisplacements, setNodeDisplacements] = useState([])  //displacements for the nodes

    //handle the clickdown of calculate button
    const calculateButtonClicked = async () => {
        //set design status message
        setDesignStatusMessage("Status: Analysing truss...")
        //creating the node object for backend
        let nodesForAnalysis = [];
        nodes.forEach((node, index) => {
            let nodeObject = {};
            nodeObject["id"] = index + 1;
            nodeObject["x"] = node["x"];
            nodeObject["y"] = node["y"];
            nodeObject["dof"] = {
                ux: node["dof"]["Tx"] == 1 ? true : false,
                uy: node["dof"]["Ty"] == 1 ? true : false
            };

            nodesForAnalysis.push(nodeObject);
        });
        //creating elements object for backend
        let elementsForAnalysis = [];
        elements.forEach((element, index) => {
            let elementObject = {};
            elementObject["id"] = index + 1;
            elementObject["start"] = findNodeID(element["start"]["x"], element["start"]["y"]);
            elementObject["end"] = findNodeID(element["end"]["x"], element["end"]["y"]);

            elementsForAnalysis.push(elementObject);
        });
        //creating force object for backend
        let forcesForAnalysis = [];
        nodes.forEach((node) => {
            forcesForAnalysis.push(node["force"]["magnitude"] * Math.cos(node["force"]["angle"] * Math.PI / 180));
            forcesForAnalysis.push(node["force"]["magnitude"] * Math.sin(node["force"]["angle"] * Math.PI / 180));
        })

        const requestObject = {
            nodesForAnalysis,
            elementsForAnalysis,
            forcesForAnalysis,
            modulusOfElasticity: inputDataRefs.modulusOfElasticityRef.current ? inputDataRefs.modulusOfElasticityRef.current.value : "",
            crossSectionalArea: inputDataRefs.crossSectionalAreaRef.current ? inputDataRefs.crossSectionalAreaRef.current.value : ""
        }

        //send data to backend
        axios.post("/api/trussAnalysisCalculations", requestObject, {
            headers: {
                "Content-Type": "application/json",
            },
        }).then((response) => {
            //set data received from the backend
            setMemberForces(response.data["memberForces"])
            setNodeDisplacements(response.data["displacements"])

            //front end stuff
            setDesignCalculationsVisibility(true);
            setDesignStatusMessage("Status: Truss analysis complete.")
        }).catch((error) => {
            console.error(`the request could not be completed ${error}`)
        })
    };

    const findNodeID = (x, y) => {
        let indexForNode = 0;
        nodes.forEach((node, index) => {
            if (node["x"] == x && node["y"] == y) {
                indexForNode = index + 1;
            }
        });

        return indexForNode;
    }

    //****************************************SETTINGS BUTTON****************************************
    //settings menu state variables
    const [displaySettingsMenu, setDisplaySettingsMenu] = useState(false); //toggle to show or not show the settings menu
    const [designedBy, setDesignedBy] = useState("John Doe"); //who has design the structural element (this is going to appear in the final PDF that we are going to print out)
    const [checkedBy, setCheckedBy] = useState("Alice Alex"); //who checked the design calculations (this is going to appear in the final PDF that we are going to print out)
    const [allowableDCR, setAllowableDCR] = useState(1); //what is the DCR that is allowed?

    //****************************************FEEDBACK BUTTON****************************************
    const feedbackButtonClicked = () => {
        window.open("https://forms.gle/Az8WUKkhQbNMhtnp9", "_blank");
    }


    //****************************************INFORMATION BUTTON****************************************
    //handle the clickdown of information button
    const informationButtonClicked = () => {
        window.open("../assets/trussAnalysis/designManual.pdf", "_blank");
    };

    //****************************************INFORMATION OVERLAYS****************************************
    //information overlay state variables
    const [geometryInformationOverlayVisibility, setGeometryInformationOverlayVisibility] = useState(false);
    const [materialPropertiesInformationOverlayVisibility, setMaterialPropertiesInformationOverlayVisibility] = useState(false);
    const [appliedForcesInformationOverlayVisibility, setAppliedForcesInformationOverlayVisibility] = useState(false);
    const [setupInformationOverlayVisibility, setSetupInformationOverlayVisibility] = useState(false);

    //information overlay functions
    const geometryInformationButtonClicked = () => {
        setGeometryInformationOverlayVisibility(true);
    };

    const materialPropertiesInformationButtonClicked = () => {
        setMaterialPropertiesInformationOverlayVisibility(true);
    };

    const appliedForcesInformationButtonClicked = () => {
        setAppliedForcesInformationOverlayVisibility(true);
    };

    const closeInformationOverlays = () => {
        setGeometryInformationOverlayVisibility(false);
        setMaterialPropertiesInformationOverlayVisibility(false);
        setAppliedForcesInformationOverlayVisibility(false);
    };

    const informationOverlayFunctions = [geometryInformationButtonClicked, materialPropertiesInformationButtonClicked, appliedForcesInformationButtonClicked];

    //****************************************TITLE BAR BUTTONS****************************************
    //title bar buttons
    const titleBarButtons = {
        calculateButton: ["Analyse", calculateButtonClicked],
        informationButton: ["Information", informationButtonClicked],
        feedbackButton: ["Feedback", feedbackButtonClicked]
    };

    const renderedTitleBarButtons = [];
    Object.keys(titleBarButtons).forEach((key) => {
        renderedTitleBarButtons.push(<Buttons type="textButton"
            text={titleBarButtons[key][0]}
            fontColor="black"
            backgroundColor=""
            linkTo=""
            onClickFunction={titleBarButtons[key][1]} />);
    });

    return (
        <div className="_containerDiv">
            {displaySettingsMenu && (
                <SettingsMenu setDisplaySettingsMenuFunction={setDisplaySettingsMenu}
                    designedBy={designedBy}
                    setDesignedBy={setDesignedBy}
                    checkedBy={checkedBy}
                    setCheckedBy={setCheckedBy}
                    allowableDCR={allowableDCR}
                    setAllowableDCR={setAllowableDCR} />
            )}
            {geometryInformationOverlayVisibility && (
                <InformationOverlay informationOverlayHeading="Degree of Freedom"
                    informationOverlayCaption={["Coordinate System Setup"]}
                    informationOverlayImageSrc={["../assets/trussAnalysis/images/degreeOfFreedomInformationOverlay.png"]}
                    closeInformationOverlays={closeInformationOverlays} />
            )}
            {materialPropertiesInformationOverlayVisibility && (
                <InformationOverlay informationOverlayHeading="Applied Forces"
                    informationOverlayCaption={["Applied Forces Coordinate System Setup"]}
                    informationOverlayImageSrc={["../assets/trussAnalysis/images/appliedForceInformationOverlay.png"]}
                    closeInformationOverlays={closeInformationOverlays} />
            )}
            {appliedForcesInformationOverlayVisibility && (
                <InformationOverlay informationOverlayHeading="Member Properties"
                    informationOverlayCaption={["Member Properties"]}
                    informationOverlayImageSrc={["../assets/trussAnalysis/images/memberPropertiesInformationOverlay.png"]}
                    closeInformationOverlays={closeInformationOverlays} />
            )}
            <div className="_designInputDiv" style={{ overflowX: "hidden" }}>
                <div>
                    <div className="_designInputsTitleBar">
                        <label className="_dashboardHeading">Design Inputs</label>
                    </div>
                    <div className="_designInputs">
                        <InputSheet inputData={inputData}
                            updateCalculationsFunction={() => { }} informationOverlayFunctions={informationOverlayFunctions} />
                    </div>
                </div>
                <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center" }}>
                    <button onClick={handleUpdate} className="_inputDivButton" ref={updateButtonRef}>Update</button>
                    <button onClick={handleUndo} className="_inputDivButton" ref={undoButtonRef}>Undo</button>
                </div>
                <div className="_nodesDiv">
                    {nodes.length === 0 && (
                        <>
                            <label className="_dashboardHeading">Nodes</label>
                            <label style={{ color: "white" }}>Start drawing truss to see node data here</label>
                        </>
                    )}
                    {nodes.length !== 0 && (
                        <>
                            <label className="_dashboardHeading">Nodes</label>
                            <NodeTable />
                        </>
                    )}
                </div>
                <div className="_elementsDiv">
                    {nodes.length <= 1 && (
                        <>
                            <label className="_dashboardHeading">Elements</label>
                            <label style={{ color: "white" }}>Start drawing truss to see element data here</label>
                        </>)}
                    {nodes.length > 1 && (
                        <>
                            <label className="_dashboardHeading">Elements</label>
                            <ElementTable />
                        </>
                    )}
                </div>
            </div>
            <div className="_designDashboardDiv">
                <div className="_dashboardTitleBar">
                    <label className="_dashboardHeading" style={{ color: "black" }}>
                        Truss Analysis
                    </label>
                    <div className="_dashboardButtons">{renderedTitleBarButtons}</div>
                </div>
                <div className="_drawingCanvasDiv">
                    <TrussAnalysisCanvas />
                    <div className="_analysisResultsDiv">
                        <p style={{ fontSize: "16px", fontWeight: "500", padding: "10px", margin: "0" }}>Node Diplacements</p>
                        {!designCalculationsVisibility && (
                            <p style={{ padding: "10px", margin: "0" }}>Draw truss and click Calculate to show node displacements</p>
                        )}
                        {designCalculationsVisibility && (
                            <NodeDisplacementTable nodeDisplacements={nodeDisplacements} />
                        )}
                        <p style={{ fontSize: "16px", fontWeight: "500", padding: "10px", margin: "0" }}>Member Forces</p>
                        {!designCalculationsVisibility && (
                            <p style={{ padding: "10px", margin: "0" }}>Draw truss and click Calculate to show member forces</p>
                        )}
                        {designCalculationsVisibility && (
                            <MemberForcesTables memberForces={memberForces} />
                        )}
                        <div style={{ overflowY: "scroll", display: "flex", flexDirection: "column", padding: "10px", borderTop: "1px solid black", marginTop: "50px" }}>
                            <p style={{ fontSize: "16px", fontWeight: "500", marginTop: "50px", padding: "0", margin: "0", marginBottom: "10px" }}><u>Instructions</u></p>
                            <p style={{ fontSize: "16px", fontWeight: "500", padding: "0", margin: "0" }}>Drawing</p>
                            <ul>
                                <li>To start drawing, click anywhere to create a node, and then click a second time to create the end node for a member.</li>
                                <li>Once you have created the first element, you can only start a new element from the existing nodes, but you can finish it anywhere you want on the canvas.</li>
                                <li>In order to make a precise element with known dimensions and angle, you can create the start node, and then type in the length, press the TAB key, and then enter the angle, and then press enter. You will notice "L" and "A" written below your mourse cursor which indicate the Length and Angle of the element you are drawing. Press TAB to switch between the two. Blue indicates the the particular field is active and ready for inputs.</li>
                                <li>Press ESC to exit any current element that you are drawing.</li>
                            </ul>
                            <p style={{ fontSize: "16px", fontWeight: "500", padding: "0", margin: "0" }}>Navigating Around</p>
                            <ul>
                                <li>Use your mouse scroll wheel to zoom in and out of the canvas.</li>
                                <li>Using the mouse scrool wheel click, you can pan around the canvas.</li>
                            </ul>
                            <p style={{ fontSize: "16px", fontWeight: "500", padding: "0", margin: "0" }}>Constraints and Forces</p>
                            <ul>
                                <li>To add a constraint to a node, select it using a bounding rectangle; click and drag to create one. Once you do that, contraints become active on the left design inputs pane. You can check or uncheck them as you require for your analysis</li>
                                <li>Similar to adding contraints, you can also add forces using the same steps. Select a node, the force inputs become active in the design inputs pane. Enter required values and then click update to apply the changes.</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="_dashboardStatusBar">
                    <label>{designStatusMessage}</label>
                    <label>Structura Pro</label>
                </div>
            </div>
        </div>
    );
}

function NodeDisplacementTable({ nodeDisplacements }) {
    //create nodedisplacements object
    let nodeDisplacementsForTable = [];
    for (let i = 0; i < nodeDisplacements.length / 2; i++) {
        let tempDisplacements = {};
        tempDisplacements["x"] = nodeDisplacements[2 * i][0];
        tempDisplacements["y"] = nodeDisplacements[2 * i + 1][0];
        nodeDisplacementsForTable.push(tempDisplacements);
    }
    // console.log(nodeDisplacementsForTable);

    // nodeDisplacementsForTable.map((nodeDisplacement, index) => {
    //     console.log(nodeDisplacement["x"]);
    // })

    return (
        <table className="_analysisResultsTable">
            <thead>
                <tr>
                    <th>ID</th>
                    <th>Dx</th>
                    <th>Dy</th>
                </tr>
            </thead>
            <tbody>
                {nodeDisplacementsForTable.map((nodeDisplacement, index) => (
                    <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{nodeDisplacement["x"].toExponential(1)}</td>
                        <td>{nodeDisplacement["y"].toExponential(1)}</td>
                    </tr>
                ))}
            </tbody>
        </table>
    );
}

function MemberForcesTables({ memberForces }) {
    return (
        <table className="_analysisResultsTable">
            <thead>
                <tr>
                    <th>ID</th>
                    <th>Force</th>
                    <th>Type</th>
                </tr>
            </thead>
            <tbody>
                {memberForces.map((memberForce, index) => (
                    <tr key={index}>
                        <td>{memberForce["elementId"]}</td>
                        <td>{memberForce["axialForce"].toFixed(2)}</td>
                        <td>{memberForce["type"]}</td>
                    </tr>
                ))}
            </tbody>
        </table>
    );
}

export default TrussAnalysis;