import React, { useState } from "react";
import { createUserWithEmailAndPassword, updateProfile } from "firebase/auth";
import { auth } from "../firebase";
import { useNavigate } from "react-router-dom";
import { createUserProfileDocument } from "../firebase";
import axios from "axios";

function SignUp() {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [displayName, setDisplayName] = useState("");
    const [error, setError] = useState(null);
    const navigate = useNavigate();

    const handleSignUp = async (e) => {
        e.preventDefault();
        try {
            const userCredential = await createUserWithEmailAndPassword(auth, email, password);
            const user = userCredential.user;

            await updateProfile(user, {
                displayName: displayName
            });

            await createUserProfileDocument(userCredential.user, {displayName: displayName});
            // Send a POST request to your backend API to add the user data to the backend collection
            await axios.post('/api/signUpUser', {
                uid: user.uid,
                email: user.email,
                displayName: displayName
            });


            
            alert('Sign up successful. Please sign in again.');
            navigate("/signIn");
        } catch (error) {
            setError(error.message);
            console.error("Error signing up:", error);
        }
    };

    const handleClosePopup = () => {
        setError(null); // Clear error message to close the popup
    };

    return (
        <div className="form-container">
            <form className="signup-form" onSubmit={handleSignUp}>
                {/* Structura Pro heading and logo */}
                <div style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    padding: "20px",
                    justifyContent: "center"
                }}>
                    <img src="./assets/companyLogo_white.png" style={{ height: "35px", width: "35px" }} alt="Company Logo" />
                    <label style={{ fontWeight: "500", fontSize: "20px", marginLeft: "10px" }}>Structura Pro</label>
                </div>
                {/* Sign up tag line */}
                <label style={{ marginTop: "10px", marginBottom: "10px", fontWeight: "500" }}>Join Structura Pro today!</label>
                {/* Input fields */}
                <div className="input-group">
                    <input
                        type="name"
                        value={displayName}
                        onChange={(e) => setDisplayName(e.target.value)}
                        placeholder="Name"
                        required
                    />
                </div>
                <div className="input-group">
                    <input
                        type="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        placeholder="Email"
                        required
                    />
                </div>
                <div className="input-group">
                    <input
                        type="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        placeholder="Password"
                        minLength={8}
                        maxLength={20}
                        required
                    />
                </div>
                {/* Sign up button */}
                <button className="btn btn-primary" type="submit">Sign Up</button>
            </form>
            {error && (
                <div className="error-popup">
                    <div className="error-popup-content">
                        <p>{error}</p>
                        <button className="btn btn-close" onClick={handleClosePopup}>
                            Close
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
}


export default SignUp;
