import "./loadingOverlay.css";

const Spinner = () => {
    const spinnerStyle = {
        width: '40px',
        height: '40px',
        border: '4px solid rgba(0, 0, 0, 0.1)',
        borderLeftColor: '#000',
        borderRadius: '50%',
        animation: 'spin 1s linear infinite',
        display: 'inline-block',
        margin: '20px auto',
    };

    const spinnerKeyframes = `
      @keyframes spin {
        0% {
          transform: rotate(0deg);
        }
        100% {
          transform: rotate(360deg);
        }
      }
    `;

    return (
        <>
            <style>
                {spinnerKeyframes}
            </style>
            <div style={spinnerStyle}></div>
        </>
    );
};

function LoadingOverlay() {
    return (
        <>
            <div className="_loadingOverlayDiv">
                <Spinner />
                <label style={{ color: "white" }}>Optimizing...</label>
            </div>
        </>
    )
}

export default LoadingOverlay;