import InformationPage from "../../components/informationPage/informationPage";

function Pricing() {
    const heading = "Pricing";
    const content = {
        heading1: ["Subscription Model", "Our pricing model is subscription-based. Users pay INR 100 per month for the subscription, which grants access to all the paid tools on our website."],
        heading2: ["Free Tools", "Certain tools on Structura Pro are marked as free and are available to all users free of charge. Users simply need to sign in and create an account with us to access these tools. No payment is required for free tools."],
        heading3: ["Price Changes", "Structura Pro reserves the right to increase the subscription price at any time. Users will be given prior notice of any price changes and will have the opportunity to cancel their subscription if they choose to do so."],
        heading4: ["Annual Package", "We also offer an annual subscription package for INR 1000. This option provides a discounted rate for users who prefer to pay annually."],
        heading5: ["Cancellation Policy", "Users can cancel their subscription at any time through their account settings. Upon cancellation, users will continue to have access to the paid tools until the end of their current billing cycle."],
        heading6: ["Refund Policy", "Refunds are not provided for any subscription payments that have already been processed. If a user cancels their subscription, they will not receive a refund for the remainder of the current billing period."],
        heading7: ["Account Requirements", "To access both free and paid tools, users must create an account with Structura Pro and agree to our Terms of Use and Privacy Policy."]
    };


    return (
        <>
            <InformationPage informationPageHeading={heading} content={content} />
        </>
    );
}

export default Pricing;
