import "./informationOverlay.css";
import Buttons from "../buttons/buttons";
import { useState } from "react";

function InformationOverlay({ informationOverlayHeading, informationOverlayCaption, informationOverlayImageSrc, closeInformationOverlays }) {
    const imageCount = informationOverlayImageSrc.length;
    let [currentIndex, setCurrentIndex] = useState(0);

    const previousButton = () => {
        currentIndex != 0 ? setCurrentIndex(--currentIndex) : console.log("current index already at 0");
    };

    const nextButton = () => {
        currentIndex != imageCount - 1 ? setCurrentIndex(++currentIndex) : console.log("already at the last image");
    };

    return (
        <div className="_informationOverlayDiv">
            <div className="_informationOverlayInnerDiv">
                <div className="_informationOverlayHeadingDiv">
                    <label className="_informationOverlayHeading">{informationOverlayHeading}</label>
                    <Buttons type="textButton" text="Close" fontColor="white" backgroundColor="" linkTo="" onClickFunction={closeInformationOverlays} />
                </div>
                <div className="_informationOverlayImageDiv">
                    <button
                        id="_informationOverlayPreviousButton"
                        className="_informationOverlayNavigationButtons"
                        style={{
                            color: `${imageCount == 1 || currentIndex == 0 ? "darkGrey" : "black"}`,
                            cursor: `${imageCount == 1 || currentIndex == 0 ? "auto" : "pointer"}`,
                            paddingRight: "30px"
                        }}
                        onClick={previousButton}
                    >
                        Back
                    </button>
                    <img src={informationOverlayImageSrc[currentIndex]} className="_informationOverlayImage" />
                    <button
                        id="_informationOverlayNextButton"
                        className="_informationOverlayNavigationButtons"
                        style={{
                            color: `${imageCount == 1 || currentIndex == imageCount - 1 ? "darkGrey" : "black"}`,
                            cursor: `${imageCount == 1 || currentIndex == imageCount - 1 ? "auto" : "pointer"}`,
                            paddingLeft: "30px"
                        }}
                        onClick={nextButton}
                    >
                        Next
                    </button>
                </div>
                <label className="_informationOverlayCaption">{informationOverlayCaption[currentIndex]}</label>
            </div>
        </div>
    );
}

export default InformationOverlay;
